<template>
  <div>
    <div class="container">
      <p style="font-weight: bold; text-align: center">
        FORMULARIO ÚNICO NACIONAL DE PERMISO DE VERTIMIENTO AL SUELO
      </p>
      <p style="font-weight: bold; text-align: center">
        Base legal: Decreto 1076 de 2015 o aquel que lo modifique o sustituya
      </p>
    </div>
    <div class="container">
      <p style="text-align: justify">
        Señor(a) usuario (a), los campos que tienen el * son obligatorios para
        radicar su solicitud. Le recomendamos diligenciar la mayoría o totalidad
        de la información solicitada, así como adjuntar los documentos descritos
        en las instrucciones de diligenciamiento de este formulario.
      </p>
    </div>
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="text-right my-3">
        <b-button
          v-b-tooltip.hover
          title="Limpiar campos"
          variant="success"
          @click="resetForm()"
        >
          <b-icon icon="trash-fill"></b-icon>
        </b-button>
      </div>
      <div style="text-align: left; margin-top: 20px">
        <label style="font-weight: bold">I. DATOS DEL SOLICITANTE </label>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group label="1. *Tipo de persona:" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoper"
              name="selectedtipoper"
              @change="onChangeRadioButton()"
            >
              <b-form-radio value="1">Natural </b-form-radio>
              <b-form-radio value="2">Jurídica</b-form-radio>
            </b-form-radio-group>
            <b-form-radio-group
              v-if="form.selectedtipoper == 2"
              id="radio-slots"
              v-model="form.selectedtipoperjuridica"
              name="selectedtipoperjuridica"
            >
              <b-form-radio value="1">Jurídica pública </b-form-radio>
              <b-form-radio value="2">Jurídica privada </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="2. *Tipo de trámite:" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipotram"
              name="selectedtipotram"
            >
              <b-form-radio value="257">Nuevo </b-form-radio>
              <b-form-radio value="212">Renovación </b-form-radio>
              <b-form-radio value="210">Modificación </b-form-radio>
            </b-form-radio-group>

            <label
              >(Aplica para trámites relacionados con permisos
              existentes)</label
            >
            <b-form-input
              v-model="form.expe"
              placeholder="Número de expediente:"
              maxlength="30"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="3. *Nombre o razón social:"
            style="text-align: left"
          >
            <b-form-group
              label="*Tipo de identificación:"
              style="text-align: left"
            >
              <b-form-radio-group
                id="radio-slots"
                v-model="form.selectedtipoident"
                name="selectedtipoident"
              >
                <b-form-radio value="10" v-if="form.selectedtipoper == 1"
                  >CC
                </b-form-radio>
                <b-form-radio value="26" v-if="form.selectedtipoper == 2"
                  >NIT
                </b-form-radio>
                <b-form-radio value="36" v-if="form.selectedtipoper == 1"
                  >Pasaporte
                </b-form-radio>
                <b-form-radio value="26" v-if="form.selectedtipoper == 2"
                  >Personería jurídica
                </b-form-radio>
                <b-form-radio value="11" v-if="form.selectedtipoper == 1"
                  >Cédula de extranjería
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              style="text-align: left"
              v-if="
                form.selectedtipoident == 26 || form.selectedtipoident == 25
              "
            >
              *Razón Social</label
            >
            <b-form-input
              v-if="
                form.selectedtipoident == 26 || form.selectedtipoident == 25
              "
              v-model="form.nombre"
              placeholder="razón social"
              maxlength="180"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group>
            <div class="row">
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Nombre</label
                >
                <b-form-input
                  placeholder="Primer Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre1"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Nombre</label
                >
                <b-form-input
                  placeholder="Segundo Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre2"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Apellido</label
                >
                <b-form-input
                  placeholder="Primer Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre3"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Apellido</label
                >
                <b-form-input
                  placeholder="Segundo Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre4"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 26 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 25 ||
                form.selectedtipoident == 11
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacion"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 11
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacion"
                  placeholder="No:"
                  v-if="
                    form.selectedtipoident == 26 || form.selectedtipoident == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digito"
                  placeholder="Dígito verificación:"
                  v-if="
                    form.selectedtipoident == 26 || form.selectedtipoident == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
            <label>*Dirección de correspondencia: </label>
            <b-form-input
              v-model="form.dircorrespondencia"
              placeholder="Dirección de correspondencia"
              maxlength="95"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.depart"
              :options="optionsdepartamento"
              @change="onChangeCiudad()"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Ciudad:" style="text-align: left">
            <b-form-select v-show="showciudad" v-model="ciudadcompleta">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsciudades"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Teléfono (s):" style="text-align: left"
            ><b-form-input
              v-model="form.tel"
              placeholder="Teléfono (s)"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Fax:" style="text-align: left"
            ><b-form-input
              v-model="form.fax"
              placeholder="Fax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correo"
              placeholder="Correo electrónico"
              type="email"
              class="form-control"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="*¿Autoriza la notificación mediante correo electrónico?"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedcorreo"
              name="selectedcorreo"
            >
              <b-form-radio value="1">Si </b-form-radio>
              <b-form-radio value="2">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group
            label="En caso contrario indique la dirección para notificación física:"
            style="text-align: left"
            ><b-form-input
              v-model="form.correodirnoti"
              placeholder="Dirección de notificación"
              maxlength="200"
            ></b-form-input>
          </b-form-group>
        </div>
        <!--<div class="col-md-12">
          <b-form-group
            label="*Selecciona el tipo de notificación de respuesta"
            style="text-align: left"
          >
            <b-form-select
              v-model="form.selectedtiponoti"
              :options="optionstiponoti"
            ></b-form-select>
          </b-form-group>
        </div>-->
        <div class="col-md-6">
          <b-form-group
            label="4. Información del Representante"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedrepre"
              name="selectedrepre"
            >
              <b-form-radio value="1">Legal</b-form-radio>
              <b-form-radio value="2">ó Apoderado</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <!--<div class="col-md-6">
          <b-form-group label="1. Tipo de persona:" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoperapo"
              name="selectedtipoperapo"
              @change="onChangeRadioButtonApo()"
            >
              <b-form-radio value="1">Natural </b-form-radio>
              <b-form-radio value="2">Jurídica</b-form-radio>
            </b-form-radio-group>
            <b-form-radio-group
              v-if="form.selectedtipoperapo == 2"
              id="radio-slots"
              v-model="form.selectedtipoperjuridicaapo"
              name="selectedtipoperjuridicaapo"
            >
              <b-form-radio value="1">Jurídica pública </b-form-radio>
              <b-form-radio value="2">Jurídica privada </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>-->

        <div class="col-md-6">
          <b-form-group
            label="Tipo de identificación:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoidentapo"
              name="selectedtipoidentapo"
            >
              <b-form-radio value="10" v-if="form.selectedtipoperapo == 1"
                >CC
              </b-form-radio>
              <b-form-radio value="26" v-if="form.selectedtipoperapo == 2"
                >NIT
              </b-form-radio>
              <b-form-radio value="36" v-if="form.selectedtipoperapo == 1"
                >Pasaporte
              </b-form-radio>
              <b-form-radio value="25" v-if="form.selectedtipoperapo == 2"
                >Personería jurídica
              </b-form-radio>
              <b-form-radio value="11" v-if="form.selectedtipoperapo == 1"
                >Cédula de extranjería
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <!------------------------------------------------------------------------------------->
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              style="text-align: left"
              v-if="
                form.selectedtipoidentapo == 26 ||
                form.selectedtipoidentapo == 25
              "
            >
              Nombre</label
            >
            <b-form-input
              v-if="
                form.selectedtipoidentapo == 26 ||
                form.selectedtipoidentapo == 25
              "
              v-model="form.nombreapoderado"
              placeholder="Nombre"
              maxlength="180"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group>
            <div class="row">
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  >*Primer Nombre</label
                >
                <b-form-input
                  placeholder="Primer Nombre"
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  v-model="form.nombre1apo"
                  maxlength="180"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  >Segundo Nombre</label
                >
                <b-form-input
                  placeholder="Segundo Nombre"
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  v-model="form.nombre2apo"
                  maxlength="180"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  >*Primer Apellido</label
                >
                <b-form-input
                  placeholder="Primer Apellido"
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  v-model="form.nombre3apo"
                  maxlength="180"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  >Segundo Apellido</label
                >
                <b-form-input
                  placeholder="Segundo Apellido"
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  v-model="form.nombre4apo"
                  maxlength="180"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoidentapo == 10 ||
                form.selectedtipoidentapo == 26 ||
                form.selectedtipoidentapo == 36 ||
                form.selectedtipoidentapo == 25 ||
                form.selectedtipoidentapo == 11
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacionapode"
              placeholder="No:"
              v-if="
                form.selectedtipoidentapo == 10 ||
                form.selectedtipoidentapo == 36 ||
                form.selectedtipoidentapo == 11
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacionapode"
                  placeholder="No:"
                  v-if="
                    form.selectedtipoidentapo == 26 ||
                    form.selectedtipoidentapo == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digitoapo"
                  placeholder="Dígito verificación:"
                  v-if="
                    form.selectedtipoidentapo == 26 ||
                    form.selectedtipoidentapo == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <!------------------------------------------------------------------------------------->

        <div class="col-md-6">
          <b-form-group label="De:" style="text-align: left"
            ><b-form-input
              v-model="form.lugaridapoderado"
              placeholder="Lugar de expedición de la identificación"
              maxlength="30"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="Dirección de correspondencia:"
            style="text-align: left"
            ><b-form-input
              v-model="form.dirapoderado"
              placeholder="Dirección de correspondencia"
              maxlength="95"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departapoderado"
              :options="optionsdepartamento"
              @change="onChangeCiudadapoderado()"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Ciudad:" style="text-align: left">
            <b-form-select v-show="showciudadapo" v-model="ciudadapoderado"
              ><b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsciudadesapo"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option></b-form-select
            >
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Teléfono (s):" style="text-align: left"
            ><b-form-input
              v-model="form.telapoderado"
              placeholder="Teléfono (s)"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Fax:" style="text-align: left"
            ><b-form-input
              v-model="form.faxapoderado"
              placeholder="Fax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correoapoderado"
              placeholder="Correo electrónico"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="5. Calidad en que actúa sobre el predio:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedcalidadactua"
              name="selectedcalidadactua"
            >
              <b-form-radio value="1">Propietario </b-form-radio>
              <b-form-radio value="2">Poseedor </b-form-radio>
              <b-form-radio value="3">Tenedor </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <!--------------------------cierre row finaliza el primer bloque de informacion ------------->
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div style="text-align: left; margin-top: 20px">
        <label style="font-weight: bold"
          >II. INFORMACIÓN GENERAL DEL PREDIO PARA EL CUAL SE SOLICITA EL
          PERMISO DE VERTIMIENTOS
        </label>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="1. Nombre del predio:" style="text-align: left"
            ><b-form-input
              v-model="form.nombrepredio"
              placeholder="Nombre del predio"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2. Dirección del predio:"
            style="text-align: left"
            ><b-form-input
              v-model="form.direccionpredio"
              placeholder="*Dirección del predio"
              maxlength="95"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="3. *Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departpredio"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Municipio:" style="text-align: left">
            <b-form-select
              v-model="form.ciudadpredio"
              :options="optionsCiudad"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="Nombre centro poblado, vereda y/o corregimiento:"
            style="text-align: left"
            ><b-form-input
              v-model="form1.nombrecentropoblado"
              placeholder="Nombre centro poblado, vereda y/o corregimiento:"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div style="text-align: left; margin-top: 20px">
        <label style="font-weight: bold"
          >III. INFORMACIÓN GENERAL DE LA ACTIVIDAD ECONÓMICA DEL SOLICITANTE
        </label>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="*1. Actividad económica:" style="text-align: left"
            ><b-form-input
              v-model="form1.actividadeco"
              placeholder="Actividad económica"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2. Código CIIU de la actividad económica (campo opcional, no dará lugar a la devolución del trámite ni a la solicitud de información adicional):"
            style="text-align: left"
            ><b-form-select
              v-model="form1.CodigoCIIU"
              :options="optionciiu"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="*3. Costo total del proyecto:"
            style="text-align: left"
            ><b-form-input
              v-model="form1.costototal"
              placeholder="Costo total del proyecto"
              maxlength="30"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div style="text-align: left; margin-top: 20px">
        <label style="font-weight: bold"
          >IV. INFORMACIÓN GENERAL DEL ÁREA DE DISPOSICIÓN Y DEL VERTIMIENTO
        </label>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group
            label="1. Localización del vertimiento"
            style="text-align: left"
          >
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form1.deparfuente"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Municipio:" style="text-align: left">
            <b-form-select
              v-model="form1.munifuente"
              :options="optionsCiudad"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group
            label="C. poblado/vereda/corregimiento:"
            style="text-align: left"
            ><b-form-input
              v-model="form1.direccionprediofuente"
              placeholder="Nombre centro poblado, vereda y/o corregimiento:"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2. Fuente de abastecimiento:"
            style="text-align: left"
            ><b-form-input
              v-model="form1.fuenteabastecimiento"
              placeholder="Fuente de abastecimiento"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group
            label="3. Coordenadas geográficas del área de disposición del vertimiento en sistema de referencia Magna Sirgas:"
            style="text-align: left"
          >
          </b-form-group>
        </div>
        <div class="col-md-6 border border-dark">
          <b-form-group
            label="Latitud"
            style="text-align: left; font-weight: bold"
          />
          <div class="row">
            <div class="col-md-3">
              <b-form-group label="Grados"
                ><b-form-input
                  v-model="form1.grado1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.grado2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.grado3"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group label="Minutos"
                ><b-form-input
                  v-model="form1.minutos1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.minutos2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.minutos3"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group label="Segundos"
                ><b-form-input
                  v-model="form1.segundos1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.segundos2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.segundos3"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="col-md-6 border border-dark">
          <b-form-group
            label="Longitud"
            style="text-align: left; font-weight: bold"
          />
          <div class="row">
            <div class="col-md-3">
              <b-form-group label="Grados"
                ><b-form-input
                  v-model="form1.grados1l"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.grados2l"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.grados3l"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group label="Minutos"
                ><b-form-input
                  v-model="form1.minutos1l"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.minutos2l"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.minutos3l"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group label="Segundos"
                ><b-form-input
                  v-model="form1.segundos1l"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.segundos2l"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.segundos3l"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group label="Altitud"
                ><b-form-input
                  v-model="form1.altitud1l"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.altitud2l"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  v-model="form1.altitud3l"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
        
        <div class="col-md-12">
              Nota: En caso de que el área de vertimiento al suelo esté conformada por más de un polígono, anexar en el mismo formato de la tabla anterior, la identificación de cada área numerada con sus respectivas coordenadas, mínimo 3, que conforman cada uno de los polígonos requeridos.
            <br><br></div>
        <div class="col-md-12">
          <b-form-group
            label="4. Uso del suelo actual del área de disposición:"
            style="text-align: left"
            ><b-form-input
              v-model="form1.usosueloactual"
              placeholder="Uso del suelo actual del área de disposición"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="Uso del suelo potencial del área de disposición:"
            style="text-align: left"
            ><b-form-input
              v-model="form1.usosuelopotencial"
              placeholder="Uso del suelo potencial del área de disposición"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="5. Tipo de vertimiento:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedtipoverti"
              name="selectedtipoverti"
            >
              <b-form-radio value="1"
                >Aguas residuales domésticas (ARD)</b-form-radio
              >
              <b-form-radio value="2"
                >Aguas residuales no domésticas (ARnD)</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="6. Tiempo de descarga" style="text-align: left">
            <div class="row">
              <div class="col-md-4">
                <b-form-group label="(horas/día):"
                  ><b-form-input
                    v-model="form1.tiempodescargahora"
                    maxlength="50"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Frecuencia (días/mes):"
                  ><b-form-input
                    v-model="form1.tiempodescargadias"
                    maxlength="50"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group
                  label="Caudal aproximado a verter (litros/segundo):"
                  ><b-form-input
                    v-model="form1.tiempodescargacuadal"
                    maxlength="50"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="7. Tipo de flujo de la descarga:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedtipoflujo"
              name="selectedtipoflujo"
            >
              <b-form-radio value="1">Continuo</b-form-radio>
              <b-form-radio value="2">Intermitente</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="8. Área de disposición del vertimiento (m2):"
            style="text-align: left"
            ><b-form-input
              v-model="form1.areavertimiento"
              placeholder="Área de disposición del vertimiento"
              maxlength="50"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <b-form-group
        label="9. Observaciones de acceso al punto de vertimiento o generalidades relevantes (adjuntar croquis):
     (campo opcional, no dará lugar a la devolución del trámite ni a la solicitud de información adicional)"
        style="text-align: left"
      >
      </b-form-group>
      <div class="col-md-12">
        <b-form-textarea
          id="textarea"
          v-model="form1.observa"
          placeholder="Observaciones de acceso al punto de vertimiento o generalidades relevantes..."
          rows="3"
          max-rows="6"
          maxlength="500"
        ></b-form-textarea>
        <br />
      </div>
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row" style="padding: 5px">
        <div class="col-md-12">
          <b-form-group
            label="FIRMA DEL SOLICITANTE O APODERADO DEBIDAMENTE CONSTITUIDO"
            style="text-align: left; font-weight: bold"
            ><b-form-input v-model="form1.firma" maxlength="50"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11">
          <b-form-file
            v-model="file1"
            :state="Boolean(file1)"
            placeholder="Adjunta los documentos, peso maximo total archivos 50 Mb, peso maximo por cada archivo 10 Mb"
            drop-placeholder="Drop file here..."
            multiple
            browse-text="Buscar"
          ></b-form-file>
        </div>
        <div class="col-md-1">
          <b-button id="popover-button-variant" href="#" tabindex="0"
            >Ayuda</b-button
          >
          <b-popover
            target="popover-button-variant"
            variant="info"
            triggers="focus"
          >
            <template #title>Tutorial</template>
            De click
            <b-link
              href="../../../document/Guía Rápida Adjuntar documentos Tramites Web.pdf"
              target="blank"
              >aqui</b-link
            >
          </b-popover>
        </div>
      </div>
      <br />
      <b-form-checkbox
        id="aceptacion"
        v-model="form1.aceptacion"
        name="aceptacion"
        value="1"
        unchecked-value="0"
        >Acepto el uso de mis datos personales, tratamiento, protección y
        conservación de estos a CVC de acuerdo a lo establecido en la Ley 1581
        de 2012.
        <a
          href="https://www.cvc.gov.co/politicas_de_seguridad_y_privacidad_de_la_informacion"
          >Ver Política de protección de Datos Personales de la CVC.</a
        > </b-form-checkbox
      ><br />
      <div v-show="show">
        <b-button block variant="success" @click="enviar()"
          >Enviar solicitud</b-button
        >
      </div>
      <br />
      <div class="text-center" v-show="loading">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <div class="text-center">
          <strong>Enviando Solicitud</strong><br />
          <strong
            >IMPORTANTE: Si adjuntó archivos, por favor espere que termine la
            subida y salga un mensaje con el número de radicado. Si abandona el
            proceso, la solicitud quedará incompleta...</strong
          >
        </div>
        <br />

        <div
          :key="ind"
          v-for="(tr, ind) in filesValide"
          class="row ml-8 mb-2 d-flex justify-content-center"
        >
          <b-progress
            :max="100"
            striped
            animated
            :variant="'primary'"
            class="w-75"
          >
            <b-progress-bar :value="tr.value === 0 ? 33 : tr.value">
              <span>
                {{
                  tr.value !== 0
                    ? tr.color
                      ? `Se proceso el archivo '${tr.name} de manera correcta.`
                      : `No se pudo procesar el archivo '${tr.name}'.`
                    : `Se esta procesando el archivo '${tr.name}'`
                }}
                <strong></strong></span
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
      <br />
    </div>

    <br />
    <div class="border border-dark container">
      <label style="font-weight: bold">INSTRUCCIONES DE DILIGENCIAMIENTO</label>
      <p style="text-align: justify">
        El presente Formato Único Nacional de solicitud de permiso de
        vertimiento al suelo busca orientar al usuario en la entrega de
        información general para el trámite correspondiente, permitiendo a la
        Autoridad Ambiental Competente - AAC consolidar la información básica
        para adelantar la visita de campo y generar el concepto técnico en el
        marco del permiso ambiental.
      </p>
      <b-form-group
        label="I. DATOS DEL SOLICITANTE (ARTÍCULO 2.2.3.3.5.2 DEL DECRETO 1076 DE 2015)"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        <b>1. Tipo de persona:</b> indicar el tipo de solicitante, ya sea una
        persona jurídica o natural.
      </p>
      <p style="text-align: justify">
        <b> 2. Tipo de trámite:</b> indicar si el trámite se refiere a un
        permiso nuevo, modificación o renovación de un permiso existente, de
        acuerdo con lo establecido en los artículos 2.2.3.3.5.9 y 2.2.3.3.5.10
        del Decreto 1076 de 2015. Asimismo, indicar el número de expediente, en
        caso que el trámite se relacione con un permiso existente (modificación
        o renovación).
      </p>
      <p style="text-align: justify">
        <b> 3. Nombre o Razón Social:</b> indicar el nombre o razón social del
        solicitante, señalar si el tipo de identificación es cédula de
        ciudadanía, número de identificación tributaria (NIT), personería
        jurídica, cédula de extranjería o pasaporte, con su respectivo número.
        Indicar la dirección de correspondencia del solicitante y otra
        información de contacto. Marcar con una "X" si se autoriza la
        notificación mediante el correo electrónico suministrado.
      </p>
      <p style="text-align: justify">
        <b> 4. Información del Representante legal o Apoderado:</b> digitar los
        nombres y apellidos del representante legal o el apoderado (si aplica)
        del solicitante, su tipo y número de identificación (CC o CE) e
        información de contacto.
      </p>
      <p style="text-align: justify">
        <b>
          5. Calidad en que actúa sobre el predio donde se realizará el
          vertimiento:</b
        >
        Indicar si el solicitante actúa en calidad de propietario, tenedor o
        poseedor del predio, según sea el caso, de acuerdo con el artículo
        2.2.3.2.9.2 del Decreto 1076 de 2015 y el Código Civil Colombiano.
        Dependiendo de la opción seleccionada, se debe adjuntar el respectivo
        soporte, de acuerdo con lo especificado en la sección ""DOCUMENTACIÓN
        QUE DEBE ANEXAR A LA SOLICITUD".
      </p>

      <b-form-group
        label="II. INFORMACIÓN GENERAL DEL PREDIO PARA EL CUAL SE SOLICITA EL PERMISO (ARTÍCULO 2.2.3.3.5.2 DEL DECRETO 1076 DE 2015)"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        <b>1. Nombre del predio:</b> registrar el nombre del predio solicitante
        del permiso, según lo establece el certificado de libertad y tradición.
      </p>
      <p style="text-align: justify">
        <b> 2. Dirección del predio:</b> indicar la localización del predio
        solicitante del permiso.
      </p>
      <p style="text-align: justify">
        <b> 3. Departamento:</b> indicar el Departamento, Municipio y el nombre
        del centro poblado y/o corregimiento en el que se localiza el predio.
      </p>

      <b-form-group
        label="III. INFORMACIÓN GENERAL DE LA ACTIVIDAD ECONÓMICA DEL SOLICITANTE (ARTÍCULO 2.2.3.3.5.2 DEL DECRETO 1076 DE 2015)"
        style="text-align: left; font-weight: bold"
      ></b-form-group>

      <p style="text-align: justify">
        <b>1. Actividad económica:</b> registre la actividad económica para la
        cual se requiere aprovechar el recurso hídrico, según lo definido por la
        DIAN. Consultar la Clasificacion Industrial Internacional Uniforme de
        todas las actividades economicas - CIIU en:
        https://www.dane.gov.co/index.php/sistema-estadistico-nacional-sen/normas-y-estandares/nomenclaturas-y-clasificaciones.
      </p>
      <p style="text-align: justify">
        <b
          >2. Código CIIU (Clasificación Industrial Internacional Uniforme) de
          la actividad económica (campo opcional, no dará lugar a la devolución
          del trámite ni a la solicitud de información adicional):</b
        >
        indique el código CIIU aplicable a la actividad económica desarrollada,
        con base en lo definido por la cámara de comercio. Consultar la
        Clasificacion Industrial Internacional Uniforme de todas las actividades
        economicas - CIIU en:
        https://www.dane.gov.co/index.php/sistema-estadistico-nacional-sen/normas-y-estandares/nomenclaturas-y-clasificaciones.
      </p>
      <p style="text-align: justify">
        <b>3. Costo total del proyecto:</b> registre el costo total del proyecto
        en letras y números, con el fin de evaluar las condiciones
        presupuestales para el cobro del servicio ambiental por parte de la
        Autoridad Ambiental Competente, de acuerdo con el artículo 96 de la Ley
        633 de 2000 y la Resolución 1280 de 2010.
      </p>

      <b-form-group
        label="IV. INFORMACIÓN GENERAL DEL ÁREA DE DISPOSICIÓN Y DEL VERTIMIENTO (ARTÍCULOS 2.2.3.3.4.9 Y 2.2.3.3.5.2 DEL DECRETO 1076 DE 2015)"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        <b>1. Tipo de cuerpo receptor:</b> Seleccionar el tipo de cuerpo de agua
        al que descargará el vertimiento, de acuerdo con las siguientes
        definiciones:
      </p>

      <p style="text-align: justify">
        <b>1. Localización del vertimiento:</b> especificar el departamento,
        municipio y centro poblado o corregimiento donde se ubica el área de
        disposición.
      </p>
      <p style="text-align: justify">
        <b> 2. Fuente de abastecimiento:</b> especificar la fuente de
        abastecimiento de agua del proyecto, obra o actividad, de acuerdo con lo
        establecido en el numeral 8 del artículo 2.2.3.3.5.2 del Decreto 1076 de
        2015.
      </p>
      <p style="text-align: justify">
        <b>
          3. Coordenadas geográficas del área de disposición del vertimiento:</b
        >
        especificar las coordenadas geográficas de los vértices del área de
        disposición del vertimiento en sistema de referencia MAGNA SIRGAS. Es
        necesario registrar la latitud, longitud y altitud, con el fin de
        facilitar la compatibilidad e interoperabilidad con las técnicas
        actuales de georreferenciación, en especial con los Sistemas Globales de
        Navegación por Satélite (SGNS), de acuerdo con el IGAC (Ver Resoluciones
        471 y 529 de 2020, Resolución 955 de 2012, Decreto 303 de 2012 y
        Resolución 068 de 2005, o aquellas que las modifiquen, adicionen o
        sustituyan).
      </p>
      <p style="text-align: justify">
        <b> 4. Uso del suelo actual del área de disposición:</b> Especificar el
        uso actual y potencial del área de disposición del vertimiento, con base
        en los instrumentos de planificación del territorio e información
        primaria y secundaria, identificando los usos actuales y conflictos de
        uso del suelo y del territorio (artículo 2.2.3.3.4.9 del Decreto 1076 de
        2015).
      </p>
      <p style="text-align: justify">
        <b> 5. Tipo de vertimiento:</b> indicar si la solicitud se realiza para
        el vertimiento de Aguas Residuales Domésticas (ARD) o Aguas Residuales
        no Domésticas (ARnD), de acuerdo con las definiciones del artículo 1 de
        la Resolución 631 de 2015 o aquella que la modifique o sustituya.
      </p>
      <p style="text-align: justify">
        <b> 6. Tiempo de descarga:</b>
        indicar el tiempo descarga del vertimiento en horas por día, la
        frecuencia en días por mes y el caudal aproximado a verter en litros por
        segundo.
      </p>
      <p style="text-align: justify">
        <b> 7. Tipo de flujo de la descarga:</b> seleccionar el tipo de flujo de
        la descarga, de acuerdo con lo establecido en el numeral 15 del artículo
        2.2.3.3.5.2 del Decreto 1076 de 2015:
      </p>
      <p style="text-align: justify">
        <b> Descarga continua:</b> el caudal y la concentración del agua
        residual no varían, debido a que se derivan de un mismo ciclo productivo
        que no presenta cambios apreciables en el tiempo.
      </p>
      <p style="text-align: justify">
        <b> Descarga intermitente:</b> el tiempo de descarga y la producción no
        es constante, creando una variación tanto en el caudal como en la carga
        contaminante.
      </p>
      <p style="text-align: justify">
        <b> 8. Área de disposición del vertimiento (m2):</b> indique la
        extensión del área de disposición del vertimiento, en metros cuadrados.
      </p>
      <p style="text-align: justify">
        <b>
          9. Observaciones de acceso al área de disposición del vertimiento o
          generalidades relevantes (campo opcional, no dará lugar a la
          devolución del trámite ni a la solicitud de información adicional):</b
        >
        mencionar generalidades asociadas al acceso al área de disposición para
        facilitar la visita de campo o aspectos relevantes que se crean
        convenientes mencionar. Adjuntar croquis.
      </p>

      <b-form-group
        label="DOCUMENTACIÓN QUE SE DEBE ANEXAR A LA SOLICITUD"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        Nota: De acuerdo con lo establecido en el artículo 2.2.3.3.5.10 del
        Decreto 1076 de 2015, "Para la renovación del permiso de vertimiento se
        deberá observar el trámite previsto para el otorgamiento de dicho
        permiso en el presente decreto. Si no existen cambios en la actividad
        generadora del vertimiento, la renovación queda supeditada solo a la
        verificación del cumplimiento de la norma de vertimiento mediante la
        caracterización del vertimiento".
      </p>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Documentación</th>
            <th scope="col">Prestadores del servicio de alcantarillado</th>
            <th scope="col">Vertimiento de ARD</th>
            <th scope="col">Vertimiento de ARnD</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p style="text-align: justify">
                Poder debidamente otorgado cuando se actúe por medio de
                apoderado (numeral 2 del artículo 2.2.3.3.5.2 del Decreto 1076
                de 2015).
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Documentos que acrediten la personería jurídica del solicitante,
                cuando aplique, hasta tanto no se realice la interoperabilidad
                entre los sistemas de información de las entidades que emiten
                las acreditaciones y la Autoridad Ambiental Competente (numeral
                3 del artículo 2.2.3.3.5.2 del Decreto 1076 de 2015):
              </p>
              <p style="text-align: justify">
                -Sociedades: Certificado de existencia y representación legal
                (expedición no superior a 3 meses).
              </p>
              <p style="text-align: justify">
                -Juntas de Acción Comunal: Certificado de existencia y
                representación legal o del documento que haga sus veces,
                expedido con una antelación no superior a 3 meses.
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Certificado actualizado del Registrador de Instrumentos Públicos
                y Privados sobre la propiedad del inmueble, o la prueba idónea
                de la posesión o tenencia (numerales 4 y 5 del artículo
                2.2.3.3.5.2 del Decreto 1076 de 2015):
              </p>
              <p style="text-align: justify">
                - Propietario del inmueble: Certificado de tradición y libertad
                (expedición no superior a 3 meses)
              </p>
              <p style="text-align: justify">
                - Tenedor: Prueba adecuada que lo acredite como tal,
                autorización del propietario o poseedor y Certificado de
                tradición y libertad del inmueble (expedición no superior a 3
                meses).
              </p>
              <p style="text-align: justify">
                - Poseedor: Prueba adecuada que lo acredite como tal y
                Certificado de tradición y libertad del inmueble (expedición no
                superior a 3 meses)
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Características de las actividades que generan el vertimiento
                (numeral 9 del artículo 2.2.3.3.5.2 del Decreto 1076 de 2015).
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Plano donde se identifique origen, cantidad y localización
                georreferenciada de las descargas al cuerpo de agua (numeral 10
                del artículo 2.2.3.3.5.2 del Decreto 1076 de 2015).
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Caracterización actual del vertimiento existente o estado final
                previsto para el vertimiento proyectado (numeral 16 del artículo
                2.2.3.3.5.2 del Decreto 1076 de 2015), de acuerdo con los
                parámetros mínimos establecidos para cada actividad en la norma
                de vertimientos correspondiente (Resolución 631 de 2015,
                Resolución 883 de 2018 o aquella que las modifique, adicione o
                sustituya).
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Ubicación, descripción de la operación del sistema, memorias
                técnicas y diseños de ingeniería conceptual y básica, planos de
                detalle del sistema de tratamiento (existente o propuesto) y
                condiciones de eficiencia del sistema de tratamiento que se
                adoptará (numeral 17 del artículo 2.2.3.3.5.2 del Decreto 1076
                de 2015).
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Concepto sobre el uso del suelo expedido por la autoridad
                municipal competente (numeral 18 del artículo 2.2.3.3.5.2 del
                Decreto 1076 de 2015).
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Documento Evaluación Ambiental del Vertimiento (numeral 19 del
                artículo 2.2.3.3.5.2 del Decreto 1076 de 2015). De acuerdo con
                el artículo 2.2.3.3.5.3 del Decreto 1076 de 2015, la evaluación
                ambiental del vertimiento deberá ser presentada por los
                generadores de vertimientos a cuerpos de aguas o al suelo que
                desarrollen actividades industriales, comerciales y/o de
                servicio, así como los provenientes de conjuntos residenciales
                (*), y deberá contener como mínimo:
              </p>
              <p style="text-align: justify">
                1. Localización georreferenciada de proyecto, obra o actividad.
              </p>
              <p style="text-align: justify">
                2. Memoria detallada del proyecto, obra o actividad que se
                pretenda realizar, con especificaciones de procesos y
                tecnologías que serán empleados en la gestión del vertimiento.
              </p>
              <p style="text-align: justify">
                3. Información detallada sobre la naturaleza de los insumos,
                productos químicos, formas de energía empleados y los procesos
                químicos y físicos utilizados en el desarrollo del proyecto,
                obra o actividad que genera vertimientos.
              </p>

              <p style="text-align: justify">
                4. Predicción y valoración de los impactos que puedan derivarse
                de los vertimientos generados por el proyecto, obra o actividad
                al suelo, considerando su vocación conforme a lo dispuesto en
                los instrumentos de ordenamiento territorial y los Planes de
                Manejo Ambiental de Acuíferos. Cuando estos últimos no existan,
                la autoridad ambiental competente definirá los términos y
                condiciones bajo los cuales se debe realizar la identificación
                de los impactos y la gestión ambiental de los mismos.
              </p>
              <p style="text-align: justify">
                5. Manejo de residuos asociados a la gestión del vertimiento.
              </p>
              <p style="text-align: justify">
                6. Descripción y valoración de los impactos generados por el
                vertimiento y las medidas para prevenir, mitigar, corregir y
                compensar dichos impactos al suelo.
              </p>
              <p style="text-align: justify">
                7. Posible incidencia del proyecto, obra o actividad en la
                calidad de la vida o en las condiciones económicas, sociales y
                culturales de los habitantes del sector o de la región en donde
                pretende desarrollarse y medidas que se adoptarán para evitar o
                minimizar efectos negativos de orden sociocultural que puedan
                derivarse de la misma.
              </p>
              <p style="text-align: justify">
                8. Estudios técnicos y diseños de la estructura de descarga de
                los vertimientos, que sustenten su localización y
                características.
              </p>
              <p style="text-align: justify">
                (*) Nota: El parágrafo 2 del artículo 2.2.3.3.5.3 del Decreto
                1076 de 2015 establece que "(...) en relación con los conjuntos
                residenciales, la autoridad ambiental definirá los casos en los
                cuales no estarán obligados a presentar la evaluación ambiental
                del vertimiento en función de la capacidad de carga del cuerpo
                receptor, densidad de ocupación del suelo y densidad
                poblacional".
              </p>
            </td>
            <td>
              X
              <p>
                (Aplica si el vertimiento proviene de actividades industriales,
                comerciales o de servicios, de acuerdo con el artículo
                2.2.3.3.5.3 del Decreto 1076 de 2015: "La evaluación ambiental
                del vertimiento deberá ser presentada por los generadores de
                vertimientos a cuerpos de aguas o al suelo que desarrollen
                actividades industriales, comerciales y/o de servicio, así como
                los provenientes de conjuntos residenciales")
              </p>
            </td>
            <td>
              X
              <p>
                (Aplica si el vertimiento proviene de actividades industriales,
                comerciales o de servicios, de acuerdo con el artículo
                2.2.3.3.5.3 del Decreto 1076 de 2015: "La evaluación ambiental
                del vertimiento deberá ser presentada por los generadores de
                vertimientos a cuerpos de aguas o al suelo que desarrollen
                actividades industriales, comerciales y/o de servicio, así como
                los provenientes de conjuntos residenciales")
              </p>
            </td>
            <td>
              X
              <p>
                (Aplica si el vertimiento proviene de actividades industriales,
                comerciales o de servicios, de acuerdo con el artículo
                2.2.3.3.5.3 del Decreto 1076 de 2015: "La evaluación ambiental
                del vertimiento deberá ser presentada por los generadores de
                vertimientos a cuerpos de aguas o al suelo que desarrollen
                actividades industriales, comerciales y/o de servicio, así como
                los provenientes de conjuntos residenciales")
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Documento Plan de Gestión del Riesgo para el manejo de
                vertimientos (numeral 20 del artículo 2.2.3.3.5.2 del Decreto
                1076 de 2015). De acuerdo con el artículo 2.2.3.3.5.4 del
                Decreto 1076 de 2015, el plan de gestión del riesgo aplica para
                vertimientos a cuerpos de agua provenientes de actividades
                industriales, comerciales y de servicio, ya sean ARD o ARnD.
                Dicho Plan debe incluir el análisis del riesgo, medidas de
                prevención y mitigación, protocolos de emergencia y contingencia
                y programa de rehabilitación y recuperación, incluyendo
                (Resolución 1514 de 2012):
              </p>
              <p style="text-align: justify">
                - Generalidades (objetivos, antecedentes, alcances y
                metodología)
              </p>
              <p style="text-align: justify">
                - Descripción de las actividades y procesos asociados al sistema
                de gestión del vertimiento (localización, componentes y
                funcionamiento).
              </p>
              <p style="text-align: justify">
                - Caracterización del área de influencia (área de influencia,
                descripción medio abiótica, descripción medio biótica,
                descripción medio socio económico).
              </p>
              <p style="text-align: justify">
                - Proceso del conocimiento del riesgo (identificación y
                determinación de la probabilidad de ocurrencia y/o presencia de
                amenaza; identificación y análisis de vulnerabilidad y
                Consolidación de los escenarios de riesgo).
              </p>
              <p style="text-align: justify">
                - Proceso de reducción del riesgo asociado al sistema de gestión
                del vertimiento.
              </p>
              <p style="text-align: justify">
                - Proceso de manejo del desastre (preparación para la respuesta,
                preparación para la recuperación post desastre y ejecución de la
                respuesta y la respectiva recuperación).
              </p>
              <p style="text-align: justify">
                - Sistema de seguimiento y evaluación del plan.
              </p>
              <p style="text-align: justify">- Divulgación del plan.</p>
              <p style="text-align: justify">
                - Actualización y vigencia del plan.
              </p>
              <p style="text-align: justify">
                - Profesionales responsables de la formulación del plan.
              </p>
              <p style="text-align: justify">- Anexos y planos.</p>
            </td>
            <td>
              X
              <p>
                (Aplica si el vertimiento proviene de actividades industriales,
                comerciales o de servicios, de acuerdo con el artículo
                2.2.3.3.5.4 del Decreto 1076 de 2015: "Las personas naturales o
                jurídicas de derecho público o privado que desarrollen
                actividades industriales, comerciales y de servicios que generen
                vertimientos a un cuerpo de agua o al suelo deberán elaborar un
                Plan de Gestión del Riesgo para el Manejo de Vertimientos")
              </p>
            </td>
            <td>
              X
              <p>
                (Aplica si el vertimiento proviene de actividades industriales,
                comerciales o de servicios, de acuerdo con el artículo
                2.2.3.3.5.4 del Decreto 1076 de 2015: "Las personas naturales o
                jurídicas de derecho público o privado que desarrollen
                actividades industriales, comerciales y de servicios que generen
                vertimientos a un cuerpo de agua o al suelo deberán elaborar un
                Plan de Gestión del Riesgo para el Manejo de Vertimientos")
              </p>
            </td>
            <td>
              X
              <p>
                (Aplica si el vertimiento proviene de actividades industriales,
                comerciales o de servicios, de acuerdo con el artículo
                2.2.3.3.5.4 del Decreto 1076 de 2015: "Las personas naturales o
                jurídicas de derecho público o privado que desarrollen
                actividades industriales, comerciales y de servicios que generen
                vertimientos a un cuerpo de agua o al suelo deberán elaborar un
                Plan de Gestión del Riesgo para el Manejo de Vertimientos")
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Para aguas residuales domésticas tratadas (artículo 2.2.3.3.4.9
                del Decreto 1076 de 2015):
              </p>
              <p style="text-align: justify">
                <b>1. Infiltración.</b> Resultados y datos de campo de pruebas
                de infiltración calculando la tasa de infiltración.
              </p>
              <p style="text-align: justify">
                <b> 2. Sistema de disposición de los vertimientos.</b> Diseño y
                manual de operación y mantenimiento del sistema de disposición
                de aguas residuales tratadas al suelo, incluyendo el mecanismo
                de descarga y sus elementos estructurantes que permiten el
                vertimiento al suelo.
              </p>
              <p style="text-align: justify">
                <b> 3. Área de disposición del vertimiento.</b> Identificación
                del área donde se realizará la disposición en plano topográfico
                con coordenadas magna sirgas, indicando como mínimo: dimensión
                requerida, los usos de los suelos en las áreas colindantes y el
                uso actual y potencial del suelo donde se realizará el
                vertimiento del agua residual doméstica tratada, conforme al
                Plan de Ordenación y Manejo de Cuenca Hidrográfica y los
                instrumentos de ordenamiento territorial vigentes.
              </p>
              <p style="text-align: justify">
                <b>
                  4. Plan de cierre y abandono del área de disposición del
                  vertimiento.</b
                >
                Plan que define el uso que se le dará al área que se utilizó
                como disposición del vertimiento. Para tal fin, las actividades
                contempladas en el plan de cierre deben garantizar que las
                condiciones físicas, químicas y biológicas del suelo permiten el
                uso potencial definido en los instrumentos de ordenamiento
                territorial vigentes y sin perjuicio de la afectación sobre la
                salud pública.
              </p>
            </td>
            <td>
              X
              <p>
                (Casos en los cuales el prestador del servicio de alcantarillado
                cuente con ARD exclusivas)
              </p>
            </td>
            <td>X</td>
            <td></td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Para aguas residuales no domésticas tratadas (artículo
                2.2.3.3.4.9 del Decreto 1076 de 2015):
              </p>
              <p style="text-align: justify">
                <b> 1. Línea base del suelo:</b> caracterización fisicoquímica y
                biológica del suelo, relacionada con el área de disposición del
                vertimiento.
              </p>
              <p style="text-align: justify">
                <b> 2. Línea base del agua subterránea:</b> Determinación de la
                dirección de flujo mediante monitoreo del nivel del agua
                subterránea en pozos o aljibes existentes o en piezómetros
                construidos para dicho propósito, previa nivelación topográfica
                de los mismos.
              </p>
              <p style="text-align: justify">
                <b> 3. Sistema de disposición de los vertimientos.</b> Diseño y
                manual de operación y mantenimiento del sistema de disposición
                de aguas residuales tratadas al suelo, incluyendo el mecanismo
                de descarga y sus elementos estructurantes que permiten el
                vertimiento al suelo. El diseño del sistema de disposición de
                los vertimientos debe incluir la siguiente documentación de
                soporte para el análisis: a) Modelación numérica del flujo y
                transporte de solutos en el suelo; b) Análisis hidrológico que
                incluya la caracterización de los periodos secos y húmedos en la
                cuenca hidrográfica en la cual se localice la solicitud de
                vertimiento; c) Descripción del sistema y equipos para el manejo
                de la disposición al suelo del agua residual tratada; d)
                Determinación de la variación del nivel freático o
                potenciométrico con base en la información recolectada en campo,
                considerando condiciones hidroclimáticas e hidrogeológicas; e)
                Determinación y mapeo a escala 1:10.000 o de mayor detalle de la
                vulnerabilidad intrínseca de los acuíferos a la contaminación,
                sustentando la selección del método utilizado.
              </p>
              <p style="text-align: justify">
                <b> 4. Área de disposición del vertimiento.</b> Identificación
                del área donde se realizará la disposición en plano topográfico
                con coordenadas magna sirgas, indicando como mínimo: dimensión
                requerida, los usos de los suelos en las áreas colindantes y el
                uso actual y potencial del suelo donde se realizará el
                vertimiento del agua residual tratada.
              </p>
              <p style="text-align: justify">
                <b> 5. Plan de monitoreo.</b> Estructurar el Plan de Monitoreo
                para la caracterización del efluente, del suelo y del agua
                subterránea, acorde a la caracterización fisicoquímica del
                vertimiento a realizar, incluyendo grasas y aceites a menos que
                se demuestre que las grasas y aceites no se encuentran presentes
                en sus aguas residuales tratadas.
              </p>
              <p style="text-align: justify">
                <b>
                  6. Plan de cierre y abandono del área de disposición del
                  vertimiento.</b
                >
                Plan que deberá definir el uso que se le dará al área que se
                utilizó como disposición del vertimiento.
              </p>
            </td>
            <td>
              X
              <p>
                (Casos en los cuales el prestador del servicio de alcantarillado
                cuente con ARD + ARnD)
              </p>
            </td>
            <td></td>
            <td>X</td>
          </tr>

          <tr>
            <td>
              <p style="text-align: justify">
                Certificado de disponibilidad o conexión al acueducto, cuando se
                trate de usuarios que cuentan con dicho servicio pero no pueden
                conectarse al sistema de alcantarillado.
              </p>
            </td>
            <td></td>
            <td>X</td>
            <td>X</td>
          </tr>
        </tbody>
      </table>
    </div>

    <br />
  </div>
  <!--cierre div principal-->
</template>

<script>
import {
  rutafile,
  rutaciudad,
  rutaenviosolicitud,
  tiponotificacion,
  logo,
} from "../properties";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      form: {
        tipotramite: "",
        mediorecepcion: "4",
        observacionradicado: "PERMISO VERTIMIENTO AL SUELO",
        asunto: "PERMISO VERTIMIENTO AL SUELO",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram: "", //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        expe: "",
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        desciudad: "", //CAMBIO
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: tiponotificacion,
        selectedrepre: "",
        selectedtipoperapo: "1",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        desciudadapoderado: "", //CAMBIO
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        nombrecentropoblado: "",
        logo: logo,
        filesname: "",
      },
      form1: {
        actividadeco: "",
        CodigoCIIU: "",
        costototal: "",
        deparfuente: "",
        munifuente: "",
        direccionprediofuente: "",
        fuenteabastecimiento: "",
        grado1: "",
        grado2: "",
        grado3: "",
        minutos1: "",
        minutos2: "",
        minutos3: "",
        segundos1: "",
        segundos2: "",
        segundos3: "",
        grados1l: "",
        grados2l: "",
        grados3l: "",
        minutos1l: "",
        minutos2l: "",
        minutos3l: "",
        segundos1l: "",
        segundos2l: "",
        segundos3l: "",
        altitud1l: "",
        altitud2l: "",
        altitud3l: "",
        usosueloactual: "",
        usosuelopotencial: "",
        selectedtipoverti: "",
        selectedcaptacion: "",
        tiempodescargahora: "",
        tiempodescargadias: "",
        tiempodescargacuadal: "",
        selectedtipoflujo: "",
        areavertimiento: "",
        observa: "",
        firma: "",
        aceptacion: "",
        tipovertmiento: "2",
      },
      form2: {},
      ciudadapoderado: "", //CAMBIO
      ciudadcompleta: "", //CAMBIO
      loading: false,
      show: true,
      showciudad: false,
      showciudadapo: false,
      file1: [],
      pesofile1: 0,
      nombrefiles: [],
      arryaJson: [],
      contadorarchivos: 0, // nueva------------------------------------------------------------
      contadorresponse: 0, // nueva------------------------------------------------------------
      filesValide: [], // nueva----------------------------------------------------------------
      optionsCiudad: [
        { value: "1000", text: "SANTIAGO DE CALI" },
        { value: "20000", text: "ALCALA" },
        { value: "36000", text: "ANDALUCIA" },
        { value: "41000", text: "ANSERMANUEVO" },
        { value: "54000", text: "ARGELIA" },
        { value: "100000", text: "BOLIVAR" },
        { value: "109000", text: "BUENAVENTURA" },
        { value: "111000", text: "GUADALAJARA DE BUGA" },
        { value: "113000", text: "BUGALAGRANDE" },
        { value: "122000", text: "CAICEDONIA" },
        { value: "126000", text: "DARIEN" },
        { value: "130000", text: "CANDELARIA" },
        { value: "147000", text: "CARTAGO" },
        { value: "233000", text: "DAGUA" },
        { value: "243000", text: "EL AGUILA" },
        { value: "246000", text: "EL CAIRO" },
        { value: "248000", text: "EL CERRITO" },
        { value: "250000", text: "EL DOVIO" },
        { value: "275000", text: "FLORIDA" },
        { value: "306000", text: "GINEBRA" },
        { value: "318000", text: "GUACARI" },
        { value: "364000", text: "JAMUNDI" },
        { value: "377000", text: "LA CUMBRE" },
        { value: "400000", text: "LA UNION" },
        { value: "403000", text: "LA VICTORIA" },
        { value: "497000", text: "OBANDO" },
        { value: "520000", text: "PALMIRA" },
        { value: "563000", text: "PRADERA" },
        { value: "606000", text: "RESTREPO" },
        { value: "616000", text: "RIOFRIO" },
        { value: "622000", text: "ROLDANILLO" },
        { value: "670000", text: "SAN PEDRO" },
        { value: "736000", text: "SEVILLA" },
        { value: "823000", text: "TORO" },
        { value: "828000", text: "TRUJILLO" },
        { value: "834000", text: "TULUA" },
        { value: "845000", text: "ULLOA" },
        { value: "863000", text: "VERSALLES" },
        { value: "869000", text: "VIJES" },
        { value: "890000", text: "YOTOCO" },
        { value: "892000", text: "YUMBO" },
        { value: "895000", text: "ZARZAL" },
      ],
      optionsdepart: [{ value: "76", text: "VALLE DEL CAUCA" }],
      optionsdepartamento: [
        { value: "91", text: "AMAZONAS" },
        { value: "5", text: "ANTIOQUIA" },
        { value: "81", text: "ARAUCA" },
        {
          value: "88",
          text: "ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA",
        },
        { value: "8", text: "ATLANTICO" },
        { value: "11", text: "BOGOTA D.C" },
        { value: "13", text: "BOLIVAR" },
        { value: "15", text: "BOYACA" },
        { value: "17", text: "CALDAS" },
        { value: "18", text: "CAQUETA" },
        { value: "85", text: "CASANARE" },
        { value: "19", text: "CAUCA" },
        { value: "20", text: "CESAR" },
        { value: "27", text: "CHOCO" },
        { value: "23", text: "CORDOBA" },
        { value: "25", text: "CUNDINAMARCA" },
        { value: "94", text: "GUAINIA" },
        { value: "95", text: "GUAVIARE" },
        { value: "41", text: "HUILA" },
        { value: "44", text: "LA GUAJIRA" },
        { value: "47", text: "MAGDALENA" },
        { value: "50", text: "META" },
        { value: "52", text: "NARIÑO" },
        { value: "54", text: "NORTE DE SANTANDER" },
        { value: "86", text: "PUTUMAYO" },
        { value: "63", text: "QUINDIO" },
        { value: "66", text: "RISARALDA" },
        { value: "68", text: "SANTANDER" },
        { value: "70", text: "SUCRE" },
        { value: "73", text: "TOLIMA" },
        { value: "76", text: "VALLE DEL CAUCA" },
        { value: "97", text: "VAUPES" },
        { value: "99", text: "VICHADA" },
      ],
      optionsciudades: [],
      optionsciudadesapo: [], //CAMBIO
      optionstiponoti: [
        { value: "2", text: "CORREO CERTIFICADO" },
        { value: "1", text: "CORREO ELECTRONICO" },
        { value: "7", text: "NOTIFICACIÓN PERSONAL" },
      ],
      optionciiu: [
        {
          value:
            "0111 - Cultivo de cereales (excepto arroz), legumbres y semillas oleaginosas",
          text: "0111 - Cultivo de cereales (excepto arroz), legumbres y semillas oleaginosas",
        },
        { value: "0112 - Cultivo de arroz", text: "0112 - Cultivo de arroz" },
        {
          value: "0113 - Cultivo de hortalizas, raíces y tubérculos",
          text: "0113 - Cultivo de hortalizas, raíces y tubérculos",
        },
        { value: "0114 - Cultivo de tabaco", text: "0114 - Cultivo de tabaco" },
        {
          value: "0115 - Cultivo de plantas textiles",
          text: "0115 - Cultivo de plantas textiles",
        },
        {
          value: "0119 - Otros cultivos transitorios n.c.p.",
          text: "0119 - Otros cultivos transitorios n.c.p.",
        },
        {
          value: "0121 - Cultivo de frutas tropicales y subtropicales",
          text: "0121 - Cultivo de frutas tropicales y subtropicales",
        },
        {
          value: "0122 - Cultivo de plátano y banano",
          text: "0122 - Cultivo de plátano y banano",
        },
        { value: "0123 - Cultivo de café", text: "0123 - Cultivo de café" },
        {
          value: "0124 - Cultivo de caña de azúcar",
          text: "0124 - Cultivo de caña de azúcar",
        },
        {
          value: "0125 - Cultivo de flor de corte",
          text: "0125 - Cultivo de flor de corte",
        },
        {
          value:
            "0126 - Cultivo de palma para aceite (palma africana) y otros frutos oleaginosos",
          text: "0126 - Cultivo de palma para aceite (palma africana) y otros frutos oleaginosos",
        },
        {
          value: "0127 - Cultivo de plantas con las que se preparan bebidas",
          text: "0127 - Cultivo de plantas con las que se preparan bebidas",
        },
        {
          value:
            "0128 - Cultivo de especias y de plantas aromáticas y medicinales",
          text: "0128 - Cultivo de especias y de plantas aromáticas y medicinales",
        },
        {
          value: "0129 - Otros cultivos permanentes n.c.p.",
          text: "0129 - Otros cultivos permanentes n.c.p.",
        },
        {
          value:
            "0130 - Propagación de plantas (actividades de los viveros, excepto viveros forestales)",
          text: "0130 - Propagación de plantas (actividades de los viveros, excepto viveros forestales)",
        },
        {
          value: "0141 - Cría de ganado bovino y bufalino",
          text: "0141 - Cría de ganado bovino y bufalino",
        },
        {
          value: "0142 - Cría de caballos y otros equinos",
          text: "0142 - Cría de caballos y otros equinos",
        },
        {
          value: "0143 - Cría de ovejas y cabras",
          text: "0143 - Cría de ovejas y cabras",
        },
        {
          value: "0144 - Cría de ganado porcino",
          text: "0144 - Cría de ganado porcino",
        },
        {
          value: "0145 - Cría de aves de corral",
          text: "0145 - Cría de aves de corral",
        },
        {
          value: "0149 - Cría de otros animales n.c.p.",
          text: "0149 - Cría de otros animales n.c.p.",
        },
        {
          value: "0150 - Explotación mixta (agrícola y pecuaria)",
          text: "0150 - Explotación mixta (agrícola y pecuaria)",
        },
        {
          value: "0161 - Actividades de apoyo a la agricultura",
          text: "0161 - Actividades de apoyo a la agricultura",
        },
        {
          value: "0162 - Actividades de apoyo a la ganadería",
          text: "0162 - Actividades de apoyo a la ganadería",
        },
        {
          value: "0163 - Actividades posteriores a la cosecha",
          text: "0163 - Actividades posteriores a la cosecha",
        },
        {
          value: "0164 - Tratamiento de semillas para propagación",
          text: "0164 - Tratamiento de semillas para propagación",
        },
        {
          value:
            "0170 - Caza ordinaria y mediante trampas y actividades de servicios conexas",
          text: "0170 - Caza ordinaria y mediante trampas y actividades de servicios conexas",
        },
        {
          value: "0210 - Silvicultura y otras actividades forestales",
          text: "0210 - Silvicultura y otras actividades forestales",
        },
        {
          value: "0220 - Extracción de madera",
          text: "0220 - Extracción de madera",
        },
        {
          value:
            "0230 - Recolección de productos forestales diferentes a la madera",
          text: "0230 - Recolección de productos forestales diferentes a la madera",
        },
        {
          value: "0240 - Servicios de apoyo a la silvicultura",
          text: "0240 - Servicios de apoyo a la silvicultura",
        },
        { value: "0311 - Pesca marítima", text: "0311 - Pesca marítima" },
        {
          value: "0312 - Pesca de agua dulce",
          text: "0312 - Pesca de agua dulce",
        },
        {
          value: "0321 - Acuicultura marítima",
          text: "0321 - Acuicultura marítima",
        },
        {
          value: "0322 - Acuicultura de agua dulce",
          text: "0322 - Acuicultura de agua dulce",
        },
        {
          value: "0510 - Extracción de hulla (carbón de piedra)",
          text: "0510 - Extracción de hulla (carbón de piedra)",
        },
        {
          value: "0520 - Extracción de carbón lignito",
          text: "0520 - Extracción de carbón lignito",
        },
        {
          value: "0610 - Extracción de petróleo crudo",
          text: "0610 - Extracción de petróleo crudo",
        },
        {
          value: "0620 - Extracción de gas natural",
          text: "0620 - Extracción de gas natural",
        },
        {
          value: "0710 - Extracción de minerales de hierro",
          text: "0710 - Extracción de minerales de hierro",
        },
        {
          value: "0721 - Extracción de minerales de uranio y de torio",
          text: "0721 - Extracción de minerales de uranio y de torio",
        },
        {
          value: "0722 - Extracción de oro y otros metales preciosos",
          text: "0722 - Extracción de oro y otros metales preciosos",
        },
        {
          value: "0723 - Extracción de minerales de níquel",
          text: "0723 - Extracción de minerales de níquel",
        },
        {
          value:
            "0729 - Extracción de otros minerales metalíferos no ferrosos n.c.p.",
          text: "0729 - Extracción de otros minerales metalíferos no ferrosos n.c.p.",
        },
        {
          value:
            "0811 - Extracción de piedra, arena, arcillas comunes, yeso y anhidrita",
          text: "0811 - Extracción de piedra, arena, arcillas comunes, yeso y anhidrita",
        },
        {
          value:
            "0812 - Extracción de arcillas de uso industrial, caliza, caolín y bentonitas",
          text: "0812 - Extracción de arcillas de uso industrial, caliza, caolín y bentonitas",
        },
        {
          value:
            "0820 - Extracción de esmeraldas, piedras preciosas y semipreciosas",
          text: "0820 - Extracción de esmeraldas, piedras preciosas y semipreciosas",
        },
        {
          value:
            "0891 - Extracción de minerales para la fabricación de abonos y productos químicos",
          text: "0891 - Extracción de minerales para la fabricación de abonos y productos químicos",
        },
        {
          value: "0892 - Extracción de halita (sal)",
          text: "0892 - Extracción de halita (sal)",
        },
        {
          value: "0899 - Extracción de otros minerales no metálicos n.c.p.",
          text: "0899 - Extracción de otros minerales no metálicos n.c.p.",
        },
        {
          value:
            "0910 - Actividades de apoyo para la extracción de petróleo y de gas natural",
          text: "0910 - Actividades de apoyo para la extracción de petróleo y de gas natural",
        },
        {
          value:
            "0990 - Actividades de apoyo para otras actividades de explotación de minas y canteras",
          text: "0990 - Actividades de apoyo para otras actividades de explotación de minas y canteras",
        },
        {
          value:
            "1011 - Procesamiento y conservación de carne y productos cárnicos",
          text: "1011 - Procesamiento y conservación de carne y productos cárnicos",
        },
        {
          value:
            "1012 - Procesamiento y conservación de pescados, crustáceos y moluscos",
          text: "1012 - Procesamiento y conservación de pescados, crustáceos y moluscos",
        },
        {
          value:
            "1020 - Procesamiento y conservación de frutas, legumbres, hortalizas y tubérculos",
          text: "1020 - Procesamiento y conservación de frutas, legumbres, hortalizas y tubérculos",
        },
        {
          value: "1031 - Extracción de aceites de origen vegetal crudos",
          text: "1031 - Extracción de aceites de origen vegetal crudos",
        },
        {
          value:
            "1032 - Elaboración de aceites y grasas de origen vegetal refinados",
          text: "1032 - Elaboración de aceites y grasas de origen vegetal refinados",
        },
        {
          value: "1033 - Elaboración de aceites y grasas de origen animal",
          text: "1033 - Elaboración de aceites y grasas de origen animal",
        },
        {
          value: "1040 - Elaboración de productos lácteos",
          text: "1040 - Elaboración de productos lácteos",
        },
        {
          value: "1051 - Elaboración de productos de molinería",
          text: "1051 - Elaboración de productos de molinería",
        },
        {
          value:
            "1052 - Elaboración de almidones y productos derivados del almidón",
          text: "1052 - Elaboración de almidones y productos derivados del almidón",
        },
        { value: "1061 - Trilla de café", text: "1061 - Trilla de café" },
        {
          value: "1062 - Descafeinado, tostión y molienda del café",
          text: "1062 - Descafeinado, tostión y molienda del café",
        },
        {
          value: "1063 - Otros derivados del café",
          text: "1063 - Otros derivados del café",
        },
        {
          value: "1071 - Elaboración y refinación de azúcar",
          text: "1071 - Elaboración y refinación de azúcar",
        },
        {
          value: "1072 - Elaboración de panela",
          text: "1072 - Elaboración de panela",
        },
        {
          value: "1081 - Elaboración de productos de panadería",
          text: "1081 - Elaboración de productos de panadería",
        },
        {
          value:
            "1082 - Elaboración de cacao, chocolate y productos de confitería",
          text: "1082 - Elaboración de cacao, chocolate y productos de confitería",
        },
        {
          value:
            "1083 - Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares",
          text: "1083 - Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares",
        },
        {
          value: "1084 - Elaboración de comidas y platos preparados",
          text: "1084 - Elaboración de comidas y platos preparados",
        },
        {
          value: "1089 - Elaboración de otros productos alimenticios n.c.p.",
          text: "1089 - Elaboración de otros productos alimenticios n.c.p.",
        },
        {
          value: "1090 - Elaboración de alimentos preparados para animales",
          text: "1090 - Elaboración de alimentos preparados para animales",
        },
        {
          value:
            "1101 - Destilación, rectificación y mezcla de bebidas alcohólicas",
          text: "1101 - Destilación, rectificación y mezcla de bebidas alcohólicas",
        },
        {
          value: "1102 - Elaboración de bebidas fermentadas no destiladas",
          text: "1102 - Elaboración de bebidas fermentadas no destiladas",
        },
        {
          value:
            "1103 - Producción de malta, elaboración de cervezas y otras bebidas malteadas",
          text: "1103 - Producción de malta, elaboración de cervezas y otras bebidas malteadas",
        },
        {
          value:
            "1104 - Elaboración de bebidas no alcohólicas, producción de aguas minerales y otras aguas embotelladas",
          text: "1104 - Elaboración de bebidas no alcohólicas, producción de aguas minerales y otras aguas embotelladas",
        },
        {
          value: "1200 - Elaboración de productos de tabaco",
          text: "1200 - Elaboración de productos de tabaco",
        },
        {
          value: "1311 - Preparación e hilatura de fibras textiles",
          text: "1311 - Preparación e hilatura de fibras textiles",
        },
        {
          value: "1312 - Tejeduría de productos textiles",
          text: "1312 - Tejeduría de productos textiles",
        },
        {
          value: "1313 - Acabado de productos textiles",
          text: "1313 - Acabado de productos textiles",
        },
        {
          value: "1391 - Fabricación de tejidos de punto y ganchillo",
          text: "1391 - Fabricación de tejidos de punto y ganchillo",
        },
        {
          value:
            "1392 - Confección de artículos con materiales textiles, excepto prendas de vestir",
          text: "1392 - Confección de artículos con materiales textiles, excepto prendas de vestir",
        },
        {
          value: "1393 - Fabricación de tapetes y alfombras para pisos",
          text: "1393 - Fabricación de tapetes y alfombras para pisos",
        },
        {
          value:
            "1394 - Fabricación de cuerdas, cordeles, cables, bramantes y redes",
          text: "1394 - Fabricación de cuerdas, cordeles, cables, bramantes y redes",
        },
        {
          value: "1399 - Fabricación de otros artículos textiles n.c.p.",
          text: "1399 - Fabricación de otros artículos textiles n.c.p.",
        },
        {
          value:
            "1410 - Confección de prendas de vestir, excepto prendas de piel",
          text: "1410 - Confección de prendas de vestir, excepto prendas de piel",
        },
        {
          value: "1420 - Fabricación de artículos de piel",
          text: "1420 - Fabricación de artículos de piel",
        },
        {
          value: "1430 - Fabricación de artículos de punto y ganchillo",
          text: "1430 - Fabricación de artículos de punto y ganchillo",
        },
        {
          value:
            "1511 - Curtido y recurtido de cueros; recurtido y teñido de pieles",
          text: "1511 - Curtido y recurtido de cueros; recurtido y teñido de pieles",
        },
        {
          value:
            "1512 - Fabricación de artículos de viaje, bolsos de mano y artículos similares elaborados en cuero, y fabricación de artículos de talabartería y guarnicionería",
          text: "1512 - Fabricación de artículos de viaje, bolsos de mano y artículos similares elaborados en cuero, y fabricación de artículos de talabartería y guarnicionería",
        },
        {
          value:
            "1513 - Fabricación de artículos de viaje, bolsos de mano y artículos similares; artículos de talabartería y guarnicionería elaborados en otros materiales",
          text: "1513 - Fabricación de artículos de viaje, bolsos de mano y artículos similares; artículos de talabartería y guarnicionería elaborados en otros materiales",
        },
        {
          value:
            "1521 - Fabricación de calzado de cuero y piel, con cualquier tipo de suela",
          text: "1521 - Fabricación de calzado de cuero y piel, con cualquier tipo de suela",
        },
        {
          value:
            "1522 - Fabricación de otros tipos de calzado, excepto calzado de cuero y piel",
          text: "1522 - Fabricación de otros tipos de calzado, excepto calzado de cuero y piel",
        },
        {
          value: "1523 - Fabricación de partes del calzado",
          text: "1523 - Fabricación de partes del calzado",
        },
        {
          value: "1610 - Aserrado, acepillado e impregnación de la madera",
          text: "1610 - Aserrado, acepillado e impregnación de la madera",
        },
        {
          value:
            "1620 - Fabricación de hojas de madera para enchapado; fabricación de tableros contrachapados, tableros laminados, tableros de partículas y otros tableros y paneles",
          text: "1620 - Fabricación de hojas de madera para enchapado; fabricación de tableros contrachapados, tableros laminados, tableros de partículas y otros tableros y paneles",
        },
        {
          value:
            "1630 - Fabricación de partes y piezas de madera, de carpintería y ebanistería para la construcción",
          text: "1630 - Fabricación de partes y piezas de madera, de carpintería y ebanistería para la construcción",
        },
        {
          value: "1640 - Fabricación de recipientes de madera",
          text: "1640 - Fabricación de recipientes de madera",
        },
        {
          value:
            "1690 - Fabricación de otros productos de madera; fabricación de artículos de corcho, cestería y espartería",
          text: "1690 - Fabricación de otros productos de madera; fabricación de artículos de corcho, cestería y espartería",
        },
        {
          value:
            "1701 - Fabricación de pulpas (pastas) celulósicas; papel y cartón",
          text: "1701 - Fabricación de pulpas (pastas) celulósicas; papel y cartón",
        },
        {
          value:
            "1702 - Fabricación de papel y cartón ondulado (corrugado); fabricación de envases, empaques y de embalajes de papel y cartón",
          text: "1702 - Fabricación de papel y cartón ondulado (corrugado); fabricación de envases, empaques y de embalajes de papel y cartón",
        },
        {
          value: "1709 - Fabricación de otros artículos de papel y cartón",
          text: "1709 - Fabricación de otros artículos de papel y cartón",
        },
        {
          value: "1811 - Actividades de impresión",
          text: "1811 - Actividades de impresión",
        },
        {
          value:
            "1812 - Actividades de servicios relacionados con la impresión",
          text: "1812 - Actividades de servicios relacionados con la impresión",
        },
        {
          value:
            "1820 - Producción de copias a partir de grabaciones originales",
          text: "1820 - Producción de copias a partir de grabaciones originales",
        },
        {
          value: "1910 - Fabricación de productos de hornos de coque",
          text: "1910 - Fabricación de productos de hornos de coque",
        },
        {
          value:
            "1921 - Fabricación de productos de la refinación del petróleo",
          text: "1921 - Fabricación de productos de la refinación del petróleo",
        },
        {
          value: "1922 - Actividad de mezcla de combustibles",
          text: "1922 - Actividad de mezcla de combustibles",
        },
        {
          value:
            "2011 - Fabricación de sustancias y productos químicos básicos",
          text: "2011 - Fabricación de sustancias y productos químicos básicos",
        },
        {
          value:
            "2012 - Fabricación de abonos y compuestos inorgánicos nitrogenados",
          text: "2012 - Fabricación de abonos y compuestos inorgánicos nitrogenados",
        },
        {
          value: "2013 - Fabricación de plásticos en formas primarias",
          text: "2013 - Fabricación de plásticos en formas primarias",
        },
        {
          value: "2014 - Fabricación de caucho sintético en formas primarias",
          text: "2014 - Fabricación de caucho sintético en formas primarias",
        },
        {
          value:
            "2021 - Fabricación de plaguicidas y otros productos químicos de uso agropecuario",
          text: "2021 - Fabricación de plaguicidas y otros productos químicos de uso agropecuario",
        },
        {
          value:
            "2022 - Fabricación de pinturas, barnices y revestimientos similares, tintas para impresión y masillas",
          text: "2022 - Fabricación de pinturas, barnices y revestimientos similares, tintas para impresión y masillas",
        },
        {
          value:
            "2023 - Fabricación de jabones y detergentes, preparados para limpiar y pulir; perfumes y preparados de tocador",
          text: "2023 - Fabricación de jabones y detergentes, preparados para limpiar y pulir; perfumes y preparados de tocador",
        },
        {
          value: "2029 - Fabricación de otros productos químicos n.c.p.",
          text: "2029 - Fabricación de otros productos químicos n.c.p.",
        },
        {
          value: "2030 - Fabricación de fibras sintéticas y artificiales",
          text: "2030 - Fabricación de fibras sintéticas y artificiales",
        },
        {
          value:
            "2100 - Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos de uso farmacéutico",
          text: "2100 - Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos de uso farmacéutico",
        },
        {
          value: "2211 - Fabricación de llantas y neumáticos de caucho",
          text: "2211 - Fabricación de llantas y neumáticos de caucho",
        },
        {
          value: "2212 - Reencauche de llantas usadas",
          text: "2212 - Reencauche de llantas usadas",
        },
        {
          value:
            "2219 - Fabricación de formas básicas de caucho y otros productos de caucho n.c.p.",
          text: "2219 - Fabricación de formas básicas de caucho y otros productos de caucho n.c.p.",
        },
        {
          value: "2221 - Fabricación de formas básicas de plástico",
          text: "2221 - Fabricación de formas básicas de plástico",
        },
        {
          value: "2229 - Fabricación de artículos de plástico n.c.p.",
          text: "2229 - Fabricación de artículos de plástico n.c.p.",
        },
        {
          value: "2310 - Fabricación de vidrio y productos de vidrio",
          text: "2310 - Fabricación de vidrio y productos de vidrio",
        },
        {
          value: "2391 - Fabricación de productos refractarios",
          text: "2391 - Fabricación de productos refractarios",
        },
        {
          value:
            "2392 - Fabricación de materiales de arcilla para la construcción",
          text: "2392 - Fabricación de materiales de arcilla para la construcción",
        },
        {
          value:
            "2393 - Fabricación de otros productos de cerámica y porcelana",
          text: "2393 - Fabricación de otros productos de cerámica y porcelana",
        },
        {
          value: "2394 - Fabricación de cemento, cal y yeso",
          text: "2394 - Fabricación de cemento, cal y yeso",
        },
        {
          value: "2395 - Fabricación de artículos de hormigón, cemento y yeso",
          text: "2395 - Fabricación de artículos de hormigón, cemento y yeso",
        },
        {
          value: "2396 - Corte, tallado y acabado de la piedra",
          text: "2396 - Corte, tallado y acabado de la piedra",
        },
        {
          value:
            "2399 - Fabricación de otros productos minerales no metálicos n.c.p.",
          text: "2399 - Fabricación de otros productos minerales no metálicos n.c.p.",
        },
        {
          value: "2410 - Industrias básicas de hierro y de acero",
          text: "2410 - Industrias básicas de hierro y de acero",
        },
        {
          value: "2421 - Industrias básicas de metales preciosos",
          text: "2421 - Industrias básicas de metales preciosos",
        },
        {
          value: "2429 - Industrias básicas de otros metales no ferrosos",
          text: "2429 - Industrias básicas de otros metales no ferrosos",
        },
        {
          value: "2431 - Fundición de hierro y de acero",
          text: "2431 - Fundición de hierro y de acero",
        },
        {
          value: "2432 - Fundición de metales no ferrosos",
          text: "2432 - Fundición de metales no ferrosos",
        },
        {
          value:
            "2511 - Fabricación de productos metálicos para uso estructural",
          text: "2511 - Fabricación de productos metálicos para uso estructural",
        },
        {
          value:
            "2512 - Fabricación de tanques, depósitos y recipientes de metal, excepto los utilizados para el envase o el transporte de mercancías",
          text: "2512 - Fabricación de tanques, depósitos y recipientes de metal, excepto los utilizados para el envase o el transporte de mercancías",
        },
        {
          value:
            "2513 - Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central",
          text: "2513 - Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central",
        },
        {
          value: "2520 - Fabricación de armas y municiones",
          text: "2520 - Fabricación de armas y municiones",
        },
        {
          value:
            "2591 - Forja, prensado, estampado y laminado de metal; pulvimetalurgia",
          text: "2591 - Forja, prensado, estampado y laminado de metal; pulvimetalurgia",
        },
        {
          value: "2592 - Tratamiento y revestimiento de metales; mecanizado",
          text: "2592 - Tratamiento y revestimiento de metales; mecanizado",
        },
        {
          value:
            "2593 - Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería",
          text: "2593 - Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería",
        },
        {
          value:
            "2599 - Fabricación de otros productos elaborados de metal n.c.p.",
          text: "2599 - Fabricación de otros productos elaborados de metal n.c.p.",
        },
        {
          value: "2610 - Fabricación de componentes y tableros electrónicos",
          text: "2610 - Fabricación de componentes y tableros electrónicos",
        },
        {
          value: "2620 - Fabricación de computadoras y de equipo periférico",
          text: "2620 - Fabricación de computadoras y de equipo periférico",
        },
        {
          value: "2630 - Fabricación de equipos de comunicación",
          text: "2630 - Fabricación de equipos de comunicación",
        },
        {
          value: "2640 - Fabricación de aparatos electrónicos de consumo",
          text: "2640 - Fabricación de aparatos electrónicos de consumo",
        },
        {
          value:
            "2651 - Fabricación de equipo de medición, prueba, navegación y control",
          text: "2651 - Fabricación de equipo de medición, prueba, navegación y control",
        },
        {
          value: "2652 - Fabricación de relojes",
          text: "2652 - Fabricación de relojes",
        },
        {
          value:
            "2660 - Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico",
          text: "2660 - Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico",
        },
        {
          value:
            "2670 - Fabricación de instrumentos ópticos y equipo fotográfico",
          text: "2670 - Fabricación de instrumentos ópticos y equipo fotográfico",
        },
        {
          value:
            "2680 - Fabricación de medios magnéticos y ópticos para almacenamiento de datos",
          text: "2680 - Fabricación de medios magnéticos y ópticos para almacenamiento de datos",
        },
        {
          value:
            "2711 - Fabricación de motores, generadores y transformadores eléctricos",
          text: "2711 - Fabricación de motores, generadores y transformadores eléctricos",
        },
        {
          value:
            "2712 - Fabricación de aparatos de distribución y control de la energía eléctrica",
          text: "2712 - Fabricación de aparatos de distribución y control de la energía eléctrica",
        },
        {
          value:
            "2720 - Fabricación de pilas, baterías y acumuladores eléctricos",
          text: "2720 - Fabricación de pilas, baterías y acumuladores eléctricos",
        },
        {
          value:
            "2731 - Fabricación de hilos y cables eléctricos y de fibra óptica",
          text: "2731 - Fabricación de hilos y cables eléctricos y de fibra óptica",
        },
        {
          value: "2732 - Fabricación de dispositivos de cableado",
          text: "2732 - Fabricación de dispositivos de cableado",
        },
        {
          value: "2740 - Fabricación de equipos eléctricos de iluminación",
          text: "2740 - Fabricación de equipos eléctricos de iluminación",
        },
        {
          value: "2750 - Fabricación de aparatos de uso doméstico",
          text: "2750 - Fabricación de aparatos de uso doméstico",
        },
        {
          value: "2790 - Fabricación de otros tipos de equipo eléctrico n.c.p.",
          text: "2790 - Fabricación de otros tipos de equipo eléctrico n.c.p.",
        },
        {
          value:
            "2811 - Fabricación de motores, turbinas, y partes para motores de combustión interna",
          text: "2811 - Fabricación de motores, turbinas, y partes para motores de combustión interna",
        },
        {
          value:
            "2812 - Fabricación de equipos de potencia hidráulica y neumática",
          text: "2812 - Fabricación de equipos de potencia hidráulica y neumática",
        },
        {
          value:
            "2813 - Fabricación de otras bombas, compresores, grifos y válvulas",
          text: "2813 - Fabricación de otras bombas, compresores, grifos y válvulas",
        },
        {
          value:
            "2814 - Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión",
          text: "2814 - Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión",
        },
        {
          value:
            "2815 - Fabricación de hornos, hogares y quemadores industriales",
          text: "2815 - Fabricación de hornos, hogares y quemadores industriales",
        },
        {
          value: "2816 - Fabricación de equipo de elevación y manipulación",
          text: "2816 - Fabricación de equipo de elevación y manipulación",
        },
        {
          value:
            "2817 - Fabricación de maquinaria y equipo de oficina (excepto computadoras y equipo periférico)",
          text: "2817 - Fabricación de maquinaria y equipo de oficina (excepto computadoras y equipo periférico)",
        },
        {
          value: "2818 - Fabricación de herramientas manuales con motor",
          text: "2818 - Fabricación de herramientas manuales con motor",
        },
        {
          value:
            "2819 - Fabricación de otros tipos de maquinaria y equipo de uso general n.c.p.",
          text: "2819 - Fabricación de otros tipos de maquinaria y equipo de uso general n.c.p.",
        },
        {
          value: "2821 - Fabricación de maquinaria agropecuaria y forestal",
          text: "2821 - Fabricación de maquinaria agropecuaria y forestal",
        },
        {
          value:
            "2822 - Fabricación de máquinas formadoras de metal y de máquinas herramienta",
          text: "2822 - Fabricación de máquinas formadoras de metal y de máquinas herramienta",
        },
        {
          value: "2823 - Fabricación de maquinaria para la metalurgia",
          text: "2823 - Fabricación de maquinaria para la metalurgia",
        },
        {
          value:
            "2824 - Fabricación de maquinaria para explotación de minas y canteras y para obras de construcción",
          text: "2824 - Fabricación de maquinaria para explotación de minas y canteras y para obras de construcción",
        },
        {
          value:
            "2825 - Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco",
          text: "2825 - Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco",
        },
        {
          value:
            "2826 - Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros",
          text: "2826 - Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros",
        },
        {
          value:
            "2829 - Fabricación de otros tipos de maquinaria y equipo de uso especial n.c.p.",
          text: "2829 - Fabricación de otros tipos de maquinaria y equipo de uso especial n.c.p.",
        },
        {
          value: "2910 - Fabricación de vehículos automotores y sus motores",
          text: "2910 - Fabricación de vehículos automotores y sus motores",
        },
        {
          value:
            "2920 - Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques",
          text: "2920 - Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques",
        },
        {
          value:
            "2930 - Fabricación de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores",
          text: "2930 - Fabricación de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores",
        },
        {
          value: "3011 - Construcción de barcos y de estructuras flotantes",
          text: "3011 - Construcción de barcos y de estructuras flotantes",
        },
        {
          value: "3012 - Construcción de embarcaciones de recreo y deporte",
          text: "3012 - Construcción de embarcaciones de recreo y deporte",
        },
        {
          value:
            "3020 - Fabricación de locomotoras y de material rodante para ferrocarriles",
          text: "3020 - Fabricación de locomotoras y de material rodante para ferrocarriles",
        },
        {
          value:
            "3030 - Fabricación de aeronaves, naves espaciales y de maquinaria conexa",
          text: "3030 - Fabricación de aeronaves, naves espaciales y de maquinaria conexa",
        },
        {
          value: "3040 - Fabricación de vehículos militares de combate",
          text: "3040 - Fabricación de vehículos militares de combate",
        },
        {
          value: "3091 - Fabricación de motocicletas",
          text: "3091 - Fabricación de motocicletas",
        },
        {
          value:
            "3092 - Fabricación de bicicletas y de sillas de ruedas para personas con discapacidad",
          text: "3092 - Fabricación de bicicletas y de sillas de ruedas para personas con discapacidad",
        },
        {
          value:
            "3099 - Fabricación de otros tipos de equipo de transporte n.c.p.",
          text: "3099 - Fabricación de otros tipos de equipo de transporte n.c.p.",
        },
        {
          value: "3110 - Fabricación de muebles",
          text: "3110 - Fabricación de muebles",
        },
        {
          value: "3120 - Fabricación de colchones y somieres",
          text: "3120 - Fabricación de colchones y somieres",
        },
        {
          value: "3211 - Fabricación de joyas y artículos conexos",
          text: "3211 - Fabricación de joyas y artículos conexos",
        },
        {
          value: "3212 - Fabricación de bisutería y artículos conexos",
          text: "3212 - Fabricación de bisutería y artículos conexos",
        },
        {
          value: "3220 - Fabricación de instrumentos musicales",
          text: "3220 - Fabricación de instrumentos musicales",
        },
        {
          value:
            "3230 - Fabricación de artículos y equipo para la práctica del deporte",
          text: "3230 - Fabricación de artículos y equipo para la práctica del deporte",
        },
        {
          value: "3240 - Fabricación de juegos, juguetes y rompecabezas",
          text: "3240 - Fabricación de juegos, juguetes y rompecabezas",
        },
        {
          value:
            "3250 - Fabricación de instrumentos, aparatos y materiales médicos y odontológicos (incluido mobiliario)",
          text: "3250 - Fabricación de instrumentos, aparatos y materiales médicos y odontológicos (incluido mobiliario)",
        },
        {
          value: "3290 - Otras industrias manufactureras n.c.p.",
          text: "3290 - Otras industrias manufactureras n.c.p.",
        },
        {
          value:
            "3311 - Mantenimiento y reparación especializado de productos elaborados en metal",
          text: "3311 - Mantenimiento y reparación especializado de productos elaborados en metal",
        },
        {
          value:
            "3312 - Mantenimiento y reparación especializado de maquinaria y equipo",
          text: "3312 - Mantenimiento y reparación especializado de maquinaria y equipo",
        },
        {
          value:
            "3313 - Mantenimiento y reparación especializado de equipo electrónico y óptico",
          text: "3313 - Mantenimiento y reparación especializado de equipo electrónico y óptico",
        },
        {
          value:
            "3314 - Mantenimiento y reparación especializado de equipo eléctrico",
          text: "3314 - Mantenimiento y reparación especializado de equipo eléctrico",
        },
        {
          value:
            "3315 - Mantenimiento y reparación especializado de equipo de transporte, excepto los vehículos automotores, motocicletas y bicicletas",
          text: "3315 - Mantenimiento y reparación especializado de equipo de transporte, excepto los vehículos automotores, motocicletas y bicicletas",
        },
        {
          value:
            "3319 - Mantenimiento y reparación de otros tipos de equipos y sus componentes n.c.p.",
          text: "3319 - Mantenimiento y reparación de otros tipos de equipos y sus componentes n.c.p.",
        },
        {
          value:
            "3320 - Instalación especializada de maquinaria y equipo industrial",
          text: "3320 - Instalación especializada de maquinaria y equipo industrial",
        },
        {
          value: "3511 - Generación de energía eléctrica",
          text: "3511 - Generación de energía eléctrica",
        },
        {
          value: "3512 - Transmisión de energía eléctrica",
          text: "3512 - Transmisión de energía eléctrica",
        },
        {
          value: "3513 - Distribución de energía eléctrica",
          text: "3513 - Distribución de energía eléctrica",
        },
        {
          value: "3514 - Comercialización de energía eléctrica",
          text: "3514 - Comercialización de energía eléctrica",
        },
        {
          value:
            "3520 - Producción de gas; distribución de combustibles gaseosos por tuberías",
          text: "3520 - Producción de gas; distribución de combustibles gaseosos por tuberías",
        },
        {
          value: "3530 - Suministro de vapor y aire acondicionado",
          text: "3530 - Suministro de vapor y aire acondicionado",
        },
        {
          value: "3600 - Captación, tratamiento y distribución de agua",
          text: "3600 - Captación, tratamiento y distribución de agua",
        },
        {
          value: "3700 - Evacuación y tratamiento de aguas residuales",
          text: "3700 - Evacuación y tratamiento de aguas residuales",
        },
        {
          value: "3811 - Recolección de desechos no peligrosos",
          text: "3811 - Recolección de desechos no peligrosos",
        },
        {
          value: "3812 - Recolección de desechos peligrosos",
          text: "3812 - Recolección de desechos peligrosos",
        },
        {
          value: "3821 - Tratamiento y disposición de desechos no peligrosos",
          text: "3821 - Tratamiento y disposición de desechos no peligrosos",
        },
        {
          value: "3822 - Tratamiento y disposición de desechos peligrosos",
          text: "3822 - Tratamiento y disposición de desechos peligrosos",
        },
        {
          value: "3830 - Recuperación de materiales",
          text: "3830 - Recuperación de materiales",
        },
        {
          value:
            "3900 - Actividades de saneamiento ambiental y otros servicios de gestión de desechos",
          text: "3900 - Actividades de saneamiento ambiental y otros servicios de gestión de desechos",
        },
        {
          value: "4111 - Construcción de edificios residenciales",
          text: "4111 - Construcción de edificios residenciales",
        },
        {
          value: "4112 - Construcción de edificios no residenciales",
          text: "4112 - Construcción de edificios no residenciales",
        },
        {
          value: "4210 - Construcción de carreteras y vías de ferrocarril",
          text: "4210 - Construcción de carreteras y vías de ferrocarril",
        },
        {
          value: "4220 - Construcción de proyectos de servicio público",
          text: "4220 - Construcción de proyectos de servicio público",
        },
        {
          value: "4290 - Construcción de otras obras de ingeniería civil",
          text: "4290 - Construcción de otras obras de ingeniería civil",
        },
        { value: "4311 - Demolición", text: "4311 - Demolición" },
        {
          value: "4312 - Preparación del terreno",
          text: "4312 - Preparación del terreno",
        },
        {
          value: "4321 - Instalaciones eléctricas",
          text: "4321 - Instalaciones eléctricas",
        },
        {
          value:
            "4322 - Instalaciones de fontanería, calefacción y aire acondicionado",
          text: "4322 - Instalaciones de fontanería, calefacción y aire acondicionado",
        },
        {
          value: "4329 - Otras instalaciones especializadas",
          text: "4329 - Otras instalaciones especializadas",
        },
        {
          value:
            "4330 - Terminación y acabado de edificios y obras de ingeniería civil",
          text: "4330 - Terminación y acabado de edificios y obras de ingeniería civil",
        },
        {
          value:
            "4390 - Otras actividades especializadas para la construcción de edificios y obras de ingeniería civil",
          text: "4390 - Otras actividades especializadas para la construcción de edificios y obras de ingeniería civil",
        },
        {
          value: "4511 - Comercio de vehículos automotores nuevos",
          text: "4511 - Comercio de vehículos automotores nuevos",
        },
        {
          value: "4512 - Comercio de vehículos automotores usados",
          text: "4512 - Comercio de vehículos automotores usados",
        },
        {
          value: "4520 - Mantenimiento y reparación de vehículos automotores",
          text: "4520 - Mantenimiento y reparación de vehículos automotores",
        },
        {
          value:
            "4530 - Comercio de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores",
          text: "4530 - Comercio de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores",
        },
        {
          value:
            "4541 - Comercio de motocicletas y de sus partes, piezas y accesorios",
          text: "4541 - Comercio de motocicletas y de sus partes, piezas y accesorios",
        },
        {
          value:
            "4542 - Mantenimiento y reparación de motocicletas y de sus partes y piezas",
          text: "4542 - Mantenimiento y reparación de motocicletas y de sus partes y piezas",
        },
        {
          value:
            "4610 - Comercio al por mayor a cambio de una retribución o por contrata",
          text: "4610 - Comercio al por mayor a cambio de una retribución o por contrata",
        },
        {
          value:
            "4620 - Comercio al por mayor de materias primas agropecuarias; animales vivos",
          text: "4620 - Comercio al por mayor de materias primas agropecuarias; animales vivos",
        },
        {
          value: "4631 - Comercio al por mayor de productos alimenticios",
          text: "4631 - Comercio al por mayor de productos alimenticios",
        },
        {
          value: "4632 - Comercio al por mayor de bebidas y tabaco",
          text: "4632 - Comercio al por mayor de bebidas y tabaco",
        },
        {
          value:
            "4641 - Comercio al por mayor de productos textiles, productos confeccionados para uso doméstico",
          text: "4641 - Comercio al por mayor de productos textiles, productos confeccionados para uso doméstico",
        },
        {
          value: "4642 - Comercio al por mayor de prendas de vestir",
          text: "4642 - Comercio al por mayor de prendas de vestir",
        },
        {
          value: "4643 - Comercio al por mayor de calzado",
          text: "4643 - Comercio al por mayor de calzado",
        },
        {
          value:
            "4644 - Comercio al por mayor de aparatos y equipo de uso doméstico",
          text: "4644 - Comercio al por mayor de aparatos y equipo de uso doméstico",
        },
        {
          value:
            "4645 - Comercio al por mayor de productos farmacéuticos, medicinales, cosméticos y de tocador",
          text: "4645 - Comercio al por mayor de productos farmacéuticos, medicinales, cosméticos y de tocador",
        },
        {
          value:
            "4649 - Comercio al por mayor de otros utensilios domésticos n.c.p.",
          text: "4649 - Comercio al por mayor de otros utensilios domésticos n.c.p.",
        },
        {
          value:
            "4651 - Comercio al por mayor de computadores, equipo periférico y programas de informática",
          text: "4651 - Comercio al por mayor de computadores, equipo periférico y programas de informática",
        },
        {
          value:
            "4652 - Comercio al por mayor de equipo, partes y piezas electrónicos y de telecomunicaciones",
          text: "4652 - Comercio al por mayor de equipo, partes y piezas electrónicos y de telecomunicaciones",
        },
        {
          value:
            "4653 - Comercio al por mayor de maquinaria y equipo agropecuarios",
          text: "4653 - Comercio al por mayor de maquinaria y equipo agropecuarios",
        },
        {
          value:
            "4659 - Comercio al por mayor de otros tipos de maquinaria y equipo n.c.p.",
          text: "4659 - Comercio al por mayor de otros tipos de maquinaria y equipo n.c.p.",
        },
        {
          value:
            "4661 - Comercio al por mayor de combustibles sólidos, líquidos, gaseosos y productos conexos",
          text: "4661 - Comercio al por mayor de combustibles sólidos, líquidos, gaseosos y productos conexos",
        },
        {
          value:
            "4662 - Comercio al por mayor de metales y productos metalíferos",
          text: "4662 - Comercio al por mayor de metales y productos metalíferos",
        },
        {
          value:
            "4663 - Comercio al por mayor de materiales de construcción, artículos de ferretería, pinturas, productos de vidrio, equipo y materiales de fontanería y calefacción",
          text: "4663 - Comercio al por mayor de materiales de construcción, artículos de ferretería, pinturas, productos de vidrio, equipo y materiales de fontanería y calefacción",
        },
        {
          value:
            "4664 - Comercio al por mayor de productos químicos básicos, cauchos y plásticos en formas primarias y productos químicos de uso agropecuario",
          text: "4664 - Comercio al por mayor de productos químicos básicos, cauchos y plásticos en formas primarias y productos químicos de uso agropecuario",
        },
        {
          value:
            "4665 - Comercio al por mayor de desperdicios, desechos y chatarra",
          text: "4665 - Comercio al por mayor de desperdicios, desechos y chatarra",
        },
        {
          value: "4669 - Comercio al por mayor de otros productos n.c.p.",
          text: "4669 - Comercio al por mayor de otros productos n.c.p.",
        },
        {
          value: "4690 - Comercio al por mayor no especializado",
          text: "4690 - Comercio al por mayor no especializado",
        },
        {
          value:
            "4711 - Comercio al por menor en establecimientos no especializados con surtido compuesto principalmente por alimentos, bebidas (alcohólicas y no alcohólicas) o tabaco",
          text: "4711 - Comercio al por menor en establecimientos no especializados con surtido compuesto principalmente por alimentos, bebidas (alcohólicas y no alcohólicas) o tabaco",
        },
        {
          value:
            "4719 - Comercio al por menor en establecimientos no especializados, con surtido compuesto principalmente por productos diferentes de alimentos (víveres en general), bebidas (alcohólicas y no alcohólicas) y tabaco",
          text: "4719 - Comercio al por menor en establecimientos no especializados, con surtido compuesto principalmente por productos diferentes de alimentos (víveres en general), bebidas (alcohólicas y no alcohólicas) y tabaco",
        },
        {
          value:
            "4721 - Comercio al por menor de productos agrícolas para el consumo en establecimientos especializados",
          text: "4721 - Comercio al por menor de productos agrícolas para el consumo en establecimientos especializados",
        },
        {
          value:
            "4722 - Comercio al por menor de leche, productos lácteos y huevos, en establecimientos especializados",
          text: "4722 - Comercio al por menor de leche, productos lácteos y huevos, en establecimientos especializados",
        },
        {
          value:
            "4723 - Comercio al por menor de carnes (incluye aves de corral), productos cárnicos, pescados y productos de mar, en establecimientos especializados",
          text: "4723 - Comercio al por menor de carnes (incluye aves de corral), productos cárnicos, pescados y productos de mar, en establecimientos especializados",
        },
        {
          value:
            "4724 - Comercio al por menor de bebidas y productos del tabaco, en establecimientos especializados",
          text: "4724 - Comercio al por menor de bebidas y productos del tabaco, en establecimientos especializados",
        },
        {
          value:
            "4729 - Comercio al por menor de otros productos alimenticios n.c.p., en establecimientos especializados",
          text: "4729 - Comercio al por menor de otros productos alimenticios n.c.p., en establecimientos especializados",
        },
        {
          value: "4731 - Comercio al por menor de combustible para automotores",
          text: "4731 - Comercio al por menor de combustible para automotores",
        },
        {
          value:
            "4732 - Comercio al por menor de lubricantes (aceites, grasas), aditivos y productos de limpieza para vehículos automotores",
          text: "4732 - Comercio al por menor de lubricantes (aceites, grasas), aditivos y productos de limpieza para vehículos automotores",
        },
        {
          value:
            "4741 - Comercio al por menor de computadores, equipos periféricos, programas de informática y equipos de telecomunicaciones en establecimientos especializados",
          text: "4741 - Comercio al por menor de computadores, equipos periféricos, programas de informática y equipos de telecomunicaciones en establecimientos especializados",
        },
        {
          value:
            "4742 - Comercio al por menor de equipos y aparatos de sonido y de video, en establecimientos especializados",
          text: "4742 - Comercio al por menor de equipos y aparatos de sonido y de video, en establecimientos especializados",
        },
        {
          value:
            "4751 - Comercio al por menor de productos textiles en establecimientos especializados",
          text: "4751 - Comercio al por menor de productos textiles en establecimientos especializados",
        },
        {
          value:
            "4752 - Comercio al por menor de artículos de ferretería, pinturas y productos de vidrio en establecimientos especializados",
          text: "4752 - Comercio al por menor de artículos de ferretería, pinturas y productos de vidrio en establecimientos especializados",
        },
        {
          value:
            "4753 - Comercio al por menor de tapices, alfombras y recubrimientos para paredes y pisos en establecimientos especializados",
          text: "4753 - Comercio al por menor de tapices, alfombras y recubrimientos para paredes y pisos en establecimientos especializados",
        },
        {
          value:
            "4754 - Comercio al por menor de electrodomésticos y gasodomésticos, muebles y equipos de iluminación en establecimientos especializados",
          text: "4754 - Comercio al por menor de electrodomésticos y gasodomésticos, muebles y equipos de iluminación en establecimientos especializados",
        },
        {
          value:
            "4755 - Comercio al por menor de artículos y utensilios de uso doméstico en establecimientos especializados",
          text: "4755 - Comercio al por menor de artículos y utensilios de uso doméstico en establecimientos especializados",
        },
        {
          value:
            "4759 - Comercio al por menor de otros artículos domésticos en establecimientos especializados",
          text: "4759 - Comercio al por menor de otros artículos domésticos en establecimientos especializados",
        },
        {
          value:
            "4761 - Comercio al por menor de libros, periódicos, materiales y artículos de papelería y escritorio, en establecimientos especializados",
          text: "4761 - Comercio al por menor de libros, periódicos, materiales y artículos de papelería y escritorio, en establecimientos especializados",
        },
        {
          value:
            "4762 - Comercio al por menor de artículos deportivos, en establecimientos especializados",
          text: "4762 - Comercio al por menor de artículos deportivos, en establecimientos especializados",
        },
        {
          value:
            "4769 - Comercio al por menor de otros artículos culturales y de entretenimiento n.c.p. en establecimientos especializados",
          text: "4769 - Comercio al por menor de otros artículos culturales y de entretenimiento n.c.p. en establecimientos especializados",
        },
        {
          value:
            "4771 - Comercio al por menor de prendas de vestir y sus accesorios (incluye artículos de piel) en establecimientos especializados",
          text: "4771 - Comercio al por menor de prendas de vestir y sus accesorios (incluye artículos de piel) en establecimientos especializados",
        },
        {
          value:
            "4772 - Comercio al por menor de todo tipo de calzado y artículos de cuero y sucedáneos del cuero en establecimientos especializados",
          text: "4772 - Comercio al por menor de todo tipo de calzado y artículos de cuero y sucedáneos del cuero en establecimientos especializados",
        },
        {
          value:
            "4773 - Comercio al por menor de productos farmacéuticos y medicinales, cosméticos y artículos de tocador en establecimientos especializados",
          text: "4773 - Comercio al por menor de productos farmacéuticos y medicinales, cosméticos y artículos de tocador en establecimientos especializados",
        },
        {
          value:
            "4774 - Comercio al por menor de otros productos nuevos en establecimientos especializados",
          text: "4774 - Comercio al por menor de otros productos nuevos en establecimientos especializados",
        },
        {
          value: "4775 - Comercio al por menor de artículos de segunda mano",
          text: "4775 - Comercio al por menor de artículos de segunda mano",
        },
        {
          value:
            "4781 - Comercio al por menor de alimentos, bebidas y tabaco, en puestos de venta móviles",
          text: "4781 - Comercio al por menor de alimentos, bebidas y tabaco, en puestos de venta móviles",
        },
        {
          value:
            "4782 - Comercio al por menor de productos textiles, prendas de vestir y calzado, en puestos de venta móviles",
          text: "4782 - Comercio al por menor de productos textiles, prendas de vestir y calzado, en puestos de venta móviles",
        },
        {
          value:
            "4789 - Comercio al por menor de otros productos en puestos de venta móviles",
          text: "4789 - Comercio al por menor de otros productos en puestos de venta móviles",
        },
        {
          value: "4791 - Comercio al por menor realizado a través de internet",
          text: "4791 - Comercio al por menor realizado a través de internet",
        },
        {
          value:
            "4792 - Comercio al por menor realizado a través de casas de venta o por correo",
          text: "4792 - Comercio al por menor realizado a través de casas de venta o por correo",
        },
        {
          value:
            "4799 - Otros tipos de comercio al por menor no realizado en establecimientos, puestos de venta o mercados",
          text: "4799 - Otros tipos de comercio al por menor no realizado en establecimientos, puestos de venta o mercados",
        },
        {
          value: "4911 - Transporte férreo de pasajeros",
          text: "4911 - Transporte férreo de pasajeros",
        },
        {
          value: "4912 - Transporte férreo de carga",
          text: "4912 - Transporte férreo de carga",
        },
        {
          value: "4921 - Transporte de pasajeros",
          text: "4921 - Transporte de pasajeros",
        },
        {
          value: "4922 - Transporte mixto",
          text: "4922 - Transporte mixto",
        },
        {
          value: "4923 - Transporte de carga por carretera",
          text: "4923 - Transporte de carga por carretera",
        },
        {
          value: "4930 - Transporte por tuberías",
          text: "4930 - Transporte por tuberías",
        },
        {
          value: "5011 - Transporte de pasajeros marítimo y de cabotaje",
          text: "5011 - Transporte de pasajeros marítimo y de cabotaje",
        },
        {
          value: "5012 - Transporte de carga marítimo y de cabotaje",
          text: "5012 - Transporte de carga marítimo y de cabotaje",
        },
        {
          value: "5021 - Transporte fluvial de pasajeros",
          text: "5021 - Transporte fluvial de pasajeros",
        },
        {
          value: "5022 - Transporte fluvial de carga",
          text: "5022 - Transporte fluvial de carga",
        },
        {
          value: "5111 - Transporte aéreo nacional de pasajeros",
          text: "5111 - Transporte aéreo nacional de pasajeros",
        },
        {
          value: "5112 - Transporte aéreo internacional de pasajeros",
          text: "5112 - Transporte aéreo internacional de pasajeros",
        },
        {
          value: "5121 - Transporte aéreo nacional de carga",
          text: "5121 - Transporte aéreo nacional de carga",
        },
        {
          value: "5122 - Transporte aéreo internacional de carga",
          text: "5122 - Transporte aéreo internacional de carga",
        },
        {
          value: "5210 - Almacenamiento y depósito",
          text: "5210 - Almacenamiento y depósito",
        },
        {
          value:
            "5221 - Actividades de estaciones, vías y servicios complementarios para el transporte terrestre",
          text: "5221 - Actividades de estaciones, vías y servicios complementarios para el transporte terrestre",
        },
        {
          value:
            "5222 - Actividades de puertos y servicios complementarios para el transporte acuático",
          text: "5222 - Actividades de puertos y servicios complementarios para el transporte acuático",
        },
        {
          value:
            "5223 - Actividades de aeropuertos, servicios de navegación aérea y demás actividades conexas al transporte aéreo",
          text: "5223 - Actividades de aeropuertos, servicios de navegación aérea y demás actividades conexas al transporte aéreo",
        },
        {
          value: "5224 - Manipulación de carga",
          text: "5224 - Manipulación de carga",
        },
        {
          value: "5229 - Otras actividades complementarias al transporte",
          text: "5229 - Otras actividades complementarias al transporte",
        },
        {
          value: "5310 - Actividades postales nacionales",
          text: "5310 - Actividades postales nacionales",
        },
        {
          value: "5320 - Actividades de mensajería",
          text: "5320 - Actividades de mensajería",
        },
        {
          value: "5511 - Alojamiento en hoteles",
          text: "5511 - Alojamiento en hoteles",
        },
        {
          value: "5512 - Alojamiento en apartahoteles",
          text: "5512 - Alojamiento en apartahoteles",
        },
        {
          value: "5513 - Alojamiento en centros vacacionales",
          text: "5513 - Alojamiento en centros vacacionales",
        },
        { value: "5514 - Alojamiento rural", text: "5514 - Alojamiento rural" },
        {
          value: "5519 - Otros tipos de alojamiento para visitantes",
          text: "5519 - Otros tipos de alojamiento para visitantes",
        },
        {
          value:
            "5520 - Actividades de zonas de camping y parques para vehículos recreacionales",
          text: "5520 - Actividades de zonas de camping y parques para vehículos recreacionales",
        },
        {
          value: "5530 - Servicio de estancia por horas",
          text: "5530 - Servicio de estancia por horas",
        },
        {
          value: "5590 - Otros tipos de alojamiento n.c.p.",
          text: "5590 - Otros tipos de alojamiento n.c.p.",
        },
        {
          value: "5611 - Expendio a la mesa de comidas preparadas",
          text: "5611 - Expendio a la mesa de comidas preparadas",
        },
        {
          value: "5612 - Expendio por autoservicio de comidas preparadas",
          text: "5612 - Expendio por autoservicio de comidas preparadas",
        },
        {
          value: "5613 - Expendio de comidas preparadas en cafeterías",
          text: "5613 - Expendio de comidas preparadas en cafeterías",
        },
        {
          value: "5619 - Otros tipos de expendio de comidas preparadas n.c.p.",
          text: "5619 - Otros tipos de expendio de comidas preparadas n.c.p.",
        },
        {
          value: "5621 - Catering para eventos",
          text: "5621 - Catering para eventos",
        },
        {
          value: "5629 - Actividades de otros servicios de comidas",
          text: "5629 - Actividades de otros servicios de comidas",
        },
        {
          value:
            "5630 - Expendio de bebidas alcohólicas para el consumo dentro del establecimiento",
          text: "5630 - Expendio de bebidas alcohólicas para el consumo dentro del establecimiento",
        },
        { value: "5811 - Edición de libros", text: "5811 - Edición de libros" },
        {
          value: "5812 - Edición de directorios y listas de correo",
          text: "5812 - Edición de directorios y listas de correo",
        },
        {
          value:
            "5813 - Edición de periódicos, revistas y otras publicaciones periódicas",
          text: "5813 - Edición de periódicos, revistas y otras publicaciones periódicas",
        },
        {
          value: "5819 - Otros trabajos de edición",
          text: "5819 - Otros trabajos de edición",
        },
        {
          value: "5820 - Edición de programas de informática (software)",
          text: "5820 - Edición de programas de informática (software)",
        },
        {
          value:
            "5911 - Actividades de producción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión",
          text: "5911 - Actividades de producción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión",
        },
        {
          value:
            "5912 - Actividades de postproducción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión",
          text: "5912 - Actividades de postproducción de películas cinematográficas, videos, programas, anuncios y comerciales de televisión",
        },
        {
          value:
            "5913 - Actividades de distribución de películas cinematográficas, videos, programas, anuncios y comerciales de televisión",
          text: "5913 - Actividades de distribución de películas cinematográficas, videos, programas, anuncios y comerciales de televisión",
        },
        {
          value:
            "5914 - Actividades de exhibición de películas cinematográficas y videos",
          text: "5914 - Actividades de exhibición de películas cinematográficas y videos",
        },
        {
          value:
            "5920 - Actividades de grabación de sonido y edición de música",
          text: "5920 - Actividades de grabación de sonido y edición de música",
        },
        {
          value:
            "6010 - Actividades de programación y transmisión en el servicio de radiodifusión sonora",
          text: "6010 - Actividades de programación y transmisión en el servicio de radiodifusión sonora",
        },
        {
          value:
            "6020 - Actividades de programación y transmisión de televisión",
          text: "6020 - Actividades de programación y transmisión de televisión",
        },
        {
          value: "6110 - Actividades de telecomunicaciones alámbricas",
          text: "6110 - Actividades de telecomunicaciones alámbricas",
        },
        {
          value: "6120 - Actividades de telecomunicaciones inalámbricas",
          text: "6120 - Actividades de telecomunicaciones inalámbricas",
        },
        {
          value: "6130 - Actividades de telecomunicación satelital",
          text: "6130 - Actividades de telecomunicación satelital",
        },
        {
          value: "6190 - Otras actividades de telecomunicaciones",
          text: "6190 - Otras actividades de telecomunicaciones",
        },
        {
          value:
            "6201 - Actividades de desarrollo de sistemas informáticos (planificación, análisis, diseño, programación, pruebas)",
          text: "6201 - Actividades de desarrollo de sistemas informáticos (planificación, análisis, diseño, programación, pruebas)",
        },
        {
          value:
            "6202 - Actividades de consultoría informática y actividades de administración de instalaciones informáticas",
          text: "6202 - Actividades de consultoría informática y actividades de administración de instalaciones informáticas",
        },
        {
          value:
            "6209 - Otras actividades de tecnologías de información y actividades de servicios informáticos",
          text: "6209 - Otras actividades de tecnologías de información y actividades de servicios informáticos",
        },
        {
          value:
            "6311 - Procesamiento de datos, alojamiento (hosting) y actividades relacionadas",
          text: "6311 - Procesamiento de datos, alojamiento (hosting) y actividades relacionadas",
        },
        { value: "6312 - Portales web", text: "6312 - Portales web" },
        {
          value: "6391 - Actividades de agencias de noticias",
          text: "6391 - Actividades de agencias de noticias",
        },
        {
          value: "6399 - Otras actividades de servicios de información n.c.p.",
          text: "6399 - Otras actividades de servicios de información n.c.p.",
        },
        { value: "6411 - Banco Central", text: "6411 - Banco Central" },
        {
          value: "6412 - Bancos comerciales",
          text: "6412 - Bancos comerciales",
        },
        {
          value: "6421 - Actividades de las corporaciones financieras",
          text: "6421 - Actividades de las corporaciones financieras",
        },
        {
          value: "6422 - Actividades de las compañías de financiamiento",
          text: "6422 - Actividades de las compañías de financiamiento",
        },
        {
          value: "6423 - Banca de segundo piso",
          text: "6423 - Banca de segundo piso",
        },
        {
          value: "6424 - Actividades de las cooperativas financieras",
          text: "6424 - Actividades de las cooperativas financieras",
        },
        {
          value:
            "6431 - Fideicomisos, fondos y entidades financieras similares",
          text: "6431 - Fideicomisos, fondos y entidades financieras similares",
        },
        {
          value: "6432 - Fondos de cesantías",
          text: "6432 - Fondos de cesantías",
        },
        {
          value: "6491 - Leasing financiero (arrendamiento financiero)",
          text: "6491 - Leasing financiero (arrendamiento financiero)",
        },
        {
          value:
            "6492 - Actividades financieras de fondos de empleados y otras formas asociativas del sector solidario",
          text: "6492 - Actividades financieras de fondos de empleados y otras formas asociativas del sector solidario",
        },
        {
          value: "6493 - Actividades de compra de cartera o factoring",
          text: "6493 - Actividades de compra de cartera o factoring",
        },
        {
          value: "6494 - Otras actividades de distribución de fondos",
          text: "6494 - Otras actividades de distribución de fondos",
        },
        {
          value: "6495 - Instituciones especiales oficiales",
          text: "6495 - Instituciones especiales oficiales",
        },
        { value: "6496 - Capitalización", text: "6496 - Capitalización" },
        {
          value:
            "6499 - Otras actividades de servicio financiero, excepto las de seguros y pensiones n.c.p.",
          text: "6499 - Otras actividades de servicio financiero, excepto las de seguros y pensiones n.c.p.",
        },
        { value: "6511 - Seguros generales", text: "6511 - Seguros generales" },
        { value: "6512 - Seguros de vida", text: "6512 - Seguros de vida" },
        { value: "6513 - Reaseguros", text: "6513 - Reaseguros" },
        {
          value: "6515 - Seguros de salud",
          text: "6515 - Seguros de salud",
        },
        {
          value: "6521 - Servicios de seguros sociales de salud",
          text: "6521 - Servicios de seguros sociales de salud",
        },
        {
          value: "6522 - Servicios de seguros sociales en riesgos laborales",
          text: "6522 - Servicios de seguros sociales en riesgos laborales",
        },
        {
          value: "6523 - Servicios de seguros sociales en riesgos familia",
          text: "6523 - Servicios de seguros sociales en riesgos familia",
        },
        {
          value: "6531 - Régimen de prima media con prestación definida (RPM)",
          text: "6531 - Régimen de prima media con prestación definida (RPM)",
        },
        {
          value: "6532 - Régimen de ahorro individual con solidaridad (RAIS)",
          text: "6532 - Régimen de ahorro individual con solidaridad (RAIS)",
        },
        {
          value: "6611 - Administración de mercados financieros",
          text: "6611 - Administración de mercados financieros",
        },
        {
          value:
            "6612 - Corretaje de valores y de contratos de productos básicos",
          text: "6612 - Corretaje de valores y de contratos de productos básicos",
        },
        {
          value:
            "6613 - Otras actividades relacionadas con el mercado de valores",
          text: "6613 - Otras actividades relacionadas con el mercado de valores",
        },
        {
          value:
            "6614 - Actividades de las sociedades de intermediación cambiaria y de servicios financieros especiales",
          text: "6614 - Actividades de las sociedades de intermediación cambiaria y de servicios financieros especiales",
        },
        {
          value:
            "6615 - Actividades de los profesionales de compra y venta de divisas",
          text: "6615 - Actividades de los profesionales de compra y venta de divisas",
        },
        {
          value:
            "6619 - Otras actividades auxiliares de las actividades de servicios financieros n.c.p.",
          text: "6619 - Otras actividades auxiliares de las actividades de servicios financieros n.c.p.",
        },
        {
          value: "6621 - Actividades de agentes y corredores de seguros",
          text: "6621 - Actividades de agentes y corredores de seguros",
        },
        {
          value:
            "6629 - Evaluación de riesgos y daños, y otras actividades de servicios auxiliares",
          text: "6629 - Evaluación de riesgos y daños, y otras actividades de servicios auxiliares",
        },
        {
          value: "6630 - Actividades de administración de fondos",
          text: "6630 - Actividades de administración de fondos",
        },
        {
          value:
            "6810 - Actividades inmobiliarias realizadas con bienes propios o arrendados",
          text: "6810 - Actividades inmobiliarias realizadas con bienes propios o arrendados",
        },
        {
          value:
            "6820 - Actividades inmobiliarias realizadas a cambio de una retribución o por contrata",
          text: "6820 - Actividades inmobiliarias realizadas a cambio de una retribución o por contrata",
        },
        {
          value: "6910 - Actividades jurídicas",
          text: "6910 - Actividades jurídicas",
        },
        {
          value: "6920",
          text: "6920 - Actividades de contabilidad, teneduría de libros, auditoría financiera y asesoría tributaria",
        },
        {
          value: "7010",
          text: "7010 - Actividades de administración empresarial",
        },
        {
          value: "7020",
          text: "7020 - Actividades de consultoría de gestión",
        },
        {
          value: "7111",
          text: "7111 - Actividades de arquitectura",
        },
        {
          value: "7112",
          text: "7112 - Actividades de ingeniería y otras actividades conexas de consultoría técnica",
        },
        {
          value: "7120",
          text: "7120 - Ensayos y análisis técnicos",
        },
        {
          value: "7210",
          text: "7210 - Investigaciones y desarrollo experimental en el campo de las ciencias naturales y la ingeniería",
        },
        {
          value: "7220",
          text: "7220 - Investigaciones y desarrollo experimental en el campo de las ciencias sociales y las humanidades",
        },
        {
          value: "7310",
          text: "7310 - Publicidad",
        },
        {
          value: "7320",
          text: "7320 - Estudios de mercado y realización de encuestas de opinión pública",
        },
        {
          value: "7410",
          text: "7410 - Actividades especializadas de diseño",
        },
        {
          value: "7420",
          text: "7420 - Actividades de fotografía",
        },
        {
          value: "7490",
          text: "7490 - Otras actividades profesionales, científicas y técnicas n.c.p.",
        },
        {
          value: "7500",
          text: "7500 - Actividades veterinarias",
        },
        {
          value: "7710",
          text: "7710 - Alquiler y arrendamiento de vehículos automotores",
        },
        {
          value: "7721",
          text: "7721 - Alquiler y arrendamiento de equipo recreativo y deportivo",
        },
        {
          value: "7722",
          text: "7722 - Alquiler de videos y discos",
        },
        {
          value:
            "7729 - Alquiler y arrendamiento de otros efectos personales y enseres domésticos n.c.p.",
          text: "7729 - Alquiler y arrendamiento de otros efectos personales y enseres domésticos n.c.p.",
        },
        {
          value:
            "7730 - Alquiler y arrendamiento de otros tipos de maquinaria, equipo y bienes tangibles n.c.p.",
          text: "7730 - Alquiler y arrendamiento de otros tipos de maquinaria, equipo y bienes tangibles n.c.p.",
        },
        {
          value:
            "7740 - Arrendamiento de propiedad intelectual y productos similares, excepto obras protegidas por derechos de autor",
          text: "7740 - Arrendamiento de propiedad intelectual y productos similares, excepto obras protegidas por derechos de autor",
        },
        {
          value:
            "7810 - Actividades de agencias de gestión y colocación de empleo",
          text: "7810 - Actividades de agencias de gestión y colocación de empleo",
        },
        {
          value: "7820 - Actividades de empresas de servicios temporales",
          text: "7820 - Actividades de empresas de servicios temporales",
        },
        {
          value: "7830 - Otras actividades de provisión de talento humano",
          text: "7830 - Otras actividades de provisión de talento humano",
        },
        {
          value: "7911 - Actividades de las agencias de viaje",
          text: "7911 - Actividades de las agencias de viaje",
        },
        {
          value: "7912 - Actividades de operadores turísticos",
          text: "7912 - Actividades de operadores turísticos",
        },
        {
          value: "7990 - Otros servicios de reserva y actividades relacionadas",
          text: "7990 - Otros servicios de reserva y actividades relacionadas",
        },
        {
          value: "8010 - Actividades de seguridad privada",
          text: "8010 - Actividades de seguridad privada",
        },
        {
          value: "8020 - Actividades de servicios de sistemas de seguridad",
          text: "8020 - Actividades de servicios de sistemas de seguridad",
        },
        {
          value: "8030 - Actividades de detectives e investigadores privados",
          text: "8030 - Actividades de detectives e investigadores privados",
        },
        {
          value: "8110 - Actividades combinadas de apoyo a instalaciones",
          text: "8110 - Actividades combinadas de apoyo a instalaciones",
        },
        {
          value: "8121 - Limpieza general interior de edificios",
          text: "8121 - Limpieza general interior de edificios",
        },
        {
          value:
            "8129 - Otras actividades de limpieza de edificios e instalaciones industriales",
          text: "8129 - Otras actividades de limpieza de edificios e instalaciones industriales",
        },
        {
          value:
            "8130 - Actividades de paisajismo y servicios de mantenimiento conexos",
          text: "8130 - Actividades de paisajismo y servicios de mantenimiento conexos",
        },
        {
          value:
            "8211 - Actividades combinadas de servicios administrativos de oficina",
          text: "8211 - Actividades combinadas de servicios administrativos de oficina",
        },
        {
          value:
            "8219 - Fotocopiado, preparación de documentos y otras actividades especializadas de apoyo a oficina",
          text: "8219 - Fotocopiado, preparación de documentos y otras actividades especializadas de apoyo a oficina",
        },
        {
          value: "8220 - Actividades de centros de llamadas (call center)",
          text: "8220 - Actividades de centros de llamadas (call center)",
        },
        {
          value: "8230 - Organización de convenciones y eventos comerciales",
          text: "8230 - Organización de convenciones y eventos comerciales",
        },
        {
          value:
            "8291 - Actividades de agencias de cobranza y oficinas de calificación crediticia",
          text: "8291 - Actividades de agencias de cobranza y oficinas de calificación crediticia",
        },
        {
          value: "8292 - Actividades de envase y empaque",
          text: "8292 - Actividades de envase y empaque",
        },
        {
          value:
            "8299 - Otras actividades de servicio de apoyo a las empresas n.c.p.",
          text: "8299 - Otras actividades de servicio de apoyo a las empresas n.c.p.",
        },
        {
          value: "8411 - Actividades legislativas de la administración pública",
          text: "8411 - Actividades legislativas de la administración pública",
        },
        {
          value: "8412 - Actividades ejecutivas de la administración pública",
          text: "8412 - Actividades ejecutivas de la administración pública",
        },
        {
          value:
            "8413 - Regulación de las actividades de organismos que prestan servicios de salud, educativos, culturales y otros servicios sociales, excepto servicios de seguridad social",
          text: "8413 - Regulación de las actividades de organismos que prestan servicios de salud, educativos, culturales y otros servicios sociales, excepto servicios de seguridad social",
        },
        {
          value:
            "8414 - Actividades reguladoras y facilitadoras de la actividad económica",
          text: "8414 - Actividades reguladoras y facilitadoras de la actividad económica",
        },
        {
          value:
            "8415 - Actividades de los órganos de control y otras instituciones",
          text: "8415 - Actividades de los órganos de control y otras instituciones",
        },
        { value: "8421 - Relaciones exteriores", text: "8421 - Relaciones exteriores" },
        { value: "8422 - Actividades de defensa", text: "8422 - Actividades de defensa" },
        { value: "8423 - Orden público y actividades de seguridad", text: "8423 - Orden público y actividades de seguridad" },
        { value: "8424 - Administración de justicia", text: "8424 - Administración de justicia" },
        {
          value: "8430 - Actividades de planes de seguridad social de afiliación obligatoria",
          text: "8430 - Actividades de planes de seguridad social de afiliación obligatoria",
        },
        { value: "8511 - Educación de la primera infancia", text: "8511 - Educación de la primera infancia" },
        { value: "8512 - Educación preescolar", text: "8512 - Educación preescolar" },
        { value: "8513 - Educación básica primaria", text: "8513 - Educación básica primaria" },
        { value: "8521 - Educación básica secundaria", text: "8521 - Educación básica secundaria" },
        { value: "8522 - Educación media académica", text: "8522 - Educación media académica" },
        { value: "8523 - Educación media técnica", text: "8523 - Educación media técnica" },
        {
          value: "8530 - Establecimientos que combinan diferentes niveles de educación",
          text: "8530 - Establecimientos que combinan diferentes niveles de educación",
        },
        { value: "8541 - Educación técnica profesional", text: "8541 - Educación técnica profesional" },
        {
          value: "8542 - Educación tecnológica",
          text: "8542 - Educación tecnológica",
        },
        {
          value:
            "8543 - Educación de instituciones universitarias o de escuelas tecnológicas",
          text: "8543 - Educación de instituciones universitarias o de escuelas tecnológicas",
        },
        {
          value: "8544 - Educación de universidades",
          text: "8544 - Educación de universidades",
        },
        {
          value: "8551 - Formación para el trabajo",
          text: "8551 - Formación para el trabajo",
        },
        {
          value: "8552 - Enseñanza deportiva y recreativa",
          text: "8552 - Enseñanza deportiva y recreativa",
        },
        {
          value: "8553 - Enseñanza cultural",
          text: "8553 - Enseñanza cultural",
        },
        {
          value: "8559 - Otros tipos de educación n.c.p.",
          text: "8559 - Otros tipos de educación n.c.p.",
        },
        {
          value: "8560 - Actividades de apoyo a la educación",
          text: "8560 - Actividades de apoyo a la educación",
        },
        {
          value: "8610 - Actividades de hospitales y clínicas, con internación",
          text: "8610 - Actividades de hospitales y clínicas, con internación",
        },
        {
          value: "8621 - Actividades de la práctica médica, sin internación",
          text: "8621 - Actividades de la práctica médica, sin internación",
        },
        {
          value: "8622 - Actividades de la práctica odontológica",
          text: "8622 - Actividades de la práctica odontológica",
        },
        {
          value: "8691 - Actividades de apoyo diagnóstico",
          text: "8691 - Actividades de apoyo diagnóstico",
        },
        { value: "8692 - Actividades de apoyo terapéutico", text: "8692 - Actividades de apoyo terapéutico" },
        {
          value: "8699 - Otras actividades de atención de la salud humana",
          text: "8699 - Otras actividades de atención de la salud humana",
        },
        {
          value: "8710 - Actividades de atención residencial medicalizada de tipo general",
          text: "8710 - Actividades de atención residencial medicalizada de tipo general",
        },
        {
          value: "8720 - Actividades de atención residencial, para el cuidado de pacientes con retardo mental, enfermedad mental y consumo de sustancias psicoactivas",
          text: "8720 - Actividades de atención residencial, para el cuidado de pacientes con retardo mental, enfermedad mental y consumo de sustancias psicoactivas",
        },
        {
          value: "8730 - Actividades de atención en instituciones para el cuidado de personas mayores y/o discapacitadas",
          text: "8730 - Actividades de atención en instituciones para el cuidado de personas mayores y/o discapacitadas",
        },
        {
          value: "8790 - Otras actividades de atención en instituciones con alojamiento",
          text: "8790 - Otras actividades de atención en instituciones con alojamiento",
        },
        {
          value: "8810 - Actividades de asistencia social sin alojamiento para personas mayores y discapacitadas",
          text: "8810 - Actividades de asistencia social sin alojamiento para personas mayores y discapacitadas",
        },
        {
          value: "8891 - Actividades de guarderías para niños y niñas",
          text: "8891 - Actividades de guarderías para niños y niñas",
        },
        {
          value: "8899 - Otras actividades de asistencia social sin alojamiento n.c.p.",
          text: "8899 - Otras actividades de asistencia social sin alojamiento n.c.p.",
        },
        { value: "9001 - Creación literaria", text: "9001 - Creación literaria" },
        { value: "9002 - Creación musical", text: "9002 - Creación musical" },
        { value: "9003 - Creación teatral", text: "9003 - Creación teatral" },
        { value: "9004 - Creación audiovisual", text: "9004 - Creación audiovisual" },
        { value: "9005 - Artes plásticas y visuales", text: "9005 - Artes plásticas y visuales" },
        { value: "9006 - Actividades teatrales", text: "9006 - Actividades teatrales" },
        {
          value: "9007 - Actividades de espectáculos musicales en vivo",
          text: "9007 - Actividades de espectáculos musicales en vivo",
        },
        {
          value: "9008 - Otras actividades de espectáculos en vivo n.c.p.",
          text: "9008 - Otras actividades de espectáculos en vivo n.c.p.",
        },
        { value: "9101 - Actividades de bibliotecas y archivos", text: "9101 - Actividades de bibliotecas y archivos" },
        {
          value: "9102 - Actividades y funcionamiento de museos, conservación de edificios y sitios históricos",
          text: "9102 - Actividades y funcionamiento de museos, conservación de edificios y sitios históricos",
        },
        {
          value: "9103 - Actividades de jardines botánicos, zoológicos y reservas naturales",
          text: "9103 - Actividades de jardines botánicos, zoológicos y reservas naturales",
        },
        { value: "9200 - Actividades de juegos de azar y apuestas", text: "9200 - Actividades de juegos de azar y apuestas" },
        { value: "9311 - Gestión de instalaciones deportivas", text: "9311 - Gestión de instalaciones deportivas" },
        { value: "9312 - Actividades de clubes deportivos", text: "9312 - Actividades de clubes deportivos" },
        { value: "9319 - Otras actividades deportivas", text: "9319 - Otras actividades deportivas" },
        {
          value: "9321 - Actividades de parques de atracciones y parques temáticos",
          text: "9321 - Actividades de parques de atracciones y parques temáticos",
        },
        {
          value: "9329 - Otras actividades recreativas y de esparcimiento n.c.p.",
          text: "9329 - Otras actividades recreativas y de esparcimiento n.c.p.",
        },
        {
          value: "9411 - Actividades de asociaciones empresariales y de empleadores",
          text: "9411 - Actividades de asociaciones empresariales y de empleadores",
        },
        {
          value: "9412 - Actividades de asociaciones profesionales",
          text: "9412 - Actividades de asociaciones profesionales",
        },
        {
          value: "9420 - Actividades de sindicatos de empleados",
          text: "9420 - Actividades de sindicatos de empleados",
        },
        {
          value: "9491 - Actividades de asociaciones religiosas",
          text: "9491 - Actividades de asociaciones religiosas",
        },
        { value: "9492 - Actividades de asociaciones políticas", text: "9492 - Actividades de asociaciones políticas" },
        {
          value: "9499 - Actividades de otras asociaciones n.c.p.",
          text: "9499 - Actividades de otras asociaciones n.c.p.",
        },
        {
          value: "9511 - Mantenimiento y reparación de computadores y de equipo periférico",
          text: "9511 - Mantenimiento y reparación de computadores y de equipo periférico",
        },
        {
          value: "9512 - Mantenimiento y reparación de equipos de comunicación",
          text: "9512 - Mantenimiento y reparación de equipos de comunicación",
        },
        {
          value:
            "9521 - Mantenimiento y reparación de aparatos electrónicos de consumo",
          text: "9521 - Mantenimiento y reparación de aparatos electrónicos de consumo",
        },
        {
          value:
            "9522 - Mantenimiento y reparación de aparatos y equipos domésticos y de jardinería",
          text: "9522 - Mantenimiento y reparación de aparatos y equipos domésticos y de jardinería",
        },
        {
          value: "9523 - Reparación de calzado y artículos de cuero",
          text: "9523 - Reparación de calzado y artículos de cuero",
        },
        {
          value: "9524 - Reparación de muebles y accesorios para el hogar",
          text: "9524 - Reparación de muebles y accesorios para el hogar",
        },
        {
          value:
            "9529 - Mantenimiento y reparación de otros efectos personales y enseres domésticos",
          text: "9529 - Mantenimiento y reparación de otros efectos personales y enseres domésticos",
        },
        {
          value:
            "9601 - Lavado y limpieza, incluso la limpieza en seco, de productos textiles y de piel",
          text: "9601 - Lavado y limpieza, incluso la limpieza en seco, de productos textiles y de piel",
        },
        {
          value: "9602 - Peluquería y otros tratamientos de belleza",
          text: "9602 - Peluquería y otros tratamientos de belleza",
        },
        {
          value: "9603 - Pompas fúnebres y actividades relacionadas",
          text: "9603 - Pompas fúnebres y actividades relacionadas",
        },
        {
          value: "9609 - Otras actividades de servicios personales n.c.p.",
          text: "9609 - Otras actividades de servicios personales n.c.p.",
        },
        {
          value:
            "9700 - Actividades de los hogares individuales como empleadores de personal doméstico",
          text: "9700 - Actividades de los hogares individuales como empleadores de personal doméstico",
        },
        {
          value:
            "9810 - Actividades no diferenciadas de los hogares individuales como productores de bienes para uso propio",
          text: "9810 - Actividades no diferenciadas de los hogares individuales como productores de bienes para uso propio",
        },
        {
          value:
            "9820 - Actividades no diferenciadas de los hogares individuales como productores de servicios para uso propio",
          text: "9820 - Actividades no diferenciadas de los hogares individuales como productores de servicios para uso propio",
        },
        {
          value:
            "9900 - Actividades de organizaciones y entidades extraterritoriales",
          text: "9900 - Actividades de organizaciones y entidades extraterritoriales",
        },
      ],
    };
  },
  watch: {
    "form.observacionradicado": function (newVal) {
      // Reemplaza el carácter "&" por una cadena vacía
      this.form.observacionradicado = newVal
        .replace(/&/g, "")
        .replace(/'/g, "");
    },
  },
  methods: {
    enviar() {
      this.arryaJson = [];
      this.nombrefiles = []; // nueva-----------------------------------------------------------

      if (this.form.selectedtipotram == 257) {
        this.form.tipotramite = "8";
      } else if (this.form.selectedtipotram == 212) {
        this.form.tipotramite = "69";
      } else if (this.form.selectedtipotram == 210) {
        this.form.tipotramite = "68";
      }

      this.form.desciudad = this.ciudadcompleta.text; //CAMBIO
      this.form.ciudad = this.ciudadcompleta.value; //CAMBIO
      this.form.desciudadapoderado = this.ciudadapoderado.text; //CAMBIO
      this.form.ciudadapoderado = this.ciudadapoderado.value; //CAMBIO

      if (this.form.selectedtipoident === "25") {
        this.form.selectedtipoident = "26";
        console.log("this.form.selectedtipoident", this.form.selectedtipoident);
      }

      if (this.form.selectedtipoidentapo === "25") {
        this.form.selectedtipoidentapo = "26";
        console.log(
          "this.form.selectedtipoidentapo",
          this.form.selectedtipoidentapo
        );
      }

      this.pesofile1 = 0; // nueva----------------------------------------------------------------
      if (this.file1.length > 0) {
        this.file1.forEach((file) => {
          this.nombrefiles.push(file.name);
        });
      }
      this.form.filesname = this.nombrefiles.toString();

      const axiosInstance = axios.create({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      if (
        this.validacioncampos(this.form) &&
        this.validacioncamposapo(this.form)
      ) {
        this.form2 = Object.assign({}, this.form, this.form1);

        this.arryaJson.push(this.form, this.form1, this.form2);

        if (Array.isArray(this.arryaJson)) {
          // Recorre el arryaJson y aplica la función a todas las propiedades de todos los objetos en la matriz
          this.arryaJson.forEach((obj) => {
            for (const prop in obj) {
              if (typeof obj[prop] === "string") {
                obj[prop] = this.replaceSpecialCharacters(obj[prop]);
              }
            }
          });
        } else {
          console.error("arryaJson no es una matriz o está indefinida");
        }

        this.loading = true;
        this.show = false;
        for (var i = 0; i < this.file1.length; i++) {
          this.pesofile1 += this.file1[i].size;
        }

        // VALIDO SI CARGARON ARCHIVOS ADJUNTOS ----------------->
        if (this.file1.length > 0) {
          if (this.validarpesofile(this.file1)) {
            //console.log("valida archivos", this.validarpesofile(this.file1));
            // VALIDO SI TODOS LOS ARCHIVOS ADJUNTOS SUPERAN LOS 50 MB ----------------->
            console.log("this.pesofile1 3676", this.pesofile1);
            if (this.pesofile1 > 50000000) {
              swal(
                "El peso total de los archivos adjuntos supera los 50 Mb permitidos"
              );
              this.loading = false;
              this.show = true;
              //console.log('tamaño de los archivos -------- ',this.pesofile1)
              this.pesofile1 = 0;
            }  else if (this.pesofile1 < 1024) {
              swal(
                "El peso de uno o más archivos adjuntos es menor a 1 KB, lo cual no es permitido."
              );
              this.loading = false;
              this.show = true;
              this.pesofile1 = 0;
              console.log('tamaño de los archivos -------- ',this.pesofile1)
            } else {
              // SE ENVIA LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
              console.log("linea _____________ 3476");
              axiosInstance
                .post(rutaenviosolicitud, this.arryaJson)
                .then((response) => {
                  let blob = this.b64toBlob(response.data.pdf);

                  this.file1.push(blob);

                  console.log("adjuntos frontend ", this.file1.length);

                  if (this.file1.length > 0) {
                    this.contadorarchivos = this.file1.length;
                    this.filesValide = []; /////////////7nuevo

                    //console.log("cantidad de archivos ", this.contadorarchivos);
                    this.file1.forEach((file) => {
                      /////////////7nuevo
                      this.filesValide.push({
                        name: file.name,
                        value: 0,
                        color: true,
                      });

                      const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                      const formData = new FormData();
                      var regex = /([^-\w.]*)/gm;
                      //console.log("nombre archivo", file.name);
                      var arcPDF2 = file.name.replace(regex, "");
                      arcPDF2 = arcPDF2.replaceAll(" ", "");
                      //console.log("nombre nuevo ", arcPDF2);

                      var blob = file.slice(0, file.size, file.type);
                      var newFile = new File([blob], arcPDF2, {
                        type: file.type,
                      });

                      formData.append("file", newFile);
                      formData.append("fileName", arcPDF2);
                      formData.append("radicado", response.data.radicado);
                      formData.append("usuario", response.data.usuario);
                      formData.append("ordenTrabajo", response.data.orden);
                      formData.append("idCorreo", response.data.correo);

                      // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                      axios
                        .post(rutafile, formData, {
                          /// envio archivos
                          headers: { "content-type": "multipart/form-data" },
                          maxRedirects: 0,
                        })
                        .then((respon) => {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          if (respon.status === 200) {
                            this.contadorresponse++;
                          } else {
                            ///////////77nuevo
                            this.filesValide[keyInd].color = false;
                          } ///////////77nuevo

                          this.resetForm();
                          //console.log("Respuesta envio adjuntos", respon);

                          if (this.contadorarchivos === this.contadorresponse) {
                            this.loading = false;
                            swal({
                              closeOnClickOutside: false,
                              text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                          }

                          this.show = true;
                        })
                        .catch(function (error) {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          this.filesValide[keyInd].color = false; ///////////77nuevo
                          if (
                            error.response.status === 500 ||
                            error.response.status === 404
                          ) {
                            swal({
                              closeOnClickOutside: false,
                              text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                            this.resetForm();
                            console.log("error 2022 ", error.response.status);
                          }
                          this.show = true;
                        });
                    });
                  } else {
                    this.resetForm();
                    swal({
                      closeOnClickOutside: false,
                      text: `...Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                    this.loading = false;
                    this.show = true;
                  }
                })
                .catch(function (error) {
                  if (error.response.status === 500) {
                    swal({
                      closeOnClickOutside: false,
                      text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                  }
                  this.loading = false;
                  this.show = true;
                });
            }
          } else {
            swal("El peso de uno o mas archivos supera los 10 Mb permitidos");
            this.loading = false;
            this.show = true;
          }
        } else {
          // CUANDO NO CARGAR ARCHIVOS SE ENVIA SOLO LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
          axiosInstance
            .post(rutaenviosolicitud, this.arryaJson)
            .then((response) => {
              let blob = this.b64toBlob(response.data.pdf);

              this.file1.push(blob);

              console.log("adjuntos pdf backend", this.file1.length);

              if (this.file1.length > 0) {
                this.contadorarchivos = this.file1.length;
                this.filesValide = []; /////////////7nuevo

                //console.log("cantidad de archivos ", this.contadorarchivos);
                this.file1.forEach((file) => {
                  /////////////7nuevo
                  this.filesValide.push({
                    name: file.name,
                    value: 0,
                    color: true,
                  });

                  const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                  const formData = new FormData();
                  var regex = /([^-\w.]*)/gm;
                  //console.log("nombre archivo", file.name);
                  var arcPDF2 = file.name.replace(regex, "");
                  arcPDF2 = arcPDF2.replaceAll(" ", "");
                  //console.log("nombre nuevo ", arcPDF2);

                  var blob = file.slice(0, file.size, file.type);
                  var newFile = new File([blob], arcPDF2, {
                    type: file.type,
                  });

                  formData.append("file", newFile);
                  formData.append("fileName", arcPDF2);
                  formData.append("radicado", response.data.radicado);
                  formData.append("usuario", response.data.usuario);
                  formData.append("ordenTrabajo", response.data.orden);
                  formData.append("idCorreo", response.data.correo);

                  // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                  axios
                    .post(rutafile, formData, {
                      /// envio archivos
                      headers: { "content-type": "multipart/form-data" },
                      maxRedirects: 0,
                    })
                    .then((respon) => {
                      this.filesValide[keyInd].value = 100; ///////////77nuevo
                      if (respon.status === 200) {
                        this.contadorresponse++;
                      } else {
                        ///////////77nuevo
                        this.filesValide[keyInd].color = false;
                      } ///////////77nuevo

                      this.resetForm();
                      //console.log("Respuesta envio adjuntos", respon);

                      if (this.contadorarchivos === this.contadorresponse) {
                        this.loading = false;
                        swal({
                          closeOnClickOutside: false,
                          text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                        }).then((value) => {
                          if (value) {
                            window.location.href = "https://cvc.gov.co/";
                          }
                        });
                      }

                      this.show = true;
                    })
                    .catch(function (error) {
                      this.filesValide[keyInd].value = 100; ///////////77nuevo
                      this.filesValide[keyInd].color = false; ///////////77nuevo
                      if (
                        error.response.status === 500 ||
                        error.response.status === 404
                      ) {
                        swal({
                          closeOnClickOutside: false,
                          text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                        }).then((value) => {
                          if (value) {
                            window.location.href = "https://cvc.gov.co/";
                          }
                        });
                        this.resetForm();
                        console.log("error 2022 ", error.response.status);
                      }
                      this.show = true;
                    });
                });
              }

              this.resetForm();
              swal({
                closeOnClickOutside: false,
                text: `.,.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
              }).then((value) => {
                if (value) {
                  window.location.href = "https://cvc.gov.co/";
                }
              });
              this.loading = false;
              this.show = true;
            })
            .catch(function (error) {
              if (error.response.status === 500) {
                swal({
                  closeOnClickOutside: false,
                  text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                }).then((value) => {
                  if (value) {
                    window.location.href = "https://cvc.gov.co/";
                  }
                });
              }
              this.loading = false;
              this.show = true;
            });
        }
      } else {
        console.log("fallo");
      }
    },
    validarpesofile(files) {
      var count = 0;
      //console.log(files.length);
      files.forEach((file) => {
        if (file.size > 10000000) {
          count++;
        }
      });
      //console.log("archivos con peso de mas de 10 mb", count);
      if (count == 0) {
        return true;
      } else {
        return false;
      }
    },
    b64toBlob(b64Data) {
      const names = "formulario.pdf";
      const newbase64 = "data:application/pdf;base64," + b64Data;

      let arr = newbase64.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = window.atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      let file_created = new File([u8arr], names, { type: mime });

      /*var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";

      var blob = new Blob([file_created], { type: "application/pdf" }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "fileName.pdf";
      //a.click();*/

      return file_created;
    },
    resetForm() {
      this.form1.firma = "";
      (this.form = {
        tipotramite: "",
        mediorecepcion: "4",
        observacionradicado: "",
        asunto: "",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram: "", //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        expe: "",
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: "",
        selectedrepre: "",
        selectedtipoperapo: "",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        nombrecentropoblado: "",
      }),
        (this.form1 = {
          actividadeco: "",
          CodigoCIIU: "",
          costototal: "",
          deparfuente: "",
          munifuente: "",
          direccionprediofuente: "",
          fuenteabastecimiento: "",
          grado1: "",
          grado2: "",
          grado3: "",
          minutos1: "",
          minutos2: "",
          minutos3: "",
          segundos1: "",
          segundos2: "",
          segundos3: "",
          grados1l: "",
          grados2l: "",
          grados3l: "",
          minutos1l: "",
          minutos2l: "",
          minutos3l: "",
          segundos1l: "",
          segundos2l: "",
          segundos3l: "",
          altitud1l: "",
          altitud2l: "",
          altitud3l: "",
          usosueloactual: "",
          usosuelopotencial: "",
          selectedtipoverti: "",
          selectedcaptacion: "",
          tiempodescargahora: "",
          tiempodescargadias: "",
          tiempodescargacuadal: "",
          selectedtipoflujo: "",
          areavertimiento: "",
          observa: "",
          firma: "",
          aceptacion: "",
        });
    },
    validarNumero() {
      this.form.identificacion = this.form.identificacion.replace(
        /[^0-9]/g,
        ""
      ); // Elimina cualquier carácter que no sea un número
      this.form.identificacionapode = this.form.identificacionapode.replace(
        /[^0-9]/g,
        ""
      );
    },
    validacioncampos() {
      if (this.form.selectedtipoper === "") {
        swal("Debe seleccionar el tipo de persona");
      } else if (
        this.form.selectedtipoper === "2" &&
        this.form.selectedtipoperjuridica === ""
      ) {
        swal("Debe seleccionar el tipo de persona juridica");
      } else if (this.form.selectedtipotram === "") {
        swal("Debe seleccionar el tipo de tramite");
      } else if (this.form.selectedtipoident === "") {
        swal("Debe seleccionar el tipo de identificación");
      } else if (
        (this.form.selectedtipoident === "26" ||
          this.form.selectedtipoident === "1") &&
        this.form.nombre === ""
      ) {
        swal("Debe ingresar un nombre o razón social");
      } else if (
        (this.form.selectedtipoident === "10" ||
          this.form.selectedtipoident === "36" ||
          this.form.selectedtipoident === "11") &&
        (this.form.nombre1 === "" || this.form.nombre3 === "")
      ) {
        swal("Debe ingresar un nombre y un apellido");
      } else if (this.form.identificacion === "") {
        swal("Debe ingresar la identificación");
      } else if (
        (this.form.selectedtipoident === "26" ||
          this.form.selectedtipoident === "1") &&
        this.form.digito === ""
      ) {
        swal("Debe ingresar el digito de verificación");
      } else if (this.form.dircorrespondencia === "") {
        swal("Debe ingresar la dirección de correspondencia");
      } else if (this.form.depart === "") {
        swal("Debe seleccionar el departamento de correspondencia");
      } else if (this.ciudadcompleta.value == undefined) {
        swal("Debe seleccionar la ciudad de correspondencia");
      } else if (this.form.selectedcorreo === "") {
        swal("Debe seleccionar si o no autoriza la notificación por correo");
      } else if (this.form.selectedcorreo === "1" && this.form.correo === "") {
        swal("Por favor ingresar un correo");
      } else if (
        this.form.selectedcorreo === "1" &&
        this.validEmail(this.form.correo) === false
      ) {
        swal("Por favor ingresar un correo valido");
      } else if (
        this.form.selectedcorreo === "2" &&
        this.form.correodirnoti === ""
      ) {
        swal("Por favor ingresar una dirección de notificación");
      } else if (this.form.direccionpredio === "") {
        swal(
          "Debe ingresar la dirección del predio para el cual esta haciendo la solicitud"
        );
      } else if (this.form.departpredio === "") {
        swal(
          "Debe seleccionar el departamento del predio para el cual esta haciendo la solicitud"
        );
      } else if (this.form.ciudadpredio === "") {
        swal(
          "Debe seleccionar el municipio del predio para el cual esta haciendo la solicitud"
        );
      } 

      else if (this.form1.actividadeco === "") {
        swal(
          "Debe diligenciar la Actividad económica"
        );
      } else if (this.form1.costototal === "") {
        swal(
          "Debe diligenciar el Costo total del proyecto"
        );
      } 
      
      else if (this.form1.aceptacion === "") {
        swal(
          "Debe aceptar el uso de mis datos personales, tratamiento, protección"
        );
      } else {
        return true;
      }
    },
    validacioncamposapo() {
      if (
        this.form.selectedtipoperapo !== "" &&
        this.form.selectedtipoidentapo === ""
      ) {
        swal("Debe seleccionar el tipo de identificación del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.selectedtipoperapo === "2" &&
        this.form.selectedtipoperjuridica === ""
      ) {
        swal("Debe seleccionar el tipo de persona juridica del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "26") ||
          this.form.selectedtipoidentapo === "1") &&
        this.form.nombreapoderado === ""
      ) {
        swal("Debe ingresar un nombre o razón social del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "10") ||
          this.form.selectedtipoidentapo === "36" ||
          this.form.selectedtipoidentapo === "11") &&
        (this.form.nombre1apo === "" || this.form.nombre3apo === "")
      ) {
        swal("Debe ingresar un nombre y un apellido del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.identificacionapode === ""
      ) {
        swal("Debe ingresar la identificación del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "26") ||
          this.form.selectedtipoidentapo === "1") &&
        this.form.digitoapo === ""
      ) {
        swal("Debe ingresar el digito de verificación del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.dirapoderado === ""
      ) {
        swal("Debe ingresar la dirección de correspondencia del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.departapoderado === ""
      ) {
        swal(
          "Debe seleccionar el departamento de correspondencia del representante"
        );
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.ciudadapoderado.value == undefined
      ) {
        swal("Debe seleccionar la ciudad de correspondencia del representante");
      } else if (
        this.form.correoapoderado !== "" &&
        this.validEmail(this.form.correoapoderado) === false
      ) {
        swal("Por favor ingresar un correo valido del representante");
      } else {
        return true;
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(email);
    },
    onChangeCiudad() {
      if (this.form.depart !== "") {
        this.optionsciudades = [];

        this.$http
          .post(rutaciudad + `${this.form.depart}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudades.push(objeto);
            }
            this.showciudad = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeCiudadapoderado() {
      //CAMBIO
      if (this.form.departapoderado !== "") {
        this.optionsciudadesapo = [];

        this.$http
          .post(rutaciudad + `${this.form.departapoderado}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudadesapo.push(objeto);
            }
            this.showciudadapo = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeRadioButton() {
      // Hacer algo basado en el valor seleccionado
      if (this.form.selectedtipoper === "1") {
        // Se seleccionó "Natural"
        console.log("Se seleccionó Natural");
        this.form.selectedtipoident = "";
        this.form.selectedtipoperjuridica = "";
        // Ejecuta tu código aquí
      } else if (this.form.selectedtipoper === "2") {
        // Se seleccionó "Jurídica"
        this.form.selectedtipoident = "";
        console.log("Se seleccionó Jurídica");
        // Ejecuta tu código aquí
      }
    },
    onChangeRadioButtonApo() {
      // Hacer algo basado en el valor seleccionado
      if (this.form.selectedtipoperapo === "1") {
        // Se seleccionó "Natural"
        console.log("Se seleccionó Natural");
        this.form.selectedtipoidentapo = "";
        this.form.selectedtipoperjuridicaapo = "";
        // Ejecuta tu código aquí
      } else if (this.form.selectedtipoperapo === "2") {
        // Se seleccionó "Jurídica"
        this.form.selectedtipoidentapo = "";
        console.log("Se seleccionó Jurídica");
        // Ejecuta tu código aquí
      }
    },
    replaceSpecialCharacters(value) {
      if (typeof value === "string") {
        // Reemplaza el carácter "&" y comillas simples por una cadena vacía
        return value.replace(/&/g, "").replace(/'/g, "");
      } else {
        return value; // Si no es una cadena, devuelve el valor sin cambios
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

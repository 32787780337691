<template>
  <div>
    <div class="container">
      <p style="font-weight: bold; text-align: center">
        FORMULARIO AUTORIZACION PARA OCUPACION DE CAUCE Y OBRAS HIDRAULICAS
      </p>
      <!--<p style="font-weight: bold; text-align: center">
        Base legal: Decreto 1076 de 2015 o aquel que lo modifique o sustituya
      </p>-->
    </div>
    <div class="container">
      <p style="text-align: justify">
        Señor(a) usuario (a), los campos que tienen el * son obligatorios para
        radicar su solicitud. Le recomendamos diligenciar la mayoría o totalidad
        de la información solicitada, así como adjuntar los documentos descritos
        en las instrucciones de diligenciamiento de este formulario.
      </p>
    </div>
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="text-right my-3">
        <b-button
          v-b-tooltip.hover
          title="Limpiar campos"
          variant="success"
          @click="resetForm()"
        >
          <b-icon icon="trash-fill"></b-icon>
        </b-button>
      </div>
      <div style="text-align: left; margin-top: 20px">
        <label style="font-weight: bold">I. DATOS DEL SOLICITANTE </label>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group label="*Tipo de persona:" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoper"
              name="selectedtipoper"
              @change="onChangeRadioButton()"
            >
              <b-form-radio value="1">Natural </b-form-radio>
              <b-form-radio value="2">Jurídica</b-form-radio>
            </b-form-radio-group>
            <b-form-radio-group
              v-if="form.selectedtipoper == 2"
              id="radio-slots"
              v-model="form.selectedtipoperjuridica"
              name="selectedtipoperjuridica"
            >
              <b-form-radio value="1">Jurídica pública </b-form-radio>
              <b-form-radio value="2">Jurídica privada </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <!--<div class="col-md-6">
          <b-form-group label="2. *Tipo de trámite:" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipotram"
              name="selectedtipotram"
            >
              <b-form-radio value="257">Nuevo </b-form-radio>
              <b-form-radio value="7">Renovación </b-form-radio>
              <b-form-radio value="210">Modificación </b-form-radio>
            </b-form-radio-group>

            <label
              >(Aplica para trámites relacionados con permisos
              existentes)</label
            >
            <b-form-input
              v-model="form.expe"
              placeholder="Número de expediente:"
              maxlength="30"
            ></b-form-input>
          </b-form-group>
        </div>-->
        <div class="col-md-6">
          <b-form-group
            label="*Tipo de identificación:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoident"
              name="selectedtipoident"
            >
              <b-form-radio value="10" v-if="form.selectedtipoper == 1"
                >CC
              </b-form-radio>
              <b-form-radio value="26" v-if="form.selectedtipoper == 2"
                >NIT
              </b-form-radio>
              <b-form-radio value="36" v-if="form.selectedtipoper == 1"
                >Pasaporte
              </b-form-radio>
              <b-form-radio value="25" v-if="form.selectedtipoper == 2"
                >Personería jurídica
              </b-form-radio>
              <b-form-radio value="11" v-if="form.selectedtipoper == 1"
                >Cédula de extranjería
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="*Nombre o razón social:"
            style="text-align: left"
          >
            <b-form-group style="text-align: left">
              <label
                style="text-align: left"
                v-if="
                  form.selectedtipoident == 26 || form.selectedtipoident == 25
                "
              >
                *Razón Social</label
              >
              <b-form-input
                v-if="
                  form.selectedtipoident == 26 || form.selectedtipoident == 25
                "
                v-model="form.nombre"
                placeholder="razón social"
                maxlength="180"
              ></b-form-input>
            </b-form-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group>
            <div class="row">
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Nombre</label
                >
                <b-form-input
                  placeholder="Primer Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre1"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Nombre</label
                >
                <b-form-input
                  placeholder="Segundo Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre2"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Apellido</label
                >
                <b-form-input
                  placeholder="Primer Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre3"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Apellido</label
                >
                <b-form-input
                  placeholder="Segundo Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre4"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 26 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 25 ||
                form.selectedtipoident == 11
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacion"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 11
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacion"
                  placeholder="No:"
                  v-if="
                    form.selectedtipoident == 26 || form.selectedtipoident == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digito"
                  placeholder="Dígito verificación:"
                  v-if="
                    form.selectedtipoident == 26 || form.selectedtipoident == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
            <label>*Dirección de correspondencia: </label>
            <b-form-input
              v-model="form.dircorrespondencia"
              placeholder="Dirección de correspondencia"
              maxlength="95"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.depart"
              :options="optionsdepartamento"
              @change="onChangeCiudad()"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Ciudad:" style="text-align: left">
            <b-form-select v-show="showciudad" v-model="ciudadcompleta">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsciudades"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Teléfono (s):" style="text-align: left"
            ><b-form-input
              v-model="form.tel"
              placeholder="Teléfono (s)"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Fax:" style="text-align: left"
            ><b-form-input
              v-model="form.fax"
              placeholder="Fax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correo"
              placeholder="Correo electrónico"
              type="email"
              class="form-control"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="*¿Autoriza la notificación mediante correo electrónico?"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedcorreo"
              name="selectedcorreo"
            >
              <b-form-radio value="1">Si </b-form-radio>
              <b-form-radio value="2">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group
            label="En caso contrario indique la dirección para notificación física:"
            style="text-align: left"
            ><b-form-input
              v-model="form.correodirnoti"
              placeholder="Dirección de notificación"
              maxlength="200"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="4. Información del Representante"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedrepre"
              name="selectedrepre"
            >
              <b-form-radio value="1">Legal</b-form-radio>
              <b-form-radio value="2">ó Apoderado</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <!--<div class="col-md-6">
          <b-form-group label="1. Tipo de persona:" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoperapo"
              name="selectedtipoperapo"
              @change="onChangeRadioButtonApo()"
            >
              <b-form-radio value="1">Natural </b-form-radio>
              <b-form-radio value="2">Jurídica</b-form-radio>
            </b-form-radio-group>
            <b-form-radio-group
              v-if="form.selectedtipoperapo == 2"
              id="radio-slots"
              v-model="form.selectedtipoperjuridicaapo"
              name="selectedtipoperjuridicaapo"
            >
              <b-form-radio value="1">Jurídica pública </b-form-radio>
              <b-form-radio value="2">Jurídica privada </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>-->

        <div class="col-md-6">
          <b-form-group
            label="Tipo de identificación:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoidentapo"
              name="selectedtipoidentapo"
            >
              <b-form-radio value="10" v-if="form.selectedtipoperapo == 1"
                >CC
              </b-form-radio>
              <b-form-radio value="26" v-if="form.selectedtipoperapo == 2"
                >NIT
              </b-form-radio>
              <b-form-radio value="36" v-if="form.selectedtipoperapo == 1"
                >Pasaporte
              </b-form-radio>
              <b-form-radio value="25" v-if="form.selectedtipoperapo == 2"
                >Personería jurídica
              </b-form-radio>
              <b-form-radio value="11" v-if="form.selectedtipoperapo == 1"
                >Cédula de extranjería
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <!------------------------------------------------------------------------------------->
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              style="text-align: left"
              v-if="
                form.selectedtipoidentapo == 26 ||
                form.selectedtipoidentapo == 25
              "
            >
              Nombre</label
            >
            <b-form-input
              v-if="
                form.selectedtipoidentapo == 26 ||
                form.selectedtipoidentapo == 25
              "
              v-model="form.nombreapoderado"
              placeholder="Nombre"
              maxlength="180"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group>
            <div class="row">
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  >*Primer Nombre</label
                >
                <b-form-input
                  placeholder="Primer Nombre"
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  v-model="form.nombre1apo"
                  maxlength="180"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  >Segundo Nombre</label
                >
                <b-form-input
                  placeholder="Segundo Nombre"
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  v-model="form.nombre2apo"
                  maxlength="180"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  >*Primer Apellido</label
                >
                <b-form-input
                  placeholder="Primer Apellido"
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  v-model="form.nombre3apo"
                  maxlength="180"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  >Segundo Apellido</label
                >
                <b-form-input
                  placeholder="Segundo Apellido"
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  v-model="form.nombre4apo"
                  maxlength="180"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoidentapo == 10 ||
                form.selectedtipoidentapo == 26 ||
                form.selectedtipoidentapo == 36 ||
                form.selectedtipoidentapo == 25 ||
                form.selectedtipoidentapo == 11
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacionapode"
              placeholder="No:"
              v-if="
                form.selectedtipoidentapo == 10 ||
                form.selectedtipoidentapo == 36 ||
                form.selectedtipoidentapo == 11
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacionapode"
                  placeholder="No:"
                  v-if="
                    form.selectedtipoidentapo == 26 ||
                    form.selectedtipoidentapo == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digitoapo"
                  placeholder="Dígito verificación:"
                  v-if="
                    form.selectedtipoidentapo == 26 ||
                    form.selectedtipoidentapo == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <!------------------------------------------------------------------------------------->

        <div class="col-md-6">
          <b-form-group label="De:" style="text-align: left"
            ><b-form-input
              v-model="form.lugaridapoderado"
              placeholder="Lugar de expedición de la identificación"
              maxlength="30"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="Dirección de correspondencia:"
            style="text-align: left"
            ><b-form-input
              v-model="form.dirapoderado"
              placeholder="Dirección de correspondencia"
              maxlength="95"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departapoderado"
              :options="optionsdepartamento"
              @change="onChangeCiudadapoderado()"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Ciudad:" style="text-align: left">
            <b-form-select v-show="showciudadapo" v-model="ciudadapoderado"
              ><b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsciudadesapo"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option></b-form-select
            >
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Teléfono (s):" style="text-align: left"
            ><b-form-input
              v-model="form.telapoderado"
              placeholder="Teléfono (s)"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Fax:" style="text-align: left"
            ><b-form-input
              v-model="form.faxapoderado"
              placeholder="Fax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correoapoderado"
              placeholder="Correo electrónico"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="5. Calidad en que actúa sobre el predio:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedcalidadactua"
              name="selectedcalidadactua"
            >
              <b-form-radio value="1">Propietario </b-form-radio>
              <b-form-radio value="2">Poseedor </b-form-radio>
              <b-form-radio value="3">Tenedor </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <!--------------------------cierre row finaliza el primer bloque de informacion ------------->
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="*Descripción" style="text-align: left">
            <b-form-textarea
              id="textarea"
              v-model="form.observacionradicado"
              placeholder="Descripción breve de la solicitud"
              rows="3"
              max-rows="6"
              maxlength="3900"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div style="text-align: left; margin-top: 20px">
        <label style="font-weight: bold"
          >II. INFORMACIÓN GENERAL DEL PREDIO
        </label>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="1. Nombre del predio:" style="text-align: left"
            ><b-form-input
              v-model="form.nombrepredio"
              placeholder="Nombre del predio"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2. *Dirección del predio:"
            style="text-align: left"
            ><b-form-input
              v-model="form.direccionpredio"
              placeholder="Dirección del predio"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="3. *Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departpredio"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Municipio:" style="text-align: left">
            <b-form-select
              v-model="form.ciudadpredio"
              :options="optionsCiudad"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="Nombre centro poblado, vereda y/o corregimiento:"
            style="text-align: left"
            ><b-form-input
              v-model="form.nombrecentropoblado"
              placeholder="Nombre centro poblado, vereda y/o corregimiento:"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row" style="padding: 5px">
        <div class="col-md-12">
          <b-form-group
            label="FIRMA DEL SOLICITANTE O APODERADO DEBIDAMENTE CONSTITUIDO"
            style="text-align: left; font-weight: bold"
            ><b-form-input v-model="form1.firma" maxlength="50"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11">
          <b-form-file
            v-model="file1"
            :state="Boolean(file1)"
            placeholder="Adjunta los documentos, peso maximo total archivos 50 Mb, peso maximo por cada archivo 10 Mb"
            drop-placeholder="Drop file here..."
            multiple
            browse-text="Buscar"
          ></b-form-file>
        </div>
        <div class="col-md-1">
          <b-button id="popover-button-variant" href="#" tabindex="0"
            >Ayuda</b-button
          >
          <b-popover
            target="popover-button-variant"
            variant="info"
            triggers="focus"
          >
            <template #title>Tutorial</template>
            De click
            <b-link
              href="../../../document/Guía Rápida Adjuntar documentos Tramites Web.pdf"
              target="blank"
              >aqui</b-link
            >
          </b-popover>
        </div>
      </div>
      <br /><br />
      <b-form-checkbox
        id="aceptacion"
        v-model="form1.aceptacion"
        name="aceptacion"
        value="1"
        unchecked-value="0"
        >Acepto el uso de mis datos personales, tratamiento, protección y
        conservación de estos a CVC de acuerdo a lo establecido en la Ley 1581
        de 2012.
        <a
          href="https://www.cvc.gov.co/politicas_de_seguridad_y_privacidad_de_la_informacion"
          >Ver Política de protección de Datos Personales de la CVC.</a
        > </b-form-checkbox
      ><br />
      <div v-show="show">
        <b-button block variant="success" @click="enviar()"
          >Enviar solicitud</b-button
        >
      </div>
      <br />
      <div class="text-center" v-show="loading">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <div class="text-center">
          <strong>Enviando Solicitud</strong><br />
          <strong
            >IMPORTANTE: Si adjuntó archivos, por favor espere que termine la
            subida y salga un mensaje con el número de radicado. Si abandona el
            proceso, la solicitud quedará incompleta...</strong
          >
        </div>
        <br />

        <div
          :key="ind"
          v-for="(tr, ind) in filesValide"
          class="row ml-8 mb-2 d-flex justify-content-center"
        >
          <b-progress
            :max="100"
            striped
            animated
            :variant="'primary'"
            class="w-75"
          >
            <b-progress-bar :value="tr.value === 0 ? 33 : tr.value">
              <span>
                {{
                  tr.value !== 0
                    ? tr.color
                      ? `Se proceso el archivo '${tr.name} de manera correcta.`
                      : `No se pudo procesar el archivo '${tr.name}'.`
                    : `Se esta procesando el archivo '${tr.name}'`
                }}
                <strong></strong></span
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
      <br />
    </div>
    <!--<div class="border border-dark container">
      <label style="font-weight: bold">INSTRUCCIONES DE DILIGENCIAMIENTO</label>
      <p style="text-align: justify">
        El presente Formato Único Nacional de solicitud de permiso de
        vertimiento al suelo busca orientar al usuario en la entrega de
        información general para el trámite correspondiente, permitiendo a la
        Autoridad Ambiental Competente - AAC consolidar la información básica
        para adelantar la visita de campo y generar el concepto técnico en el
        marco del permiso ambiental.
      </p>
      <b-form-group
        label="I. DATOS DEL SOLICITANTE (ARTÍCULO 2.2.3.3.5.2 DEL DECRETO 1076 DE 2015)"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        <b>1. Tipo de persona:</b> indicar el tipo de solicitante, ya sea una
        persona jurídica o natural.
      </p>
      <p style="text-align: justify">
        <b> 2. Tipo de trámite:</b> indicar si el trámite se refiere a un
        permiso nuevo, modificación o renovación de un permiso existente, de
        acuerdo con lo establecido en los artículos 2.2.3.3.5.9 y 2.2.3.3.5.10
        del Decreto 1076 de 2015. Asimismo, indicar el número de expediente, en
        caso que el trámite se relacione con un permiso existente (modificación
        o renovación).
      </p>
      <p style="text-align: justify">
        <b> 3. Nombre o Razón Social:</b> indicar el nombre o razón social del
        solicitante, señalar si el tipo de identificación es cédula de
        ciudadanía, número de identificación tributaria (NIT), personería
        jurídica, cédula de extranjería o pasaporte, con su respectivo número.
        Indicar la dirección de correspondencia del solicitante y otra
        información de contacto. Marcar con una "X" si se autoriza la
        notificación mediante el correo electrónico suministrado.
      </p>
      <p style="text-align: justify">
        <b> 4. Información del Representante legal o Apoderado:</b> digitar los
        nombres y apellidos del representante legal o el apoderado (si aplica)
        del solicitante, su tipo y número de identificación (CC o CE) e
        información de contacto.
      </p>
      <p style="text-align: justify">
        <b>
          5. Calidad en que actúa sobre el predio donde se realizará el
          vertimiento:</b
        >
        Indicar si el solicitante actúa en calidad de propietario, tenedor o
        poseedor del predio, según sea el caso, de acuerdo con el artículo
        2.2.3.2.9.2 del Decreto 1076 de 2015 y el Código Civil Colombiano.
        Dependiendo de la opción seleccionada, se debe adjuntar el respectivo
        soporte, de acuerdo con lo especificado en la sección ""DOCUMENTACIÓN
        QUE DEBE ANEXAR A LA SOLICITUD".
      </p>

      <b-form-group
        label="II. INFORMACIÓN GENERAL DEL PREDIO PARA EL CUAL SE SOLICITA EL PERMISO (ARTÍCULO 2.2.3.3.5.2 DEL DECRETO 1076 DE 2015)"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        <b>1. Nombre del predio:</b> registrar el nombre del predio solicitante
        del permiso, según lo establece el certificado de libertad y tradición.
      </p>
      <p style="text-align: justify">
        <b> 2. Dirección del predio:</b> indicar la localización del predio
        solicitante del permiso.
      </p>
      <p style="text-align: justify">
        <b> 3. Departamento:</b> indicar el Departamento, Municipio y el nombre
        del centro poblado y/o corregimiento en el que se localiza el predio.
      </p>

      <b-form-group
        label="III. INFORMACIÓN GENERAL DE LA ACTIVIDAD ECONÓMICA DEL SOLICITANTE (ARTÍCULO 2.2.3.3.5.2 DEL DECRETO 1076 DE 2015)"
        style="text-align: left; font-weight: bold"
      ></b-form-group>

      <p style="text-align: justify">
        <b>1. Actividad económica:</b> registre la actividad económica para la
        cual se requiere aprovechar el recurso hídrico, según lo definido por la
        DIAN. Consultar la Clasificacion Industrial Internacional Uniforme de
        todas las actividades economicas - CIIU en:
        https://www.dane.gov.co/index.php/sistema-estadistico-nacional-sen/normas-y-estandares/nomenclaturas-y-clasificaciones.
      </p>
      <p style="text-align: justify">
        <b
          >2. Código CIIU (Clasificación Industrial Internacional Uniforme) de
          la actividad económica (campo opcional, no dará lugar a la devolución
          del trámite ni a la solicitud de información adicional):</b
        >
        indique el código CIIU aplicable a la actividad económica desarrollada,
        con base en lo definido por la cámara de comercio. Consultar la
        Clasificacion Industrial Internacional Uniforme de todas las actividades
        economicas - CIIU en:
        https://www.dane.gov.co/index.php/sistema-estadistico-nacional-sen/normas-y-estandares/nomenclaturas-y-clasificaciones.
      </p>
      <p style="text-align: justify">
        <b>3. Costo total del proyecto:</b> registre el costo total del proyecto
        en letras y números, con el fin de evaluar las condiciones
        presupuestales para el cobro del servicio ambiental por parte de la
        Autoridad Ambiental Competente, de acuerdo con el artículo 96 de la Ley
        633 de 2000 y la Resolución 1280 de 2010.
      </p>

      <b-form-group
        label="IV. INFORMACIÓN GENERAL DEL ÁREA DE DISPOSICIÓN Y DEL VERTIMIENTO (ARTÍCULOS 2.2.3.3.4.9 Y 2.2.3.3.5.2 DEL DECRETO 1076 DE 2015)"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        <b>1. Tipo de cuerpo receptor:</b> Seleccionar el tipo de cuerpo de agua
        al que descargará el vertimiento, de acuerdo con las siguientes
        definiciones:
      </p>

      <p style="text-align: justify">
        <b>1. Localización del vertimiento:</b> especificar el departamento,
        municipio y centro poblado o corregimiento donde se ubica el área de
        disposición.
      </p>
      <p style="text-align: justify">
        <b> 2. Fuente de abastecimiento:</b> especificar la fuente de
        abastecimiento de agua del proyecto, obra o actividad, de acuerdo con lo
        establecido en el numeral 8 del artículo 2.2.3.3.5.2 del Decreto 1076 de
        2015.
      </p>
      <p style="text-align: justify">
        <b>
          3. Coordenadas geográficas del área de disposición del vertimiento:</b
        >
        especificar las coordenadas geográficas de los vértices del área de
        disposición del vertimiento en sistema de referencia MAGNA SIRGAS. Es
        necesario registrar la latitud, longitud y altitud, con el fin de
        facilitar la compatibilidad e interoperabilidad con las técnicas
        actuales de georreferenciación, en especial con los Sistemas Globales de
        Navegación por Satélite (SGNS), de acuerdo con el IGAC (Ver Resoluciones
        471 y 529 de 2020, Resolución 955 de 2012, Decreto 303 de 2012 y
        Resolución 068 de 2005, o aquellas que las modifiquen, adicionen o
        sustituyan).
      </p>
      <p style="text-align: justify">
        <b> 4. Uso del suelo actual del área de disposición:</b> Especificar el
        uso actual y potencial del área de disposición del vertimiento, con base
        en los instrumentos de planificación del territorio e información
        primaria y secundaria, identificando los usos actuales y conflictos de
        uso del suelo y del territorio (artículo 2.2.3.3.4.9 del Decreto 1076 de
        2015).
      </p>
      <p style="text-align: justify">
        <b> 5. Tipo de vertimiento:</b> indicar si la solicitud se realiza para
        el vertimiento de Aguas Residuales Domésticas (ARD) o Aguas Residuales
        no Domésticas (ARnD), de acuerdo con las definiciones del artículo 1 de
        la Resolución 631 de 2015 o aquella que la modifique o sustituya.
      </p>
      <p style="text-align: justify">
        <b> 6. Tiempo de descarga:</b>
        indicar el tiempo descarga del vertimiento en horas por día, la
        frecuencia en días por mes y el caudal aproximado a verter en litros por
        segundo.
      </p>
      <p style="text-align: justify">
        <b> 7. Tipo de flujo de la descarga:</b> seleccionar el tipo de flujo de
        la descarga, de acuerdo con lo establecido en el numeral 15 del artículo
        2.2.3.3.5.2 del Decreto 1076 de 2015:
      </p>
      <p style="text-align: justify">
        <b> Descarga continua:</b> el caudal y la concentración del agua
        residual no varían, debido a que se derivan de un mismo ciclo productivo
        que no presenta cambios apreciables en el tiempo.
      </p>
      <p style="text-align: justify">
        <b> Descarga intermitente:</b> el tiempo de descarga y la producción no
        es constante, creando una variación tanto en el caudal como en la carga
        contaminante.
      </p>
      <p style="text-align: justify">
        <b> 8. Área de disposición del vertimiento (m2):</b> indique la
        extensión del área de disposición del vertimiento, en metros cuadrados.
      </p>
      <p style="text-align: justify">
        <b>
          9. Observaciones de acceso al área de disposición del vertimiento o
          generalidades relevantes (campo opcional, no dará lugar a la
          devolución del trámite ni a la solicitud de información adicional):</b
        >
        mencionar generalidades asociadas al acceso al área de disposición para
        facilitar la visita de campo o aspectos relevantes que se crean
        convenientes mencionar. Adjuntar croquis.
      </p>

      <b-form-group
        label="DOCUMENTACIÓN QUE SE DEBE ANEXAR A LA SOLICITUD"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        Nota: De acuerdo con lo establecido en el artículo 2.2.3.3.5.10 del
        Decreto 1076 de 2015, "Para la renovación del permiso de vertimiento se
        deberá observar el trámite previsto para el otorgamiento de dicho
        permiso en el presente decreto. Si no existen cambios en la actividad
        generadora del vertimiento, la renovación queda supeditada solo a la
        verificación del cumplimiento de la norma de vertimiento mediante la
        caracterización del vertimiento".
      </p>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Documentación</th>
            <th scope="col">Prestadores del servicio de alcantarillado</th>
            <th scope="col">Vertimiento de ARD</th>
            <th scope="col">Vertimiento de ARnD</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p style="text-align: justify">
                Poder debidamente otorgado cuando se actúe por medio de
                apoderado (numeral 2 del artículo 2.2.3.3.5.2 del Decreto 1076
                de 2015).
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Documentos que acrediten la personería jurídica del solicitante,
                cuando aplique, hasta tanto no se realice la interoperabilidad
                entre los sistemas de información de las entidades que emiten
                las acreditaciones y la Autoridad Ambiental Competente (numeral
                3 del artículo 2.2.3.3.5.2 del Decreto 1076 de 2015):
              </p>
              <p style="text-align: justify">
                -Sociedades: Certificado de existencia y representación legal
                (expedición no superior a 3 meses).
              </p>
              <p style="text-align: justify">
                -Juntas de Acción Comunal: Certificado de existencia y
                representación legal o del documento que haga sus veces,
                expedido con una antelación no superior a 3 meses.
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Certificado actualizado del Registrador de Instrumentos Públicos
                y Privados sobre la propiedad del inmueble, o la prueba idónea
                de la posesión o tenencia (numerales 4 y 5 del artículo
                2.2.3.3.5.2 del Decreto 1076 de 2015):
              </p>
              <p style="text-align: justify">
                - Propietario del inmueble: Certificado de tradición y libertad
                (expedición no superior a 3 meses)
              </p>
              <p style="text-align: justify">
                - Tenedor: Prueba adecuada que lo acredite como tal,
                autorización del propietario o poseedor y Certificado de
                tradición y libertad del inmueble (expedición no superior a 3
                meses).
              </p>
              <p style="text-align: justify">
                - Poseedor: Prueba adecuada que lo acredite como tal y
                Certificado de tradición y libertad del inmueble (expedición no
                superior a 3 meses)
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Características de las actividades que generan el vertimiento
                (numeral 9 del artículo 2.2.3.3.5.2 del Decreto 1076 de 2015).
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Plano donde se identifique origen, cantidad y localización
                georreferenciada de las descargas al cuerpo de agua (numeral 10
                del artículo 2.2.3.3.5.2 del Decreto 1076 de 2015).
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Caracterización actual del vertimiento existente o estado final
                previsto para el vertimiento proyectado (numeral 16 del artículo
                2.2.3.3.5.2 del Decreto 1076 de 2015), de acuerdo con los
                parámetros mínimos establecidos para cada actividad en la norma
                de vertimientos correspondiente (Resolución 631 de 2015,
                Resolución 883 de 2018 o aquella que las modifique, adicione o
                sustituya).
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Ubicación, descripción de la operación del sistema, memorias
                técnicas y diseños de ingeniería conceptual y básica, planos de
                detalle del sistema de tratamiento (existente o propuesto) y
                condiciones de eficiencia del sistema de tratamiento que se
                adoptará (numeral 17 del artículo 2.2.3.3.5.2 del Decreto 1076
                de 2015).
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Concepto sobre el uso del suelo expedido por la autoridad
                municipal competente (numeral 18 del artículo 2.2.3.3.5.2 del
                Decreto 1076 de 2015).
              </p>
            </td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Documento Evaluación Ambiental del Vertimiento (numeral 19 del
                artículo 2.2.3.3.5.2 del Decreto 1076 de 2015). De acuerdo con
                el artículo 2.2.3.3.5.3 del Decreto 1076 de 2015, la evaluación
                ambiental del vertimiento deberá ser presentada por los
                generadores de vertimientos a cuerpos de aguas o al suelo que
                desarrollen actividades industriales, comerciales y/o de
                servicio, así como los provenientes de conjuntos residenciales
                (*), y deberá contener como mínimo:
              </p>
              <p style="text-align: justify">
                1. Localización georreferenciada de proyecto, obra o actividad.
              </p>
              <p style="text-align: justify">
                2. Memoria detallada del proyecto, obra o actividad que se
                pretenda realizar, con especificaciones de procesos y
                tecnologías que serán empleados en la gestión del vertimiento.
              </p>
              <p style="text-align: justify">
                3. Información detallada sobre la naturaleza de los insumos,
                productos químicos, formas de energía empleados y los procesos
                químicos y físicos utilizados en el desarrollo del proyecto,
                obra o actividad que genera vertimientos.
              </p>

              <p style="text-align: justify">
                4. Predicción y valoración de los impactos que puedan derivarse
                de los vertimientos generados por el proyecto, obra o actividad
                al suelo, considerando su vocación conforme a lo dispuesto en
                los instrumentos de ordenamiento territorial y los Planes de
                Manejo Ambiental de Acuíferos. Cuando estos últimos no existan,
                la autoridad ambiental competente definirá los términos y
                condiciones bajo los cuales se debe realizar la identificación
                de los impactos y la gestión ambiental de los mismos.
              </p>
              <p style="text-align: justify">
                5. Manejo de residuos asociados a la gestión del vertimiento.
              </p>
              <p style="text-align: justify">
                6. Descripción y valoración de los impactos generados por el
                vertimiento y las medidas para prevenir, mitigar, corregir y
                compensar dichos impactos al suelo.
              </p>
              <p style="text-align: justify">
                7. Posible incidencia del proyecto, obra o actividad en la
                calidad de la vida o en las condiciones económicas, sociales y
                culturales de los habitantes del sector o de la región en donde
                pretende desarrollarse y medidas que se adoptarán para evitar o
                minimizar efectos negativos de orden sociocultural que puedan
                derivarse de la misma.
              </p>
              <p style="text-align: justify">
                8. Estudios técnicos y diseños de la estructura de descarga de
                los vertimientos, que sustenten su localización y
                características.
              </p>
              <p style="text-align: justify">
                (*) Nota: El parágrafo 2 del artículo 2.2.3.3.5.3 del Decreto
                1076 de 2015 establece que "(...) en relación con los conjuntos
                residenciales, la autoridad ambiental definirá los casos en los
                cuales no estarán obligados a presentar la evaluación ambiental
                del vertimiento en función de la capacidad de carga del cuerpo
                receptor, densidad de ocupación del suelo y densidad
                poblacional".
              </p>
            </td>
            <td>
              X
              <p>
                (Aplica si el vertimiento proviene de actividades industriales,
                comerciales o de servicios, de acuerdo con el artículo
                2.2.3.3.5.3 del Decreto 1076 de 2015: "La evaluación ambiental
                del vertimiento deberá ser presentada por los generadores de
                vertimientos a cuerpos de aguas o al suelo que desarrollen
                actividades industriales, comerciales y/o de servicio, así como
                los provenientes de conjuntos residenciales")
              </p>
            </td>
            <td>
              X
              <p>
                (Aplica si el vertimiento proviene de actividades industriales,
                comerciales o de servicios, de acuerdo con el artículo
                2.2.3.3.5.3 del Decreto 1076 de 2015: "La evaluación ambiental
                del vertimiento deberá ser presentada por los generadores de
                vertimientos a cuerpos de aguas o al suelo que desarrollen
                actividades industriales, comerciales y/o de servicio, así como
                los provenientes de conjuntos residenciales")
              </p>
            </td>
            <td>
              X
              <p>
                (Aplica si el vertimiento proviene de actividades industriales,
                comerciales o de servicios, de acuerdo con el artículo
                2.2.3.3.5.3 del Decreto 1076 de 2015: "La evaluación ambiental
                del vertimiento deberá ser presentada por los generadores de
                vertimientos a cuerpos de aguas o al suelo que desarrollen
                actividades industriales, comerciales y/o de servicio, así como
                los provenientes de conjuntos residenciales")
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Documento Plan de Gestión del Riesgo para el manejo de
                vertimientos (numeral 20 del artículo 2.2.3.3.5.2 del Decreto
                1076 de 2015). De acuerdo con el artículo 2.2.3.3.5.4 del
                Decreto 1076 de 2015, el plan de gestión del riesgo aplica para
                vertimientos a cuerpos de agua provenientes de actividades
                industriales, comerciales y de servicio, ya sean ARD o ARnD.
                Dicho Plan debe incluir el análisis del riesgo, medidas de
                prevención y mitigación, protocolos de emergencia y contingencia
                y programa de rehabilitación y recuperación, incluyendo
                (Resolución 1514 de 2012):
              </p>
              <p style="text-align: justify">
                - Generalidades (objetivos, antecedentes, alcances y
                metodología)
              </p>
              <p style="text-align: justify">
                - Descripción de las actividades y procesos asociados al sistema
                de gestión del vertimiento (localización, componentes y
                funcionamiento).
              </p>
              <p style="text-align: justify">
                - Caracterización del área de influencia (área de influencia,
                descripción medio abiótica, descripción medio biótica,
                descripción medio socio económico).
              </p>
              <p style="text-align: justify">
                - Proceso del conocimiento del riesgo (identificación y
                determinación de la probabilidad de ocurrencia y/o presencia de
                amenaza; identificación y análisis de vulnerabilidad y
                Consolidación de los escenarios de riesgo).
              </p>
              <p style="text-align: justify">
                - Proceso de reducción del riesgo asociado al sistema de gestión
                del vertimiento.
              </p>
              <p style="text-align: justify">
                - Proceso de manejo del desastre (preparación para la respuesta,
                preparación para la recuperación post desastre y ejecución de la
                respuesta y la respectiva recuperación).
              </p>
              <p style="text-align: justify">
                - Sistema de seguimiento y evaluación del plan.
              </p>
              <p style="text-align: justify">- Divulgación del plan.</p>
              <p style="text-align: justify">
                - Actualización y vigencia del plan.
              </p>
              <p style="text-align: justify">
                - Profesionales responsables de la formulación del plan.
              </p>
              <p style="text-align: justify">- Anexos y planos.</p>
            </td>
            <td>
              X
              <p>
                (Aplica si el vertimiento proviene de actividades industriales,
                comerciales o de servicios, de acuerdo con el artículo
                2.2.3.3.5.4 del Decreto 1076 de 2015: "Las personas naturales o
                jurídicas de derecho público o privado que desarrollen
                actividades industriales, comerciales y de servicios que generen
                vertimientos a un cuerpo de agua o al suelo deberán elaborar un
                Plan de Gestión del Riesgo para el Manejo de Vertimientos")
              </p>
            </td>
            <td>
              X
              <p>
                (Aplica si el vertimiento proviene de actividades industriales,
                comerciales o de servicios, de acuerdo con el artículo
                2.2.3.3.5.4 del Decreto 1076 de 2015: "Las personas naturales o
                jurídicas de derecho público o privado que desarrollen
                actividades industriales, comerciales y de servicios que generen
                vertimientos a un cuerpo de agua o al suelo deberán elaborar un
                Plan de Gestión del Riesgo para el Manejo de Vertimientos")
              </p>
            </td>
            <td>
              X
              <p>
                (Aplica si el vertimiento proviene de actividades industriales,
                comerciales o de servicios, de acuerdo con el artículo
                2.2.3.3.5.4 del Decreto 1076 de 2015: "Las personas naturales o
                jurídicas de derecho público o privado que desarrollen
                actividades industriales, comerciales y de servicios que generen
                vertimientos a un cuerpo de agua o al suelo deberán elaborar un
                Plan de Gestión del Riesgo para el Manejo de Vertimientos")
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Para aguas residuales domésticas tratadas (artículo 2.2.3.3.4.9
                del Decreto 1076 de 2015):
              </p>
              <p style="text-align: justify">
                <b>1. Infiltración.</b> Resultados y datos de campo de pruebas
                de infiltración calculando la tasa de infiltración.
              </p>
              <p style="text-align: justify">
                <b> 2. Sistema de disposición de los vertimientos.</b> Diseño y
                manual de operación y mantenimiento del sistema de disposición
                de aguas residuales tratadas al suelo, incluyendo el mecanismo
                de descarga y sus elementos estructurantes que permiten el
                vertimiento al suelo.
              </p>
              <p style="text-align: justify">
                <b> 3. Área de disposición del vertimiento.</b> Identificación
                del área donde se realizará la disposición en plano topográfico
                con coordenadas magna sirgas, indicando como mínimo: dimensión
                requerida, los usos de los suelos en las áreas colindantes y el
                uso actual y potencial del suelo donde se realizará el
                vertimiento del agua residual doméstica tratada, conforme al
                Plan de Ordenación y Manejo de Cuenca Hidrográfica y los
                instrumentos de ordenamiento territorial vigentes.
              </p>
              <p style="text-align: justify">
                <b>
                  4. Plan de cierre y abandono del área de disposición del
                  vertimiento.</b
                >
                Plan que define el uso que se le dará al área que se utilizó
                como disposición del vertimiento. Para tal fin, las actividades
                contempladas en el plan de cierre deben garantizar que las
                condiciones físicas, químicas y biológicas del suelo permiten el
                uso potencial definido en los instrumentos de ordenamiento
                territorial vigentes y sin perjuicio de la afectación sobre la
                salud pública.
              </p>
            </td>
            <td>
              X
              <p>
                (Casos en los cuales el prestador del servicio de alcantarillado
                cuente con ARD exclusivas)
              </p>
            </td>
            <td>X</td>
            <td></td>
          </tr>
          <tr>
            <td>
              <p style="text-align: justify">
                Para aguas residuales no domésticas tratadas (artículo
                2.2.3.3.4.9 del Decreto 1076 de 2015):
              </p>
              <p style="text-align: justify">
                <b> 1. Línea base del suelo:</b> caracterización fisicoquímica y
                biológica del suelo, relacionada con el área de disposición del
                vertimiento.
              </p>
              <p style="text-align: justify">
                <b> 2. Línea base del agua subterránea:</b> Determinación de la
                dirección de flujo mediante monitoreo del nivel del agua
                subterránea en pozos o aljibes existentes o en piezómetros
                construidos para dicho propósito, previa nivelación topográfica
                de los mismos.
              </p>
              <p style="text-align: justify">
                <b> 3. Sistema de disposición de los vertimientos.</b> Diseño y
                manual de operación y mantenimiento del sistema de disposición
                de aguas residuales tratadas al suelo, incluyendo el mecanismo
                de descarga y sus elementos estructurantes que permiten el
                vertimiento al suelo. El diseño del sistema de disposición de
                los vertimientos debe incluir la siguiente documentación de
                soporte para el análisis: a) Modelación numérica del flujo y
                transporte de solutos en el suelo; b) Análisis hidrológico que
                incluya la caracterización de los periodos secos y húmedos en la
                cuenca hidrográfica en la cual se localice la solicitud de
                vertimiento; c) Descripción del sistema y equipos para el manejo
                de la disposición al suelo del agua residual tratada; d)
                Determinación de la variación del nivel freático o
                potenciométrico con base en la información recolectada en campo,
                considerando condiciones hidroclimáticas e hidrogeológicas; e)
                Determinación y mapeo a escala 1:10.000 o de mayor detalle de la
                vulnerabilidad intrínseca de los acuíferos a la contaminación,
                sustentando la selección del método utilizado.
              </p>
              <p style="text-align: justify">
                <b> 4. Área de disposición del vertimiento.</b> Identificación
                del área donde se realizará la disposición en plano topográfico
                con coordenadas magna sirgas, indicando como mínimo: dimensión
                requerida, los usos de los suelos en las áreas colindantes y el
                uso actual y potencial del suelo donde se realizará el
                vertimiento del agua residual tratada.
              </p>
              <p style="text-align: justify">
                <b> 5. Plan de monitoreo.</b> Estructurar el Plan de Monitoreo
                para la caracterización del efluente, del suelo y del agua
                subterránea, acorde a la caracterización fisicoquímica del
                vertimiento a realizar, incluyendo grasas y aceites a menos que
                se demuestre que las grasas y aceites no se encuentran presentes
                en sus aguas residuales tratadas.
              </p>
              <p style="text-align: justify">
                <b>
                  6. Plan de cierre y abandono del área de disposición del
                  vertimiento.</b
                >
                Plan que deberá definir el uso que se le dará al área que se
                utilizó como disposición del vertimiento.
              </p>
            </td>
            <td>
              X
              <p>
                (Casos en los cuales el prestador del servicio de alcantarillado
                cuente con ARD + ARnD)
              </p>
            </td>
            <td></td>
            <td>X</td>
          </tr>

          <tr>
            <td>
              <p style="text-align: justify">
                Certificado de disponibilidad o conexión al acueducto, cuando se
                trate de usuarios que cuentan con dicho servicio pero no pueden
                conectarse al sistema de alcantarillado.
              </p>
            </td>
            <td></td>
            <td>X</td>
            <td>X</td>
          </tr>
        </tbody>
      </table>
    </div>-->

    <br />
  </div>
  <!--cierre div principal-->
</template>

<script>
import {
  rutafile,
  rutaciudad,
  rutaenviosolicitud,
  autoOcupacionCauseObrasHidraulicas,
  derechosAmbientales,
  tiponotificacion,
  logo,
} from "../properties";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      form: {
        tipotramite: derechosAmbientales,
        mediorecepcion: "4",
        observacionradicado: "",
        asunto: "AUTORIZACION PARA OCUPACION DE CAUCE Y OBRAS HIDRAULICAS",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram: autoOcupacionCauseObrasHidraulicas, //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        expe: "",
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        desciudad: "", //CAMBIO
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: tiponotificacion,
        selectedrepre: "",
        selectedtipoperapo: "1",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        desciudadapoderado: "", //CAMBIO
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        nombrecentropoblado: "",
        logo: logo,
        filesname: "",
      },
      form1: {
        actividadeco: "",
        CodigoCIIU: "",
        costototal: "",
        deparfuente: "",
        munifuente: "",
        direccionprediofuente: "",
        fuenteabastecimiento: "",
        grado1: "",
        grado2: "",
        grado3: "",
        munitos1: "",
        munitos2: "",
        munitos3: "",
        segundos1: "",
        segundos2: "",
        segundos3: "",
        grados1l: "",
        grados2l: "",
        grados3l: "",
        minutos1l: "",
        minutos2l: "",
        minutos3l: "",
        segundos1l: "",
        segundos2l: "",
        segundos3l: "",
        altitud1l: "",
        altitud2l: "",
        altitud3l: "",
        usosueloactual: "",
        usosuelopotencial: "",
        selectedtipoverti: "",
        selectedcaptacion: "",
        tiempodescargahora: "",
        tiempodescargadias: "",
        tiempodescargacuadal: "",
        selectedtipoflujo: "",
        areavertimiento: "",
        observa: "",
        firma: "",
        aceptacion: "",
        tipovertmiento: "2",
      },
      form2: {},
      ciudadapoderado: "", //CAMBIO
      ciudadcompleta: "", //CAMBIO
      loading: false,
      show: true,
      showciudad: false,
      showciudadapo: false,
      file1: [],
      pesofile1: 0,
      nombrefiles: [],
      arryaJson: [],
      contadorarchivos: 0, // nueva------------------------------------------------------------
      contadorresponse: 0, // nueva------------------------------------------------------------
      filesValide: [], // nueva----------------------------------------------------------------
      optionsCiudad: [
        { value: "1000", text: "SANTIAGO DE CALI" },
        { value: "20000", text: "ALCALA" },
        { value: "36000", text: "ANDALUCIA" },
        { value: "41000", text: "ANSERMANUEVO" },
        { value: "54000", text: "ARGELIA" },
        { value: "100000", text: "BOLIVAR" },
        { value: "109000", text: "BUENAVENTURA" },
        { value: "111000", text: "GUADALAJARA DE BUGA" },
        { value: "113000", text: "BUGALAGRANDE" },
        { value: "122000", text: "CAICEDONIA" },
        { value: "126000", text: "DARIEN" },
        { value: "130000", text: "CANDELARIA" },
        { value: "147000", text: "CARTAGO" },
        { value: "233000", text: "DAGUA" },
        { value: "243000", text: "EL AGUILA" },
        { value: "246000", text: "EL CAIRO" },
        { value: "248000", text: "EL CERRITO" },
        { value: "250000", text: "EL DOVIO" },
        { value: "275000", text: "FLORIDA" },
        { value: "306000", text: "GINEBRA" },
        { value: "318000", text: "GUACARI" },
        { value: "364000", text: "JAMUNDI" },
        { value: "377000", text: "LA CUMBRE" },
        { value: "400000", text: "LA UNION" },
        { value: "403000", text: "LA VICTORIA" },
        { value: "497000", text: "OBANDO" },
        { value: "520000", text: "PALMIRA" },
        { value: "563000", text: "PRADERA" },
        { value: "606000", text: "RESTREPO" },
        { value: "616000", text: "RIOFRIO" },
        { value: "622000", text: "ROLDANILLO" },
        { value: "670000", text: "SAN PEDRO" },
        { value: "736000", text: "SEVILLA" },
        { value: "823000", text: "TORO" },
        { value: "828000", text: "TRUJILLO" },
        { value: "834000", text: "TULUA" },
        { value: "845000", text: "ULLOA" },
        { value: "863000", text: "VERSALLES" },
        { value: "869000", text: "VIJES" },
        { value: "890000", text: "YOTOCO" },
        { value: "892000", text: "YUMBO" },
        { value: "895000", text: "ZARZAL" },
      ],
      optionsdepart: [{ value: "76", text: "VALLE DEL CAUCA" }],
      optionsdepartamento: [
        { value: "91", text: "AMAZONAS" },
        { value: "5", text: "ANTIOQUIA" },
        { value: "81", text: "ARAUCA" },
        {
          value: "88",
          text: "ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA",
        },
        { value: "8", text: "ATLANTICO" },
        { value: "11", text: "BOGOTA D.C" },
        { value: "13", text: "BOLIVAR" },
        { value: "15", text: "BOYACA" },
        { value: "17", text: "CALDAS" },
        { value: "18", text: "CAQUETA" },
        { value: "85", text: "CASANARE" },
        { value: "19", text: "CAUCA" },
        { value: "20", text: "CESAR" },
        { value: "27", text: "CHOCO" },
        { value: "23", text: "CORDOBA" },
        { value: "25", text: "CUNDINAMARCA" },
        { value: "94", text: "GUAINIA" },
        { value: "95", text: "GUAVIARE" },
        { value: "41", text: "HUILA" },
        { value: "44", text: "LA GUAJIRA" },
        { value: "47", text: "MAGDALENA" },
        { value: "50", text: "META" },
        { value: "52", text: "NARIÑO" },
        { value: "54", text: "NORTE DE SANTANDER" },
        { value: "86", text: "PUTUMAYO" },
        { value: "63", text: "QUINDIO" },
        { value: "66", text: "RISARALDA" },
        { value: "68", text: "SANTANDER" },
        { value: "70", text: "SUCRE" },
        { value: "73", text: "TOLIMA" },
        { value: "76", text: "VALLE DEL CAUCA" },
        { value: "97", text: "VAUPES" },
        { value: "99", text: "VICHADA" },
      ],
      optionsciudades: [],
      optionsciudadesapo: [], //CAMBIO
      optionstiponoti: [
        { value: "2", text: "CORREO CERTIFICADO" },
        { value: "1", text: "CORREO ELECTRONICO" },
        { value: "7", text: "NOTIFICACIÓN PERSONAL" },
      ],
    };
  },
  watch: {
    "form.observacionradicado": function (newVal) {
      // Reemplaza el carácter "&" por una cadena vacía
      this.form.observacionradicado = newVal
        .replace(/&/g, "")
        .replace(/'/g, "");
    },
  },
  methods: {
    enviar() {
      this.arryaJson = [];
      this.nombrefiles = []; // nueva-----------------------------------------------------------

      this.form.desciudad = this.ciudadcompleta.text; //CAMBIO
      this.form.ciudad = this.ciudadcompleta.value; //CAMBIO
      this.form.desciudadapoderado = this.ciudadapoderado.text; //CAMBIO
      this.form.ciudadapoderado = this.ciudadapoderado.value; //CAMBIO

      if (this.form.selectedtipoident === "25") {
        this.form.selectedtipoident = "26";
        console.log("this.form.selectedtipoident", this.form.selectedtipoident);
      }

      if (this.form.selectedtipoidentapo === "25") {
        this.form.selectedtipoidentapo = "26";
        console.log(
          "this.form.selectedtipoidentapo",
          this.form.selectedtipoidentapo
        );
      }

      if (this.file1.length > 0) {
        this.file1.forEach((file) => {
          this.nombrefiles.push(file.name);
        });
      }
      this.form.filesname = this.nombrefiles.toString();

      const axiosInstance = axios.create({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      if (
        this.validacioncampos(this.form) === true &&
        this.validacioncamposapo(this.form) === true
      ) {
        this.form2 = Object.assign({}, this.form, this.form1);
        //console.log('this.form2', this.form2);

        this.arryaJson.push(this.form, this.form1, this.form2);

        if (Array.isArray(this.arryaJson)) {
          // Recorre el arryaJson y aplica la función a todas las propiedades de todos los objetos en la matriz
          this.arryaJson.forEach((obj) => {
            for (const prop in obj) {
              if (typeof obj[prop] === "string") {
                obj[prop] = this.replaceSpecialCharacters(obj[prop]);
              }
            }
          });
        } else {
          console.error("arryaJson no es una matriz o está indefinida");
        }

        this.loading = true;
        this.show = false;
        this.pesofile1 = 0; // nueva----------------------------------------------------------------
        for (var i = 0; i < this.file1.length; i++) {
          this.pesofile1 += this.file1[i].size;
        }
        ///console.log('this.pesofile1 ',this.pesofile1)

        // VALIDO SI CARGARON ARCHIVOS ADJUNTOS ----------------->

        if (this.file1.length > 0) {
          if (this.validarpesofile(this.file1) === true) {
            //console.log("valida archivos", this.validarpesofile(this.file1));
            // VALIDO SI TODOS LOS ARCHIVOS ADJUNTOS SUPERAN LOS 50 MB ----------------->
            if (this.pesofile1 > 50000000) {
              swal(
                "El peso total de los archivos adjuntos supera los 50 Mb permitidos"
              );
              this.loading = false;
              this.show = true;
              //console.log('tamaño de los archivos -------- ',this.pesofile1)
              this.pesofile1 = 0;
            }  else if (this.pesofile1 < 1024) {
              swal(
                "El peso de uno o más archivos adjuntos es menor a 1 KB, lo cual no es permitido."
              );
              this.loading = false;
              this.show = true;
              this.pesofile1 = 0;
              console.log('tamaño de los archivos -------- ',this.pesofile1)
            } else {
              // SE ENVIA LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
              axiosInstance
                .post(rutaenviosolicitud, this.arryaJson)
                .then((response) => {
                  //let blob = this.b64toBlob(response.data.pdf);
                  //console.log("adjuntos blob ", blob);

                  //this.file1.push(blob);

                  //console.log(response.data);
                  //console.log("adjuntos ", this.file1.length);
                  if (this.file1.length > 0) {
                    this.contadorarchivos = this.file1.length;
                    this.filesValide = []; /////////////7nuevo

                    console.log("cantidad de archivos ", this.contadorarchivos);
                    this.file1.forEach((file) => {
                      /////////////7nuevo
                      this.filesValide.push({
                        name: file.name,
                        value: 0,
                        color: true,
                      });

                      const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                      const formData = new FormData();
                      var regex = /([^-\w.]*)/gm;
                      //console.log("nombre archivo", file.name);
                      var arcPDF2 = file.name.replace(regex, "");
                      arcPDF2 = arcPDF2.replaceAll(" ", "");
                      //console.log("nombre nuevo ", arcPDF2);

                      var blob = file.slice(0, file.size, file.type);
                      var newFile = new File([blob], arcPDF2, {
                        type: file.type,
                      });

                      formData.append("file", newFile);
                      formData.append("fileName", arcPDF2);
                      formData.append("radicado", response.data.radicado);
                      formData.append("usuario", response.data.usuario);
                      formData.append("ordenTrabajo", response.data.orden);
                      formData.append("idCorreo", response.data.correo);

                      // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                      axios
                        .post(rutafile, formData, {
                          /// envio archivos
                          headers: { "content-type": "multipart/form-data" },
                          maxRedirects: 0,
                        })
                        .then((respon) => {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          if (respon.status === 200) {
                            this.contadorresponse++;
                          } else {
                            ///////////77nuevo
                            this.filesValide[keyInd].color = false;
                          } ///////////77nuevo

                          this.resetForm();
                          //console.log("Respuesta envio adjuntos", respon);

                          if (this.contadorarchivos === this.contadorresponse) {
                            this.loading = false;
                            swal({
                              closeOnClickOutside: false,
                              text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                          }

                          this.show = true;
                        })
                        .catch(function (error) {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          this.filesValide[keyInd].color = false; ///////////77nuevo
                          if (
                            error.response.status === 500 ||
                            error.response.status === 404
                          ) {
                            swal({
                              closeOnClickOutside: false,
                              text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                            this.resetForm();
                            console.log("error 2022 ", error.response.status);
                          }
                          this.show = true;
                        });
                    });
                  } else {
                    this.resetForm();
                    swal({
                      closeOnClickOutside: false,
                      text: `...Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                    this.loading = false;
                    this.show = true;
                  }
                })
                .catch(function (error) {
                  if (error.response.status === 500) {
                    swal({
                      closeOnClickOutside: false,
                      text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                  }
                  this.loading = false;
                  this.show = true;
                });
            }
          } else {
            swal("El peso de uno o mas archivos supera los 10 Mb permitidos");
            this.loading = false;
            this.show = true;
          }
        } else {
          // CUANDO NO CARGAR ARCHIVOS SE ENVIA SOLO LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
          axiosInstance
            .post(rutaenviosolicitud, this.arryaJson)
            .then((response) => {
              //let blob = this.b64toBlob(response.data.pdf);
              //console.log("adjuntos blob ", blob);

              //this.file1.push(blob);

              //console.log(response.data);
              //console.log("adjuntos ", this.file1.length);

              this.resetForm();
              swal({
                closeOnClickOutside: false,
                text: `.,.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
              }).then((value) => {
                if (value) {
                  window.location.href = "https://cvc.gov.co/";
                }
              });
              this.loading = false;
              this.show = true;
            })
            .catch(function (error) {
              if (error.response.status === 500) {
                swal({
                  closeOnClickOutside: false,
                  text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                }).then((value) => {
                  if (value) {
                    window.location.href = "https://cvc.gov.co/";
                  }
                });
              }
              this.loading = false;
              this.show = true;
            });
        }
      } else {
        console.log("fallo");
      }
    },
    validarpesofile(files) {
      var count = 0;
      //console.log(files.length);
      files.forEach((file) => {
        if (file.size > 10000000) {
          count++;
        }
      });
      //console.log("archivos con peso de mas de 10 mb", count);
      if (count == 0) {
        return true;
      } else {
        return false;
      }
    },
    b64toBlob(b64Data) {
      const names = "formulario.pdf";
      const newbase64 = "data:application/pdf;base64," + b64Data;

      let arr = newbase64.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = window.atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      let file_created = new File([u8arr], names, { type: mime });

      /*var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";

      var blob = new Blob([file_created], { type: "application/pdf" }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "fileName.pdf";
      //a.click();*/

      return file_created;
    },
    resetForm() {
      this.form1.firma = "";
      (this.form = {
        tipotramite: "",
        mediorecepcion: "4",
        observacionradicado: "",
        asunto: "",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram: "", //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        expe: "",
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: "",
        selectedrepre: "",
        selectedtipoperapo: "",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        nombrecentropoblado: "",
      }),
        (this.form1 = {
          actividadeco: "",
          CodigoCIIU: "",
          costototal: "",
          deparfuente: "",
          munifuente: "",
          direccionprediofuente: "",
          fuenteabastecimiento: "",
          grado1: "",
          grado2: "",
          grado3: "",
          munitos1: "",
          munitos2: "",
          munitos3: "",
          segundos1: "",
          segundos2: "",
          segundos3: "",
          grados1l: "",
          grados2l: "",
          grados3l: "",
          minutos1l: "",
          minutos2l: "",
          minutos3l: "",
          segundos1l: "",
          segundos2l: "",
          segundos3l: "",
          altitud1l: "",
          altitud2l: "",
          altitud3l: "",
          usosueloactual: "",
          usosuelopotencial: "",
          selectedtipoverti: "",
          selectedcaptacion: "",
          tiempodescargahora: "",
          tiempodescargadias: "",
          tiempodescargacuadal: "",
          selectedtipoflujo: "",
          areavertimiento: "",
          observa: "",
          firma: "",
          aceptacion: "",
        });
    },
    validarNumero() {
      this.form.identificacion = this.form.identificacion.replace(
        /[^0-9]/g,
        ""
      ); // Elimina cualquier carácter que no sea un número
      this.form.identificacionapode = this.form.identificacionapode.replace(
        /[^0-9]/g,
        ""
      );
    },
    validacioncampos() {
      if (this.form.selectedtipoper === "") {
        swal("Debe seleccionar el tipo de persona");
      } else if (
        this.form.selectedtipoper === "2" &&
        this.form.selectedtipoperjuridica === ""
      ) {
        swal("Debe seleccionar el tipo de persona juridica");
      } else if (this.form.selectedtipotram === "") {
        swal("Debe seleccionar el tipo de tramite");
      } else if (this.form.selectedtipoident === "") {
        swal("Debe seleccionar el tipo de identificación");
      } else if (
        (this.form.selectedtipoident === "26" ||
          this.form.selectedtipoident === "1") &&
        this.form.nombre === ""
      ) {
        swal("Debe ingresar un nombre o razón social");
      } else if (
        (this.form.selectedtipoident === "10" ||
          this.form.selectedtipoident === "36" ||
          this.form.selectedtipoident === "11") &&
        (this.form.nombre1 === "" || this.form.nombre3 === "")
      ) {
        swal("Debe ingresar un nombre y un apellido");
      } else if (this.form.identificacion === "") {
        swal("Debe ingresar la identificación");
      } else if (
        (this.form.selectedtipoident === "26" ||
          this.form.selectedtipoident === "1") &&
        this.form.digito === ""
      ) {
        swal("Debe ingresar el digito de verificación");
      } else if (this.form.dircorrespondencia === "") {
        swal("Debe ingresar la dirección de correspondencia");
      } else if (this.form.depart === "") {
        swal("Debe seleccionar el departamento de correspondencia");
      } else if (this.ciudadcompleta.value == undefined) {
        swal("Debe seleccionar la ciudad de correspondencia");
      } else if (this.form.selectedcorreo === "") {
        swal("Debe seleccionar si o no autoriza la notificación por correo");
      } else if (this.form.selectedcorreo === "1" && this.form.correo === "") {
        swal("Por favor ingresar un correo");
      } else if (
        this.form.selectedcorreo === "1" &&
        this.validEmail(this.form.correo) === false
      ) {
        swal("Por favor ingresar un correo valido");
      } else if (
        this.form.selectedcorreo === "2" &&
        this.form.correodirnoti === ""
      ) {
        swal("Por favor ingresar una dirección de notificación");
      } else if (this.form.observacionradicado === "") {
        swal("Debe ingresar una breve descripción de la solicitud");
      } else if (this.form.direccionpredio === "") {
        swal(
          "Debe ingresar la dirección del predio para el cual esta haciendo la solicitud"
        );
      } else if (this.form.departpredio === "") {
        swal(
          "Debe seleccionar el departamento del predio para el cual esta haciendo la solicitud"
        );
      } else if (this.form.ciudadpredio === "") {
        swal(
          "Debe seleccionar el municipio del predio para el cual esta haciendo la solicitud"
        );
      } else if (this.form1.aceptacion === "") {
        swal(
          "Debe aceptar el uso de mis datos personales, tratamiento, protección"
        );
      } else {
        return true;
      }
    },
    validacioncamposapo() {
      if (
        this.form.selectedtipoperapo !== "" &&
        this.form.selectedtipoidentapo === ""
      ) {
        swal("Debe seleccionar el tipo de identificación del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.selectedtipoperapo === "2" &&
        this.form.selectedtipoperjuridica === ""
      ) {
        swal("Debe seleccionar el tipo de persona juridica del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "26") ||
          this.form.selectedtipoidentapo === "1") &&
        this.form.nombreapoderado === ""
      ) {
        swal("Debe ingresar un nombre o razón social del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "10") ||
          this.form.selectedtipoidentapo === "36" ||
          this.form.selectedtipoidentapo === "11") &&
        (this.form.nombre1apo === "" || this.form.nombre3apo === "")
      ) {
        swal("Debe ingresar un nombre y un apellido del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.identificacionapode === ""
      ) {
        swal("Debe ingresar la identificación del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "26") ||
          this.form.selectedtipoidentapo === "1") &&
        this.form.digitoapo === ""
      ) {
        swal("Debe ingresar el digito de verificación del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.dirapoderado === ""
      ) {
        swal("Debe ingresar la dirección de correspondencia del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.departapoderado === ""
      ) {
        swal(
          "Debe seleccionar el departamento de correspondencia del representante"
        );
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.ciudadapoderado.value == undefined
      ) {
        swal("Debe seleccionar la ciudad de correspondencia del representante");
      } else if (
        this.form.correoapoderado !== "" &&
        this.validEmail(this.form.correoapoderado) === false
      ) {
        swal("Por favor ingresar un correo valido del representante");
      } else {
        return true;
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(email);
    },
    onChangeCiudad() {
      if (this.form.depart !== "") {
        this.optionsciudades = [];

        this.$http
          .post(rutaciudad + `${this.form.depart}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudades.push(objeto);
            }
            this.showciudad = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeCiudadapoderado() {
      //CAMBIO
      if (this.form.departapoderado !== "") {
        this.optionsciudadesapo = [];

        this.$http
          .post(rutaciudad + `${this.form.departapoderado}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudadesapo.push(objeto);
            }
            this.showciudadapo = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeRadioButton() {
      // Hacer algo basado en el valor seleccionado
      if (this.form.selectedtipoper === "1") {
        // Se seleccionó "Natural"
        console.log("Se seleccionó Natural");
        this.form.selectedtipoident = "";
        this.form.selectedtipoperjuridica = "";
        // Ejecuta tu código aquí
      } else if (this.form.selectedtipoper === "2") {
        // Se seleccionó "Jurídica"
        this.form.selectedtipoident = "";
        console.log("Se seleccionó Jurídica");
        // Ejecuta tu código aquí
      }
    },
    onChangeRadioButtonApo() {
      // Hacer algo basado en el valor seleccionado
      if (this.form.selectedtipoperapo === "1") {
        // Se seleccionó "Natural"
        console.log("Se seleccionó Natural");
        this.form.selectedtipoidentapo = "";
        this.form.selectedtipoperjuridicaapo = "";
        // Ejecuta tu código aquí
      } else if (this.form.selectedtipoperapo === "2") {
        // Se seleccionó "Jurídica"
        this.form.selectedtipoidentapo = "";
        console.log("Se seleccionó Jurídica");
        // Ejecuta tu código aquí
      }
    },
    replaceSpecialCharacters(value) {
      if (typeof value === "string") {
        // Reemplaza el carácter "&" y comillas simples por una cadena vacía
        return value.replace(/&/g, "").replace(/'/g, "");
      } else {
        return value; // Si no es una cadena, devuelve el valor sin cambios
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

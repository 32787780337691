<template>
  <div>
    <div class="container">
      <p style="font-weight: bold; text-align: center">
        FORMULARIO ÚNICO NACIONAL DE SOLICITUD DE APROVECHAMIENTO FORESTAL Y
        MANEJO SOSTENIBLE DE FLORA SILVESTRE Y LOS PRODUCTOS FORESTALES NO
        MADERABLES NUEVO/PRÓRROGA
      </p>
      <p style="font-weight: bold; text-align: center">
        Base legal: Ley 99 de 1993, Decreto 2811 de 1974, Decreto 1076 de 2015,
        Resolución 1740 de 2016, Decreto 2106 de 2019, Decreto 690 de 2021
      </p>
    </div>
    <div class="container">
      <p style="text-align: justify">
        Señor(a) usuario (a), los campos que tienen el * son obligatorios para
        radicar su solicitud. Le recomendamos diligenciar la mayoría o totalidad
        de la información solicitada, así como adjuntar los documentos descritos
        en las instrucciones de diligenciamiento de este formulario.
      </p>
    </div>
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="text-right my-3">
        <b-button
          v-b-tooltip.hover
          title="Limpiar campos"
          variant="success"
          @click="resetForm()"
        >
          <b-icon icon="trash-fill"></b-icon>
        </b-button>
      </div>
      <div class="border border-dark row" style="background: #aab7b8">
        <label style="font-weight: bold">DATOS BÁSICOS DE LA SOLICITUD:</label>
      </div>
      <div class="row" style="text-align: left; background: #abebc6">
        <label style="font-weight: bold">1. Datos del interesado</label>
      </div>
      <label style="font-weight: bold">1.1. Tipo de Solicitud:</label>
      <div class="row">
        <div class="col-md-6">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipotram"
              name="selectedtipotram"
            >
              <b-form-radio :value="Porriesgoapteaa">Nuevo </b-form-radio>
              <b-form-radio value="7">Prórroga </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <label style="font-weight: bold"
        >1.2. Identificación del interesado</label
      >
      <div class="row">
        <div class="col-md-6">
          <b-form-group label="*Tipo de persona:" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoper"
              name="selectedtipoper"
              @change="onChangeRadioButton()"
            >
              <b-form-radio value="1">Natural </b-form-radio>
              <b-form-radio value="2">Jurídica</b-form-radio>
            </b-form-radio-group>
            <b-form-radio-group
              v-if="form.selectedtipoper == 2"
              id="radio-slots"
              v-model="form.selectedtipoperjuridica"
              name="selectedtipoperjuridica"
            >
              <b-form-radio value="1">Jurídica pública </b-form-radio>
              <b-form-radio value="2">Jurídica privada </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            label="*Tipo de identificación:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoident"
              name="selectedtipoident"
            >
              <b-form-radio value="10" v-if="form.selectedtipoper == 1"
                >CC
              </b-form-radio>
              <b-form-radio value="26" v-if="form.selectedtipoper == 2"
                >NIT
              </b-form-radio>
              <b-form-radio value="36" v-if="form.selectedtipoper == 1"
                >Pasaporte
              </b-form-radio>
              <b-form-radio value="25" v-if="form.selectedtipoper == 2"
                >Personería jurídica
              </b-form-radio>
              <b-form-radio value="11" v-if="form.selectedtipoper == 1"
                >Cédula de extranjería
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="*Nombre o razón social:"
            style="text-align: left"
          >
            <b-form-group style="text-align: left">
              <label
                style="text-align: left"
                v-if="
                  form.selectedtipoident == 26 || form.selectedtipoident == 25
                "
              >
                *Razón Social</label
              >
              <b-form-input
                v-if="
                  form.selectedtipoident == 26 || form.selectedtipoident == 25
                "
                v-model="form.nombre"
                placeholder="razón social"
                maxlength="180"
              ></b-form-input>
            </b-form-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group>
            <div class="row">
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Nombre</label
                >
                <b-form-input
                  placeholder="Primer Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre1"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Nombre</label
                >
                <b-form-input
                  placeholder="Segundo Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre2"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Apellido</label
                >
                <b-form-input
                  placeholder="Primer Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre3"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Apellido</label
                >
                <b-form-input
                  placeholder="Segundo Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre4"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 26 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 25 ||
                form.selectedtipoident == 11
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacion"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 11
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacion"
                  placeholder="No:"
                  v-if="
                    form.selectedtipoident == 26 || form.selectedtipoident == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digito"
                  placeholder="Dígito verificación:"
                  v-if="
                    form.selectedtipoident == 26 || form.selectedtipoident == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
            <label>*Dirección de correspondencia: </label>
            <b-form-input
              v-model="form.dircorrespondencia"
              placeholder="Dirección de correspondencia"
              maxlength="95"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.depart"
              :options="optionsdepartamento"
              @change="onChangeCiudad()"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Ciudad:" style="text-align: left">
            <b-form-select v-show="showciudad" v-model="ciudadcompleta">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsciudades"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Teléfono (s):" style="text-align: left"
            ><b-form-input
              v-model="form.tel"
              placeholder="Teléfono (s)"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Fax:" style="text-align: left"
            ><b-form-input
              v-model="form.fax"
              placeholder="Fax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correo"
              placeholder="Correo electrónico"
              type="email"
              class="form-control"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="*¿Autoriza la notificación mediante correo electrónico?"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedcorreo"
              name="selectedcorreo"
            >
              <b-form-radio value="1">Si </b-form-radio>
              <b-form-radio value="2">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group
            label="En caso contrario indique la dirección para notificación física:"
            style="text-align: left"
            ><b-form-input
              v-model="form.correodirnoti"
              placeholder="Dirección de notificación"
              maxlength="200"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <label style="font-weight: bold">1.3. Apoderado (Si aplica)</label>
      <div class="row">
        <div class="col-md-6">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedrepre"
              name="selectedrepre"
            >
              <b-form-radio value="1">Legal</b-form-radio>
              <b-form-radio value="2">ó Apoderado</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <!--<div class="col-md-6">
          <b-form-group label="1. Tipo de persona:" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoperapo"
              name="selectedtipoperapo"
              @change="onChangeRadioButtonApo()"
            >
              <b-form-radio value="1">Natural </b-form-radio>
              <b-form-radio value="2">Jurídica</b-form-radio>
            </b-form-radio-group>
            <b-form-radio-group
              v-if="form.selectedtipoperapo == 2"
              id="radio-slots"
              v-model="form.selectedtipoperjuridicaapo"
              name="selectedtipoperjuridicaapo"
            >
              <b-form-radio value="1">Jurídica pública </b-form-radio>
              <b-form-radio value="2">Jurídica privada </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>-->

        <div class="col-md-6">
          <b-form-group
            label="Tipo de identificación:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoidentapo"
              name="selectedtipoidentapo"
            >
              <b-form-radio value="10" v-if="form.selectedtipoperapo == 1"
                >CC
              </b-form-radio>
              <b-form-radio value="26" v-if="form.selectedtipoperapo == 2"
                >NIT
              </b-form-radio>
              <b-form-radio value="36" v-if="form.selectedtipoperapo == 1"
                >Pasaporte
              </b-form-radio>
              <b-form-radio value="25" v-if="form.selectedtipoperapo == 2"
                >Personería jurídica
              </b-form-radio>
              <b-form-radio value="11" v-if="form.selectedtipoperapo == 1"
                >Cédula de extranjería
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <!------------------------------------------------------------------------------------->
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              style="text-align: left"
              v-if="
                form.selectedtipoidentapo == 26 ||
                form.selectedtipoidentapo == 25
              "
            >
              Nombre</label
            >
            <b-form-input
              v-if="
                form.selectedtipoidentapo == 26 ||
                form.selectedtipoidentapo == 25
              "
              v-model="form.nombreapoderado"
              placeholder="Nombre"
              maxlength="180"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group>
            <div class="row">
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  >*Primer Nombre</label
                >
                <b-form-input
                  placeholder="Primer Nombre"
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  v-model="form.nombre1apo"
                  maxlength="180"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  >Segundo Nombre</label
                >
                <b-form-input
                  placeholder="Segundo Nombre"
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  v-model="form.nombre2apo"
                  maxlength="180"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  >*Primer Apellido</label
                >
                <b-form-input
                  placeholder="Primer Apellido"
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  v-model="form.nombre3apo"
                  maxlength="180"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  >Segundo Apellido</label
                >
                <b-form-input
                  placeholder="Segundo Apellido"
                  v-if="
                    form.selectedtipoidentapo == 10 ||
                    form.selectedtipoidentapo == 36 ||
                    form.selectedtipoidentapo == 11
                  "
                  v-model="form.nombre4apo"
                  maxlength="180"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoidentapo == 10 ||
                form.selectedtipoidentapo == 26 ||
                form.selectedtipoidentapo == 36 ||
                form.selectedtipoidentapo == 25 ||
                form.selectedtipoidentapo == 11
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacionapode"
              placeholder="No:"
              v-if="
                form.selectedtipoidentapo == 10 ||
                form.selectedtipoidentapo == 36 ||
                form.selectedtipoidentapo == 11
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacionapode"
                  placeholder="No:"
                  v-if="
                    form.selectedtipoidentapo == 26 ||
                    form.selectedtipoidentapo == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digitoapo"
                  placeholder="Dígito verificación:"
                  v-if="
                    form.selectedtipoidentapo == 26 ||
                    form.selectedtipoidentapo == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <!------------------------------------------------------------------------------------->

        <div class="col-md-6">
          <b-form-group label="De:" style="text-align: left"
            ><b-form-input
              v-model="form.lugaridapoderado"
              placeholder="Lugar de expedición de la identificación"
              maxlength="30"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="Dirección de correspondencia:"
            style="text-align: left"
            ><b-form-input
              v-model="form.dirapoderado"
              placeholder="Dirección de correspondencia"
              maxlength="95"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departapoderado"
              :options="optionsdepartamento"
              @change="onChangeCiudadapoderado()"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Ciudad:" style="text-align: left">
            <b-form-select v-show="showciudadapo" v-model="ciudadapoderado"
              ><b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsciudadesapo"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option></b-form-select
            >
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Teléfono (s):" style="text-align: left"
            ><b-form-input
              v-model="form.telapoderado"
              placeholder="Teléfono (s)"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Fax:" style="text-align: left"
            ><b-form-input
              v-model="form.faxapoderado"
              placeholder="Fax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correoapoderado"
              placeholder="Correo electrónico"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <label style="font-weight: bold"
        >1.4. Calidad en que actúa sobre el predio donde se realizará el
        aprovechamiento o manejo sostenible. (Ver instructivo):</label
      >
      <div class="row">
        <div class="col-md-12">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedcalidadactua"
              name="selectedcalidadactua"
            >
              <b-form-radio value="1">Propietario </b-form-radio>
              <b-form-radio value="2">Poseedor </b-form-radio>
              <b-form-radio value="3">Tenedor </b-form-radio>
              <b-form-radio value="4">Ocupante </b-form-radio>
              <b-form-radio value="5">Autorizado </b-form-radio>
              <b-form-radio value="6">Ente territorial </b-form-radio>
              <b-form-radio value="7">Consejo comunitario </b-form-radio>
              <b-form-radio value="8">Resguardo indígena </b-form-radio>
              <b-form-radio value="9">Otro </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="especifique cuál: " style="text-align: left"
            ><b-form-input
              v-model="form1.Otroespecifique"
              placeholder="especifique cuál: "
              maxlength="200"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <label style="font-weight: bold"
        >1.5. Información del predio objeto de la solicitud:</label
      >
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="Tipo de predio(s):" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedtipopredio"
              name="selectedtipopredio"
            >
              <b-form-radio value="1">Público</b-form-radio>
              <b-form-radio value="2">Colectivo </b-form-radio>
              <b-form-radio value="3">Privado </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <label style="font-weight: bold"
        >1.6. Costo del Proyecto, Obra o Actividad (Si aplica)</label
      >
      <div class="row">
        <div class="col-md-6">
          <b-form-group label="$ " style="text-align: left"
            ><b-form-input
              v-model="form1.costopro"
              maxlength="50"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Valor en letras: " style="text-align: left"
            ><b-form-input
              v-model="form1.valorletras"
              placeholder="Valor en letras: "
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <!--------------------------cierre row finaliza el primer bloque de informacion ------------->
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row" style="text-align: left; background: #abebc6">
        <label style="font-weight: bold"
          >2. Si es una prórroga de un aprovechamiento o manejo sostenible
          aprobado</label
        >
      </div>
      <div class="row">
        <div class="col-md-12">
          <label>Indique el número del expediente:</label>
          <b-form-input
            v-model="form1.expediente"
            placeholder="Indique el número del expediente:"
            maxlength="30"
          ></b-form-input>
        </div>

        <div class="col-md-12">
          <label
            >Indique el número de acto administrativo mediante el cual se otorgó
            el derecho al uso del recurso forestal (permiso, asociación,
            concesión forestal o autorización)
          </label>
          <b-form-input
            v-model="form1.actoadmin"
            maxlength="100"
          ></b-form-input>
        </div>
        <div class="col-md-12">
          <label style="font-weight: bold"
            >Nota: En caso de prórroga no diligencie los numerales 3, 4 y
            5</label
          >
        </div>
      </div>
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row" style="text-align: left; background: #abebc6">
        <label style="font-weight: bold"
          >3. Descripción detallada de la solicitud</label
        >
      </div>
      <label style="font-weight: bold"
        >3.1. Modo de adquirir el derecho al uso del recurso (ver
        instructivo):</label
      ><br />
      Seleccione de acuerdo con el tipo de solicitud el modo por el cual
      pretende adquirir el derecho al uso del recurso forestal:
      <div class="row">
        <div class="col-md-6">
          <b-form-group
            label="Terrenos de dominio público:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.terreno"
              name="terreno"
            >
              <b-form-radio value="1">Permiso</b-form-radio>
              <b-form-radio value="2">Asociación </b-form-radio>
              <b-form-radio value="3">Concesión Forestal </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group
            label="Predios de propiedad privada o colectiva:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.propiedadprivada"
              name="propiedadprivada"
            >
              <b-form-radio value="1">Autorización</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <label style="font-weight: bold"
        >3.2. Productos forestales objeto de la solicitud de aprovechamiento o
        manejo sostenible (ver instructivo):</label
      ><br />
      De acuerdo con el tipo de solicitud a presentar, marque con una “X” la
      categoría respectiva:
      <div class="row">
        <div class="col-md-6">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.prodforestal"
              name="prodforestal"
            >
              <b-form-radio
                value="1"
                style="text-align: left; font-weight: bold"
                >A. Productos forestales maderables</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group
            label="Seleccione la clase de aprovechamiento a solicitar:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedaprovechamiento"
              name="selectedaprovechamiento"
            >
              <b-form-radio value="1">Persistente</b-form-radio>
              <b-form-radio value="2">Único </b-form-radio>
              <b-form-radio value="3">Doméstico </b-form-radio>
              <b-form-radio value="4">Manejo Forestal Unificado </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.prodforestal"
              name="prodforestal"
            >
              <b-form-radio
                value="2"
                style="text-align: left; font-weight: bold"
                >B. Manejo Sostenible de Flora Silvestre y los Productos
                Forestales No Maderables</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group
            label="Seleccione la clase de manejo sostenible a solicitar:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedclasedemanejo"
              name="selectedclasedemanejo"
            >
              <b-form-radio value="1">Doméstico</b-form-radio>
              <b-form-radio value="2">Persistente </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="col-md-12">
        <label
          >Ingresos mensuales en Salarios Mínimos Legales Mensuales Vigentes –
          (SMLMV) esperados para la actividad comercial que se pretende
          desarrollar:
        </label>
        <b-form-input
          v-model="form1.Ingresosmensuales"
          maxlength="50"
        ></b-form-input>
      </div>
      <div class="col-md-12">
        <label>Valor en letras: </label>
        <b-form-input
          v-model="form1.Valorenletras"
          maxlength="300"
        ></b-form-input>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group
            label="En caso de que se seleccione la clase de manejo sostenible persistente, indique la categoría asociada:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.categoriaasociada"
              name="categoriaasociada"
            >
              <b-form-radio value="1">a. Pequeños</b-form-radio>
              <b-form-radio value="2">b. Medianos </b-form-radio>
              <b-form-radio value="3">c. Grandes </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.prodforestal"
              name="prodforestal"
            >
              <b-form-radio
                value="3"
                style="text-align: left; font-weight: bold"
                >C. Árboles Aislados</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.prodforestal"
              name="prodforestal"
            >
              <b-form-radio
                value="4"
                style="text-align: left; font-weight: bold"
                >D. Guaduales y bambusales</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group
            label="Seleccione el tipo de aprovechamiento a solicitar:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedtipodeaprovechamiento"
              name="selectedtipodeaprovechamiento"
            >
              <b-form-radio value="1">Tipo 1</b-form-radio>
              <b-form-radio value="2">Tipo 2 </b-form-radio>
              <b-form-radio value="3"
                >Cambio definitivo de uso del suelo
              </b-form-radio>
              <b-form-radio value="4">Establecimiento y Manejo </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row" style="text-align: left; background: #abebc6">
        <label style="font-weight: bold"
          >4. Información general del predio</label
        >
      </div>
      <label style="font-weight: bold"
        >4.1. Información del (los) predio(s) a intervenir:</label
      >
      <div class="row">
        <div class="col-md-7">
          <b-form-group label="Nombre del predio:" style="text-align: left"
            ><b-form-input
              v-model="form.nombrepredio"
              placeholder="Nombre del predio"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-5">
          <b-form-group label="Superficie (ha):" style="text-align: left"
            ><b-form-input
              v-model="form1.Superficiepredio"
              placeholder="Superficie (ha):"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-7">
          <b-form-group label="*Dirección del predio:" style="text-align: left"
            ><b-form-input
              v-model="form.direccionpredio"
              placeholder="Dirección del predio"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-5" style="margin-top: auto">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedurbanorural"
              name="selectedurbanorural"
            >
              <b-form-radio value="1">Urbano</b-form-radio>
              <b-form-radio value="2">Rural </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departpredio"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Municipio:" style="text-align: left">
            <b-form-select
              v-model="form.ciudadpredio"
              :options="optionsCiudad"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="Nombre centro poblado, vereda y/o corregimiento:"
            style="text-align: left"
            ><b-form-input
              v-model="form.nombrecentropoblado"
              placeholder="Nombre centro poblado, vereda y/o corregimiento:"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <label>Número(s) de matrícula inmobiliaria: </label>
          <b-form-input
            v-model="form1.inmobiliaria"
            maxlength="300"
          ></b-form-input>
        </div>
        <div class="col-md-6">
          <label
            >Cédula Catastral (solo si no dispone de la matrícula inmobiliaria):
          </label>
          <b-form-input v-model="form1.Catastral" maxlength="30"></b-form-input>
        </div>
        <br />
        <div class="col-md-12">
          <label style="font-weight: bold"
            >Nota. En caso de que el área para el desarrollo del aprovechamiento
            forestal o manejo sostenible comprenda más de dos predios a
            intervenir, el interesado deberá anexar una tabla con la información
            predial adicional de acuerdo a los campos descritos en el presente
            formato.</label
          >
        </div>
      </div>
      <br />
      <label style="font-weight: bold"
        >4.2. Coordenadas del área objeto de la solicitud*:</label
      >
      <div class="row">
        <div class="col-md-12">
          <label style="font-weight: bold"
            >Para los aprovechamientos forestales persistentes y únicos de
            bosque natural, y para el manejo sostenible persistente de la flora
            silvestre y los productos forestales no maderables, se deberá
            señalar:
          </label>
        </div>

        <div class="col-md-12" style="margin-top: auto">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedCoordenadas"
              name="selectedCoordenadas"
            >
              <b-form-radio value="1">Coordenadas planas</b-form-radio>
              <b-form-radio value="2">Coordenadas geográficas</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label style="font-weight: bold"
            >En caso de seleccionar coordenadas planas, las mismas deberán
            corresponder al Origen único CTM-12, conforme a lo establecido en la
            Resolución 471 de 2020, modificada por la Resolución 529 de 2020,
            proferida por el Instituto Geográfico Agustín Codazzi, y para
            coordenadas geográficas utilizar el sistema de coordenadas
            Magna-Sirgas
          </label>
        </div>
        <div class="col-md-12">
          <label>Diligencie el listado de coordenadas según sea el caso:</label>
        </div>
        <div class="col-md-12 border border-dark">
          <b-form-group
            label="Coordenadas Planas"
            style="text-align: center; font-weight: bold"
          />
          <div class="row">
            <div class="col-md-4">
              <b-form-group label="Punto"
                ><b-form-input
                  size="sm"
                  v-model="form1.Punto1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.Punto2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.Punto3"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.Punto4"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.Punto5"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.Punto6"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.Punto7"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.Punto8"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.Punto9"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="X"
                ><b-form-input
                  size="sm"
                  v-model="form1.x1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.x2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.x3"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.x4"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.x5"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.x6"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.x7"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.x8"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.x9"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Y"
                ><b-form-input
                  size="sm"
                  v-model="form1.y1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.y2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.y3"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.y4"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.y5"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.y6"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.y7"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.y8"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.y9"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
        <br />
        <div class="col-md-12 border border-dark">
          <b-form-group
            label="Coordenadas Geográficas"
            style="text-align: center; font-weight: bold"
          />
          <div class="row justify-content-center">
            <div class="col-md-1">
              <b-form-group label="Punto ______"
                ><b-form-input
                  size="sm"
                  v-model="form1.puntocg1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.puntocg2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.puntocg3"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.puntocg4"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.puntocg5"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.puntocg6"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.puntocg7"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.puntocg8"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.puntocg9"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-1">
              <b-form-group label="Latitud Grados"
                ><b-form-input
                  size="sm"
                  v-model="form1.latitudg1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudg2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudg3"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudg4"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudg5"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudg6"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudg7"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudg8"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudg9"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-1">
              <b-form-group label="Latitud Minutos"
                ><b-form-input
                  size="sm"
                  v-model="form1.latitudm1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudm2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudm3"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudm4"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudm5"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudm6"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudm7"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudm8"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latitudm9"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-1">
              <b-form-group label="Latitud Segundos"
                ><b-form-input
                  size="sm"
                  v-model="form1.latituds1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latituds2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latituds3"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latituds4"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latituds5"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latituds6"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latituds7"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latituds8"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.latituds9"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-1">
              <b-form-group label="Longitud Grados"
                ><b-form-input
                  size="sm"
                  v-model="form1.longitudg1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudg2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudg3"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudg4"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudg5"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudg6"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudg7"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudg8"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudg9"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-1">
              <b-form-group label="Longitud Minutos"
                ><b-form-input
                  size="sm"
                  v-model="form1.longitudm1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudm2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudm3"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudm4"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudm5"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudm6"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudm7"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudm8"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longitudm9"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-1">
              <b-form-group label="Longitud Segundos"
                ><b-form-input
                  size="sm"
                  v-model="form1.longituds1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longituds2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longituds3"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longituds4"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longituds5"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longituds6"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longituds7"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longituds8"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.longituds9"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-1">
              <b-form-group label="Altitud (msnm)"
                ><b-form-input
                  size="sm"
                  v-model="form1.altitud1"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.altitud2"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.altitud3"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.altitud4"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.altitud5"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.altitud6"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.altitud7"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.altitud8"
                  maxlength="10"
                ></b-form-input>
                <b-form-input
                  size="sm"
                  v-model="form1.altitud9"
                  maxlength="10"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row justify-content-center">
            <b-row class="my-1">
              <b-col sm="2">
                <label for="input-small">Origen</label>
              </b-col>
              <b-col sm="10">
                <b-form-input v-model="form1.origen"></b-form-input>
              </b-col>
            </b-row>
          </div>
        </div>
        <br />
        <div class="col-md-12">
          <label style="font-weight: bold"
            >Nota. Para los aprovechamientos forestales domésticos de bosque
            natural, de árboles aislados y el manejo sostenible doméstico de la
            flora silvestre y de los productos forestales no maderables, la
            autoridad ambiental competente en la visita, tomará las coordenadas
            planas o geográficas respectivas.
          </label>
        </div>
      </div>
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row" style="text-align: left; background: #abebc6">
        <label style="font-weight: bold"
          >5. Información sobre el aprovechamiento forestal o manejo sostenible
          de la flora silvestre y los productos forestales no maderables</label
        >
      </div>
      <label style="font-weight: bold"
        >5.1. Información de las especies objeto de la solicitud de
        aprovechamiento forestal o manejo sostenible de flora silvestre y los
        productos forestales no maderables y método (Aplica para todas las
        categorías)
      </label>
      <div class="row">
        <div class="col-md-6">
          <b-form-group
            label="Método de aprovechamiento o manejo sostenible: "
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedmanejosostenible"
              name="selectedmanejosostenible"
            >
              <b-form-radio value="1">Mecánico</b-form-radio>
              <b-form-radio value="2">Manual</b-form-radio>
              <b-form-radio value="3">Mecánico-Manual</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label>Especies objeto de la solicitud: </label>
        </div>
      </div>
      <div class="table-responsive">
        <table>
          <thead style="font-size: 9px">
            <tr>
              <th scope="col">Cantidad</th>
              <th scope="col">Unidad de medida *</th>
              <th scope="col">Nombre común</th>
              <th scope="col">Nombre científico</th>
              <th scope="col">
                Aplica para únicamente para manejo sostenible de flora silvestre
                y los productos forestales no maderables
                <tr>
                  <th>
                    Parte aprovechada (Raíz, Fruto, Semilla, Flor, Corteza,
                    Exudado, Yema, Hojas, Tallos, Ramas, etc.)
                  </th>
                  <th>
                    Hábito (Árbol, Arbusto, Hierba terrestre, Epífita,
                    Bejuco/liana, Hemiepífita, Palma, etc.)
                  </th>
                </tr>
              </th>
              <th scope="col">Veda nacional o regional (si aplica) **</th>
              <th scope="col">Categoría de amenaza (si aplica)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col1_1"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col1_2"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col1_3"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col1_4"
                  maxlength="200"
                ></b-form-input>
              </td>
              <td>
                <tr>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col1_5"
                      maxlength="200"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col1_6"
                      maxlength="200"
                    ></b-form-input>
                  </td>
                </tr>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col1_7"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col1_8"
                  maxlength="10"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col2_11"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col2_2"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col2_3"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col2_4"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <tr>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col2_5"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col2_6"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                </tr>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col2_7"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col2_8"
                  maxlength="10"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col3_11"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col3_2"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col3_3"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col3_4"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <tr>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col3_5"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col3_6"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                </tr>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col3_7"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col3_8"
                  maxlength="10"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col4_11"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col4_2"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col4_3"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col4_4"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <tr>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col4_5"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col4_6"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                </tr>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col4_7"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col4_8"
                  maxlength="10"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col5_11"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col5_2"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col5_3"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col5_4"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <tr>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col5_5"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col5_6"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                </tr>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col5_7"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col5_8"
                  maxlength="10"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col6_11"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col6_2"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col6_3"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col6_4"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <tr>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col6_5"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col6_6"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                </tr>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col6_7"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col6_8"
                  maxlength="10"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col7_11"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col7_2"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col7_3"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col7_4"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <tr>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col7_5"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col7_6"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                </tr>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col7_7"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col7_8"
                  maxlength="10"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col8_11"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col8_2"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col8_3"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col8_4"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <tr>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col8_5"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col8_6"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                </tr>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col8_7"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col8_8"
                  maxlength="10"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col9_11"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col9_2"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col9_3"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col9_4"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <tr>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col9_5"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col9_6"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                </tr>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col9_7"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col9_8"
                  maxlength="10"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col10_11"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col10_2"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col10_3"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col10_4"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <tr>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col10_5"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      size="sm"
                      v-model="form1.col10_6"
                      maxlength="10"
                    ></b-form-input>
                  </td>
                </tr>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col10_7"
                  maxlength="10"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  size="sm"
                  v-model="form1.col10_8"
                  maxlength="10"
                ></b-form-input>
              </td>
            </tr>
            <tr>
              <td>Cantidad Total</td>
              <td>
                <b-form-input
                  v-model="form1.totaltabla"
                  maxlength="10"
                ></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <label style="font-weight: bold"
        >* Unidades de medida: Metros cúbicos, toneladas, kilogramos, gramos,
        miligramos, entre otros.<br />
        Nota. Para los aprovechamientos forestales únicos la información sobre
        el volumen corresponde a la establecida por el inventario estadístico.
      </label>
      <div class="col-md-12">
        <label
          ><b
            >Indique el uso que se pretende dar a los productos a obtener:
          </b></label
        >
        <b-form-textarea
          id="textarea"
          v-model="form1.usoproducto"
          placeholder="Indique el uso que se pretende dar a los productos a obtener..."
          rows="3"
          max-rows="6"
          maxlength="2000"
        ></b-form-textarea>
      </div>
      <br />
      <label style="font-weight: bold"
        >5.2. Información asociada a la solicitud de aprovechamiento de árboles
        aislados (Si aplica): </label
      ><br />
      Seleccione la ubicación o tipo de solicitud de los individuos objeto de
      aprovechamiento:
      <div class="row">
        <div class="col-md-12">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.objetodeaprovechamiento"
              name="objetodeaprovechamiento"
            >
              <b-form-radio
                value="1"
                style="text-align: left; font-weight: bold"
                >i. Árboles aislados dentro de la cobertura del bosque
                natural</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      Estado del Individuo:
      <div class="row">
        <div class="col-md-12">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedestadoindividuo"
              name="selectedestadoindividuo"
            >
              <b-form-radio value="1" style="text-align: left"
                >Caído por Causas Naturales</b-form-radio
              >
              <b-form-radio value="2" style="text-align: left"
                >Muerto por Causas Naturales</b-form-radio
              >
              <b-form-radio value="3" style="text-align: left"
                >Razones de Orden Fitosanitario</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label>Especifique cuál: </label>
          <b-form-input
            v-model="form1.especifiquecual"
            placeholder="Especifique cuál: "
            maxlength="500"
          ></b-form-input>
        </div>
        <br /><br />

        <div class="col-md-12">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.objetodeaprovechamiento"
              name="objetodeaprovechamiento"
            >
              <b-form-radio
                value="2"
                style="text-align: left; font-weight: bold"
                >ii. Árboles aislados fuera de la cobertura del bosque
                natural</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.objetodeaprovechamiento"
              name="objetodeaprovechamiento"
            >
              <b-form-radio
                value="3"
                style="text-align: left; font-weight: bold"
                >iii. Tala o poda de emergencia en centros urbanos</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12" style="margin-top: auto">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedtalapoda"
              name="selectedtalapoda"
            >
              <b-form-radio value="1">Tala </b-form-radio>
              <b-form-radio value="2">Poda</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Estado del Individuo: " style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedestadoind"
              name="selectedestadoind"
            >
              <b-form-radio value="1">Caído</b-form-radio>
              <b-form-radio value="2">Muerto</b-form-radio>
              <b-form-radio value="3">Enfermo </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Causa o Perjuicio:  " style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedcausaperjucio"
              name="selectedcausaperjucio"
            >
              <b-form-radio value="1">Estabilidad de Suelos</b-form-radio>
              <b-form-radio value="2">Canal de Agua</b-form-radio>
              <b-form-radio value="3"
                >Obras de Infraestructura/Edificaciones</b-form-radio
              >
              <b-form-radio value="4">Otro (especifique cuál):</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <div class="col-md-12">
            <b-form-input
              v-model="form1.cuasaperjuiciootro"
              placeholder="Especifique cuál: "
              maxlength="500"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-12">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.objetodeaprovechamiento"
              name="objetodeaprovechamiento"
            >
              <b-form-radio
                value="4"
                style="text-align: left; font-weight: bold"
                >iv. Obra pública o privada en centros urbanos</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12" style="margin-top: auto">
          <b-form-group>
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedtalatransplante"
              name="selectedtalatransplante"
            >
              <b-form-radio value="1">Tala </b-form-radio>
              <b-form-radio value="2">Trasplante/Reubicación</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="Actividad dentro de obras de infraestructura: "
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form1.selectedobras"
              name="selectedobras"
            >
              <b-form-radio value="1">Construcción /Realización</b-form-radio>
              <b-form-radio value="2">Remodelación</b-form-radio>
              <b-form-radio value="3">Ampliación </b-form-radio>
              <b-form-radio value="4">Instalación </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <div class="col-md-12">
            <label>Similares * </label>
            <b-form-input
              v-model="form1.Similares"
              placeholder="Especifique cuál: "
              maxlength="500"
            ></b-form-input>
          </div>
          <br /><br />
        </div>
      </div>
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row" style="padding: 5px">
        <div class="col-md-12">
          <b-form-group
            label="FIRMA DEL INTERESADO"
            style="text-align: left; font-weight: bold"
            ><b-form-input v-model="form1.firma" maxlength="50"></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11">
          <b-form-file
            v-model="file1"
            :state="Boolean(file1)"
            placeholder="Adjunta los documentos, peso maximo total archivos 50 Mb, peso maximo por cada archivo 10 Mb"
            drop-placeholder="Drop file here..."
            multiple
            browse-text="Buscar"
          ></b-form-file>
        </div>
        <div class="col-md-1">
          <b-button id="popover-button-variant" href="#" tabindex="0"
            >Ayuda</b-button
          >
          <b-popover
            target="popover-button-variant"
            variant="info"
            triggers="focus"
          >
            <template #title>Tutorial</template>
            De click
            <b-link
              href="../../../document/Guía Rápida Adjuntar documentos Tramites Web.pdf"
              target="blank"
              >aqui</b-link
            >
          </b-popover>
        </div>
      </div>
      <br /><br />
      <b-form-checkbox
        id="aceptacion"
        v-model="form1.aceptacion"
        name="aceptacion"
        value="1"
        unchecked-value="0"
        >Acepto el uso de mis datos personales, tratamiento, protección y
        conservación de estos a CVC de acuerdo a lo establecido en la Ley 1581
        de 2012.
        <a
          href="https://www.cvc.gov.co/politicas_de_seguridad_y_privacidad_de_la_informacion"
          >Ver Política de protección de Datos Personales de la CVC.</a
        > </b-form-checkbox
      ><br />
      <div v-show="show">
        <b-button block variant="success" @click="enviar()"
          >Enviar solicitud</b-button
        >
      </div>
      <br />
      <div class="text-center" v-show="loading">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <div class="text-center">
          <strong>Enviando Solicitud</strong><br />
          <strong
            >IMPORTANTE: Si adjuntó archivos, por favor espere que termine la
            subida y salga un mensaje con el número de radicado. Si abandona el
            proceso, la solicitud quedará incompleta...</strong
          >
        </div>
        <br />

        <div
          :key="ind"
          v-for="(tr, ind) in filesValide"
          class="row ml-8 mb-2 d-flex justify-content-center"
        >
          <b-progress
            :max="100"
            striped
            animated
            :variant="'primary'"
            class="w-75"
          >
            <b-progress-bar :value="tr.value === 0 ? 33 : tr.value">
              <span>
                {{
                  tr.value !== 0
                    ? tr.color
                      ? `Se proceso el archivo '${tr.name} de manera correcta.`
                      : `No se pudo procesar el archivo '${tr.name}'.`
                    : `Se esta procesando el archivo '${tr.name}'`
                }}
                <strong></strong></span
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
      <br />
    </div>
    <div class="border border-dark container">
      <p style="font-weight: bold; text-align: center">
        INSTRUCCIONES DE DILIGENCIAMIENTO
      </p>
      <p style="text-align: justify">
        El presente instructivo le permite tener la información necesaria para
        un buen diligenciamiento de los campos de información integrados al
        Formato Único Nacional de Solicitud de Aprovechamiento Forestal. Lea
        cuidadosamente este instructivo antes de llenar los campos. Recuerde que
        de la información que aporte depende un buen desarrollo de su solicitud.
      </p>
      <b-form-group
        label="DATOS BÁSICOS DE LA SOLICITUD"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        <b>1. Datos del interesado: </b>
      </p>
      <p style="text-align: justify">
        <b>1.1. Tipo de solicitud:</b> De acuerdo con el trámite a realizar,
        indique si la solicitud es nueva u obedece a una solicitud de prórroga
        de un aprovechamiento forestal previamente otorgado.
      </p>
      <p style="text-align: justify">
        <b>1.2. Identificación del interesado: </b> Indique el tipo de
        solicitante, ya sea una persona jurídica o natural. Adicionalmente
        diligencie la información asociada al nombre o razón social, tipo de
        identificación (cédula de ciudadanía (CC), cédula de extranjería (CE),
        pasaporte (PA) o Número de Identificación Tributaría (NIT)) y número de
        identificación.
      </p>
      <p style="text-align: justify">
        <b>1.3. Apoderado (Si aplica):</b> En caso de que el interesado actúe
        por medio de un apoderado, diligenciar la información de identificación
        del mismo e indicar el número de tarjeta profesional.
      </p>
      <p style="text-align: justify">
        <b
          >1.4. Calidad en que actúa sobre el predio donde se realizará el
          aprovechamiento o manejo sostenible:
        </b>
        Indique si el interesado actúa en calidad de
        <b
          >propietario, poseedor, tenedor, ocupante, Autorizado, Ente
          Territorial, Consejo Comunitario, Resguardo Indígena u Otro,</b
        >
        del predio objeto de la solicitud.
      </p>
      <p style="text-align: justify">
        <b>1.5. Información del predio objeto de la solicitud: </b> Indique si
        el predio objeto de la solicitud de aprovechamiento o manejo sostenible
        es de dominio <b>Público, Colectivo o Privado.</b>
      </p>
      <p style="text-align: justify">
        <b>1.6. Costo del proyecto, obra o actividad:</b> Diligencie la
        información asociada al costo del proyecto, obra o actividad en números
        y en letras. Es necesario indicar que la información del costo del
        proyecto hace alusión a la totalidad del proyecto, obra o actividad que
        requiere para su ejecución el uso o aprovechamiento de recursos
        naturales.
      </p>

      <b-form-group
        label="2. Si es una prórroga de un aprovechamiento o manejo sostenible aprobado"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        Si su solicitud obedece a una prórroga de un permiso, autorización,
        asociación o concesión forestal previamente otorgada, indique el número
        del expediente y del acto administrativo mediante el cual se otorgó el
        derecho al uso del recurso forestal.
      </p>
      <p style="text-align: justify">
        <b
          >En caso de prórroga no diligencie los numerales 3, 4 y 5, debido a
          que esta información fue diligenciada en la solicitud inicial.</b
        >
      </p>

      <b-form-group
        label="3. Descripción detallada de la solicitud"
        style="text-align: left; font-weight: bold"
      ></b-form-group>

      <p style="text-align: justify">
        <b>3.1. Modo de adquirir el derecho al uso del recurso:</b>
        Seleccione el modo por el cual pretende adquirir el derecho al uso del
        recurso forestal seleccionando según sea el caso:
      </p>
      <p>
        Para terrenos de dominio público aplica el
        <b>Permiso, Asociación o Concesión Forestal.</b>
      </p>
      <p>
        Para terrenos de propiedad privada o colectiva aplica la<b>
          Autorización</b
        >
      </p>
      <p style="text-align: justify">
        <b
          >• Para el caso del aprovechamiento de productos forestales
          maderables.</b
        >
        El modo de adquirir el derecho al uso del recurso forestal se divide
        así:<br />
        <b>Aprovechamiento forestal persistente:</b> concesión, asociación o
        permiso (Artículo 2.2.1.1.4.2. del Decreto 1076 de 2015).<br />
        <b>Aprovechamiento forestal único:</b> Los aprovechamientos forestales
        únicos de bosque naturales ubicados en terrenos de dominio público se
        adquieren mediante permiso (Artículo 2.2.1.1.5.3 del Decreto 1076 de
        2015).<br />
        Los aprovechamientos forestales únicos de bosques naturales ubicados en
        terrenos de dominio privado se adquieren mediante autorización (Artículo
        2.2.1.1.5.6 del Decreto 1076 de 2015).<br />
        <b>Aprovechamiento forestal doméstico:</b> Los aprovechamientos
        forestales domésticos de bosques naturales ubicados en terrenos de
        dominio público se adquieren mediante permiso (Artículo 2.2.1.1.6.1. del
        Decreto 1076 de 2015). Los aprovechamientos forestales domésticos de
        bosques naturales ubicados en terrenos de dominio privado, se adquieren
        mediante autorización (Artículo 2.2.1.1.6.3. del Decreto 1076 de
        2015).<br />
        <b
          >• Para el caso del Manejo Sostenible de Flora Silvestre de los
          Productos Forestales No Maderables.</b
        >
        El modo de adquirir el derecho al uso del recurso forestal se divide en
        Permiso, Autorización, Asociación o Concesión Forestal (Artículo
        2.2.1.1.10.2.1 del Decreto 1076 de 2015).<br />
        <b>• Para el caso del aprovechamiento de árboles aislados.</b> El modo
        de adquirir el derecho al uso del recurso forestal se divide en Permiso
        o Autorización (Artículos 2.2.1.1.9.1., 2.2.1.1.9.3., 2.2.1.1.9.4., del
        Decreto 1076 de 2015).<br />
        <b>• Para el caso del aprovechamiento de guaduales y bambusales.</b>
        Cuando el guadual y/o bambusal se encuentre ubicado en predio de
        propiedad privada, la autoridad ambiental competente otorgará el
        aprovechamiento mediante autorización y en terrenos de dominio público,
        mediante permiso (Parágrafo 1 del artículo 5 de la Resolución 1740 de
        2016).<br />
      </p>
      <p style="text-align: justify">
        <b
          >3.2. Productos forestales objeto de la solicitud de aprovechamiento o
          manejo sostenible:</b
        >: Marque con una X el tipo de recurso objeto de la solicitud de
        aprovechamiento o manejo sostenible, es decir, si la solicitud obedece
        a:<br />
        <b>A. Productos forestales maderables.</b> Seleccione el tipo de
        aprovechamiento a solicitar
        <b>Persistente, Único y Doméstico o Manejo Forestal Unificado.</b>
        (Artículos 2.2.1.1.3.1. y 2.2.1.1.10.4.3. del Decreto 1076 de 2015).<br />
        <b>Nota:</b> El interesado en llevar a cabo aprovechamientos domésticos,
        persistentes o únicos de productos forestales maderables, podrá incluir
        en la solicitud de aprovechamiento forestal de maderables, el manejo
        sostenible de la flora silvestre y de los productos forestales no
        maderables para la misma área o predio objeto de interés, a fin de dar
        un manejo integral al bosque natural. (Artículo 2.2.1.1.10.4.3. del
        Decreto 1076 de 2015).<br />
        <b
          >B. Manejo Sostenible de Flora Silvestre de los Productos Forestales
          No Maderables:</b
        >
        Seleccione la clase de manejo sostenible a solicitar,
        <b>Doméstico o Persistente.</b> (Artículo 2.2.1.1.10.3.2. del Decreto
        1076 de 2015).<br />
        Indique los ingresos mensuales en Salarios Mínimos Legales Mensuales
        Vigentes – (SMLMV) esperados para la actividad comercial que se pretende
        desarrollar (Valor en números y en letras). <br />
        En caso de que se seleccione la clase de manejo sostenible persistente,
        indique la categoría según corresponda: <br />
        <b>a. Pequeños:</b> Aquellos cuyos ingresos mensuales esperados por la
        actividad comercial sea de uno (1) a diez (10) SMLMV.<br />
        <b>b. Medianos:</b> Aquellos cuyos ingresos mensuales esperados por la
        actividad comercial esté entre diez puntos uno (10.1) a treinta (30)
        SMLMV.<br />
        <b>c. Grandes:</b> Aquellos cuyos ingresos mensuales esperados por la
        actividad comercial sea mayor a treinta (30) SMLMV.<br /><br />
        <b>C. Árboles aislados</b> (Libro 2, Parte 2, Título 2, Capítulo 1,
        Sección 9 del Decreto 1076 de 2015).<br />
        <b>D. Guaduales y bambusales:</b> Seleccione el tipo de aprovechamiento
        a solicitar;
        <b
          >Tipo 1, Tipo 2, Cambio definitivo de uso del suelo, Establecimiento y
          Manejo</b
        >
        (Resolución 1740 de 2016).
      </p>

      <b-form-group
        label="4. Información general del predio"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        <b>4.1. Información del (los) predio(s) a intervenir</b> (Artículo
        2.2.1.1. 7.1. del Decreto 1076 de 2015 y Artículo 7 de la Resolución No.
        1740 de 2016):
      </p>

      <p style="text-align: justify">
        <b>Nombre del predio:</b> registre el nombre del predio sobre el cual se
        solicita el aprovechamiento forestal, según lo establece el certificado
        de libertad y tradición.<br />
        <b>Superficie:</b> Indique la extensión del predio a intervenir en
        hectáreas<br />
        <b>Dirección del predio:</b> indique la dirección cuando aplique
        (Distrito, Municipios y suelo suburbano). Mencione si el predio es
        urbano o rural<br />
        <b>Departamento, municipio y vereda:</b> Indique la información asociada
        a la ubicación político administrativa del predio objeto de la
        solicitud.<br />
        <b>Cédula catastral y folio de matrícula inmobiliaria:</b> señale el
        código alfanumérico como se define el predio según los Certificados
        actualizados del Registrador de Instrumentos Públicos y Privados; ya que
        dichos valores identifican el predio en la base de datos municipal y en
        las oficinas de Registros públicos o privados a nivel Nacional.<br />
        <b>Nota.</b> En caso de que el área para el desarrollo del
        aprovechamiento forestal o manejo sostenible comprenda más de dos
        predios a intervenir, el interesado deberá anexar una tabla con la
        información predial adicional de acuerdo a los campos descritos en el
        presente formato.<br />
        <b>Coordenadas del área objeto de la solicitud:</b> Para los
        aprovechamientos forestales persistentes y únicos de bosque natural, y
        para el manejo sostenible persistente de la flora silvestre y los
        productos forestales no maderables, se deberá presentar el listado de
        coordenadas planas o geográficas del predio a intervenir. <br />
        En caso de seleccionar coordenadas planas, las mismas deberán
        corresponder al Origen único CTM-12, conforme a lo establecido en la
        Resolución 471 de 2020, modificada por la Resolución 529 de 2020,
        proferida por el Instituto Geográfico Agustín Codazzi, y para
        coordenadas geográficas utilizar el sistema de coordenadas
        Magna-Sirgas<br />
        <b
          >Nota. Para los aprovechamientos forestales domésticos de bosque
          natural, de árboles aislados y el manejo sostenible doméstico de la
          flora silvestre y de los productos forestales no maderables, la
          autoridad ambiental competente en la visita, tomará las coordenadas
          planas o geográficas respectivas</b
        >
      </p>

      <b-form-group
        label="5. Información sobre el aprovechamiento forestal o manejo sostenible de la flora silvestre y los productos forestales no maderables"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        <b
          >5.1. Información de las especies objeto de la solicitud de
          aprovechamiento o manejo sostenible y método</b
        >
        (Aplica para todas las categorías): (Artículo 2.2.1.1. 7.1. del Decreto
        1076 de 2015 y artículo 7 de la Resolución 1740 de 2016)
      </p>
      <p style="text-align: justify">
        <b>Método de aprovechamiento o manejo sostenible:</b> indicar si el
        aprovechamiento se realizará de forma
        <b> manual, mecánico o mecánico-manual</b>
      </p>

      <p style="text-align: justify">
        <b
          >5.2. Información asociada a la solicitud de aprovechamiento de
          árboles aislados:</b
        >
        Seleccione la ubicación o tipo de solicitud de los individuos objeto de
        aprovechamiento. (Parte 2, Título 2, Capítulo 1, Sección 9 del Decreto
        1076 de 2015)<br />
        <b>i. Árboles aislados dentro de la cobertura del bosque natural:</b>
        Indique el estado del individuo (Caído o Muerto por causas naturales o
        las razones de orden fitosanitario que origina la solicitud
        prioritaria).<br />
        <b>ii. Árboles aislados fuera de la cobertura del bosque natural:</b>
        Aplica cuando la solicitud obedezca a individuos que resulten de
        regeneración natural, árboles plantados o establecidos y que no son
        parte de una cobertura de bosque natural o cultivo forestal con fines
        comerciales.<br />
        <b
          >iii. Tala o poda de emergencia en centros urbanos (Artículo
          2.2.1.1.9.3 Decreto 1076 de 2015):</b
        >
        Indique si se solicita Tala o Poda, el estado fitosanitario del
        individuo (Caído, Muerto o Enfermo) y la causa o perjuicio (Canal de
        Agua, Obras de Infraestructura/Edificaciones u Otros)<br />
        <b>iv. Por obra pública o privada en centros urbanos:</b> Indique si se
        solicita Tala o Trasplante/Reubicación, y finalmente indique la obra a
        realizar (Construcción /Realización, Remodelación, Ampliación,
        Instalación o Similares).<br />
      </p>

      <b-form-group
        label="ANEXOS"
        style="text-align: left; font-weight: bold"
      ></b-form-group>
      <p style="text-align: justify">
        <b>Solicitud de prórroga</b><br /><br />
        <b
          >Documentos generales a adjuntar con la solicitud en físico o medio
          magnético, según sea el caso:</b
        ><br />
        a) Documentos que acrediten la existencia y representación legal del
        solicitante, hasta tanto la Autoridad Ambiental cuente con
        interoperabilidad para hacer las consultas respectivas. (Aplica en los
        casos que se dé cambio de la representación legal)<br />
        b) Poder en caso de actuar con apoderado. (Cuando se sustituya el
        poder)<br />
        c) Documento que exponga las razones técnicas y jurídicas por las cuales
        se requiere la prórroga del permiso, autorización, asociación o
        concesión forestal previamente otorgado (Si Aplica).<br />
      </p>
      <p style="text-align: justify">
        <b>Solicitud nueva</b><br /><br />
        <b
          >Documentos generales a adjuntar con la solicitud en físico o medio
          magnético, según sea el caso:</b
        ><br />
        a) Documentos que acrediten la existencia y representación legal del
        solicitante, hasta tanto la Autoridad Ambiental cuente con
        interoperabilidad para hacer las consultas respectivas.<br />
        b) Certificado de tradición y libertad (Si aplica).<br />
        c) Autorización del poseedor cuando el solicitante sea mero tenedor (Si
        Aplica).<br />
        d) Poseedor en propiedad privada. (Declaración de posesión regular)<br />
        e) Prueba sumaria de la ocupación (Si Aplica).<br />
        f) Resolución de declaratoria o certificado de personería jurídica para
        propiedad colectiva (Si Aplica).<br />
        g) Acta firmada por la Junta Directiva para empresas comunitarias de
        escasos recursos económicos y grupos asociativos (Si Aplica).<br />
        h) Poder en caso de actuar con apoderado.<br />
        i) Tabla de relación de la información predial adicional (Aplica en caso
        de que la solicitud de aprovechamiento forestal o manejo sostenible de
        la flora silvestre y los productos forestales no maderables comprenda 2
        o más predios)<br /><br />
        <b
          >Para el aprovechamiento de productos forestales maderables se deberá
          presentar la siguiente información según sea el caso:</b
        >
      </p>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Documentación</th>
              <th scope="col">Tipo de Aprovechamiento Único</th>
              <th scope="col">Tipo de Aprovechamiento Persistente</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p style="text-align: justify">
                  Resolución de sustracción de reserva forestal
                </p>
              </td>
              <td>Si Aplica</td>
              <td>No Aplica</td>
            </tr>
            <tr>
              <td>
                <p style="text-align: justify">
                  Estudio técnico que demuestre una mejor aptitud de uso del
                  suelo diferente al forestal
                </p>
              </td>
              <td>Si</td>
              <td>No Aplica</td>
            </tr>
            <tr>
              <td>
                <p style="text-align: justify">
                  Plan de aprovechamiento forestal
                </p>
              </td>
              <td>Si</td>
              <td>No Aplica</td>
            </tr>
            <tr>
              <td>
                <p style="text-align: justify">Plan de manejo forestal</p>
              </td>
              <td>No Aplica</td>
              <td>Si</td>
            </tr>
            <tr>
              <td>
                <p style="text-align: justify">
                  **Cartografía donde se incluya la localización del predio, el
                  inventario forestal realizado, sobre las coberturas de la
                  tierra, bajo la metodología Corine Land Cover adaptada para
                  Colombia
                </p>
              </td>
              <td>Si</td>
              <td>Si</td>
            </tr>
            <tr>
              <td>
                <p style="text-align: justify">
                  Propuesta de compensación (Manual de Compensaciones
                  Ambientales del Componente Biótico)
                </p>
              </td>
              <td>Si</td>
              <td>No Aplica</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <b
        >Nota: Para el caso del aprovechamiento forestal doméstico, únicamente
        se deberá allegar el presente formato.</b
      ><br /><br />
      <b
        >Para el Manejo Sostenible de Flora Silvestre y los Productos Forestales
        No Maderables se deberá presentar la siguiente información según sea el
        caso: </b
      ><br />
      a) Estudio técnico<br />
      <b>Nota 1:</b> Si la autoridad ambiental cuenta con el protocolo de manejo
      sostenible de la flora silvestre y de los productos forestales no
      maderables aprobado para la especie de interés, no se requerirá de la
      presentación del estudio técnico.<br />
      <b>Nota 2:</b> El manejo sostenible doméstico no requiere de la
      presentación del estudio técnico.<br />
      Para el aprovechamiento de Guaduales y Bambusales dentro y fuera de
      terrenos de dominio público o propiedad de propiedad privada, se debe
      adjuntar lo siguiente según sea el caso:<br /><br />
      a) Estudio para el aprovechamiento Tipo 1<br />
      b) Estudio para el Aprovechamiento Tipo 2<br />
      c) Estudio para Cambio Definitivo en el Uso del Suelo<br />
      d) Estudio para el Establecimiento y manejo de guaduales y/o bambusales<br /><br />
      <b
        >Para los individuos de la flora silvestre cuyo aprovechamiento tenga
        veda nacional o regional, el interesado deberá presentar la siguiente
        información en el marco de la documentación asociada al Plan de Manejo
        Forestal, Plan de Aprovechamiento Forestal o Estudio Técnico: (Parágrafo
        Transitorio Art. 125 del Decreto 2106 de 2019)</b
      ><br /><br />
      <b>1. Para especies arbóreas y helechos arborescentes, presentar:</b
      ><br />
      a) Resultados de la realización de un censo al 100% de los individuos
      fustales (con DAP >10cm) de las especies arbóreas y helechos arborescentes
      a aprovechar, indicando la siguiente información por cada individuo en
      formato Excel:<br />
      b) Número de identificación ID.<br />
      c) Nombre común, nombre científico y familia botánica.<br />
      d) Datos dasométricos (altura total en metros y diámetro a la altura del
      pecho – DAP en centímetros).<br />
      e) Estado fitosanitario.<br />
      f) Coordenadas planas (Este y Norte) de localización de cada individuo en
      el sistema de proyección cartográfico adoptado para Colombia, denominado
      «origen nacional», georreferenciadas dentro del polígono o los polígonos
      de área a aprovechar o a intervenir (archivo digital shapefile).<br />
      g) Resultados del muestreo de caracterización de la regeneración natural
      en el área a aprovechar o a intervenir, lo anterior en caso de encontrarse
      individuos de especies arbóreas y helechos arborescentes, con diámetro a
      la altura del pecho (DAP) menor a 10 cm (brinzales y latizales).<br />
      h) Soporte de la determinación taxonómica de las especies arbóreas y
      helechos arborescentes, mediante certificado de herbario o por un
      profesional con experiencia certificada en esta actividad para especies de
      tipo arbóreo.<br />
      i) Medidas de manejo para la conservación de las especies. Parágrafo 2 del
      Artículo 125 Decreto 2106 de 2019<br /><br />

      <b>Nota:</b> Para individuos arbóreos con diámetro a la Altura del Pecho
      (DAP) menor a 10 cm, realizar un muestreo representativo con una
      probabilidad del 95% y un error de muestreo no superior al 15%, por unidad
      de cobertura vegetal.<br />
      <b>Nota:</b> Para helechos arborescentes con altura menor a dos (2)
      metros, realizar un muestreo representativo con una probabilidad del 95% y
      un error de muestreo no superior al 15%, por unidad de cobertura
      vegetal.<br /><br />
      <b
        >Para especies de tipo no arbóreo (hierba, arbusto, enredadera, plantas
        sin semillas, entre otros) y líquenes: </b
      ><br />
      a) Descripción de la metodología de muestreo empleada para la
      caracterización de estas especies, de acuerdo a los sustratos y/o hábitos
      de crecimiento (epifito, terrestre, rupícola u otro sustrato), presentando
      los resultados de composición de especies (grupo, familia botánica y
      nombre científico), abundancia (número de individuos o cobertura en unidad
      de medida según corresponda), índices de diversidad y riqueza, y soportes
      de la representatividad del muestreo de acuerdo a la metodología
      seleccionada y las características de las unidades de cobertura de la
      tierra que componen el área forestal a intervenir.<br />
      b) Soportes de la determinación taxonómica a nivel de especie y
      clasificación por grupo taxonómico, mediante certificado emitido por un
      herbario o un profesional con experiencia en el tema, para este último se
      deberá presentar las evidencias de las determinaciones efectuadas en
      laboratorio incluyendo las descripciones de los procedimientos realizados
      y los registros fotográficos. <br />
      c) Coordenadas planas (Este y Norte) de la localización puntual de los
      forófitos y sustratos sobre los cuales se adelantó el muestreo de las
      especies vasculares y no vasculares georreferenciadas dentro del polígono
      o los polígonos del área forestal a intervenir (archivo digital
      shapefile).<br />
      d) Medidas de manejo para la conservación de las especies. Parágrafo 2 del
      Artículo 125 Decreto 2106 de 2019<br />
    </div>
    <br />
  </div>
  <!--cierre div principal-->
</template>

<script>
import {
  rutafile,
  rutaciudad,
  rutaenviosolicitud,
  tiponotificacion,
  logo,
  Porriesgoapteaa,
} from "../properties";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      form: {
        tipotramite: "",
        mediorecepcion: "4",
        observacionradicado:
          "TALA DE EMERGENCIA, SOLICITUDES PRIORITARIAS, y TALA O REUBICACIÓN POR OBRA PÚBLICA O PRIVADA",
        asunto:
          "TALA DE EMERGENCIA, SOLICITUDES PRIORITARIAS, y TALA O REUBICACIÓN POR OBRA PÚBLICA O PRIVADA",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram: "", //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        expe: "",
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        desciudad: "", //CAMBIO
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: tiponotificacion,
        selectedrepre: "",
        selectedtipoperapo: "1",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        desciudadapoderado: "", //CAMBIO
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        nombrecentropoblado: "",
        logo: logo,
        filesname: "",
      },
      form1: {
        Otroespecifique: "",
        selectedtipopredio: "",
        costopro: "",
        valorletras: "",
        expediente: "",
        actoadmin: "",
        terreno: "",
        propiedadprivada: "",
        prodforestal: "",
        selectedaprovechamiento: "",
        selectedclasedemanejo: "",
        Ingresosmensuales: "",
        Valorenletras: "",
        categoriaasociada: "",
        selectedtipodeaprovechamiento: "",
        Superficiepredio: "",
        selectedurbanorural: "",
        inmobiliaria: "",
        Catastral: "",
        selectedCoordenadas: "",
        Punto1: "",
        Punto2: "",
        Punto3: "",
        Punto4: "",
        Punto5: "",
        Punto6: "",
        Punto7: "",
        Punto8: "",
        Punto9: "",
        x1: "",
        x2: "",
        x3: "",
        x4: "",
        x5: "",
        x6: "",
        x7: "",
        x8: "",
        x9: "",
        y1: "",
        y2: "",
        y3: "",
        y4: "",
        y5: "",
        y6: "",
        y7: "",
        y8: "",
        y9: "",
        puntocg1: "",
        puntocg2: "",
        puntocg3: "",
        puntocg4: "",
        puntocg5: "",
        puntocg6: "",
        puntocg7: "",
        puntocg8: "",
        puntocg9: "",
        latitudg1: "",
        latitudg2: "",
        latitudg3: "",
        latitudg4: "",
        latitudg5: "",
        latitudg6: "",
        latitudg7: "",
        latitudg8: "",
        latitudg9: "",
        latitudm1: "",
        latitudm2: "",
        latitudm3: "",
        latitudm4: "",
        latitudm5: "",
        latitudm6: "",
        latitudm7: "",
        latitudm8: "",
        latitudm9: "",
        latituds1: "",
        latituds2: "",
        latituds3: "",
        latituds4: "",
        latituds5: "",
        latituds6: "",
        latituds7: "",
        latituds8: "",
        latituds9: "",
        longitudg1: "",
        longitudg2: "",
        longitudg3: "",
        longitudg4: "",
        longitudg5: "",
        longitudg6: "",
        longitudg7: "",
        longitudg8: "",
        longitudg9: "",
        longitudm1: "",
        longitudm2: "",
        longitudm3: "",
        longitudm4: "",
        longitudm5: "",
        longitudm6: "",
        longitudm7: "",
        longitudm8: "",
        longitudm9: "",
        longituds1: "",
        longituds2: "",
        longituds3: "",
        longituds4: "",
        longituds5: "",
        longituds6: "",
        longituds7: "",
        longituds8: "",
        longituds9: "",
        altitud1: "",
        altitud2: "",
        altitud3: "",
        altitud4: "",
        altitud5: "",
        altitud6: "",
        altitud7: "",
        altitud8: "",
        altitud9: "",
        selectedmanejosostenible: "",
        col1_1: "",
        col1_2: "",
        col1_3: "",
        col1_4: "",
        col1_5: "",
        col1_6: "",
        col1_7: "",
        col1_8: "",
        col2_11: "",
        col2_2: "",
        col2_3: "",
        col2_4: "",
        col2_5: "",
        col2_6: "",
        col2_7: "",
        col2_8: "",
        col3_11: "",
        col3_2: "",
        col3_3: "",
        col3_4: "",
        col3_5: "",
        col3_6: "",
        col3_7: "",
        col3_8: "",
        col4_11: "",
        col4_2: "",
        col4_3: "",
        col4_4: "",
        col4_5: "",
        col4_6: "",
        col4_7: "",
        col4_8: "",
        col5_11: "",
        col5_2: "",
        col5_3: "",
        col5_4: "",
        col5_5: "",
        col5_6: "",
        col5_7: "",
        col5_8: "",
        col6_11: "",
        col6_2: "",
        col6_3: "",
        col6_4: "",
        col6_5: "",
        col6_6: "",
        col6_7: "",
        col6_8: "",
        col7_11: "",
        col7_2: "",
        col7_3: "",
        col7_4: "",
        col7_5: "",
        col7_6: "",
        col7_7: "",
        col7_8: "",
        col8_11: "",
        col8_2: "",
        col8_3: "",
        col8_4: "",
        col8_5: "",
        col8_6: "",
        col8_7: "",
        col8_8: "",
        col9_11: "",
        col9_2: "",
        col9_3: "",
        col9_4: "",
        col9_5: "",
        col9_6: "",
        col9_7: "",
        col9_8: "",
        col10_11: "",
        col10_2: "",
        col10_3: "",
        col10_4: "",
        col10_5: "",
        col10_6: "",
        col10_7: "",
        col10_8: "",
        totaltabla: "",
        usoproducto: "",
        objetodeaprovechamiento: "",
        selectedestadoindividuo: "",
        especifiquecual: "",
        selectedtalapoda: "",
        selectedestadoind: "",
        selectedcausaperjucio: "",
        cuasaperjuiciootro: "",
        selectedtalatransplante: "",
        selectedobras: "",
        Similares: "",
        firma: "",
        aceptacion: "",
        origen: "",
      },
      form2: {},
      Porriesgoapteaa: Porriesgoapteaa,
      ciudadapoderado: "", //CAMBIO
      ciudadcompleta: "", //CAMBIO
      loading: false,
      show: true,
      showciudad: false,
      showciudadapo: false,
      file1: [],
      pesofile1: 0,
      nombrefiles: [],
      arryaJson: [],
      contadorarchivos: 0, // nueva------------------------------------------------------------
      contadorresponse: 0, // nueva------------------------------------------------------------
      filesValide: [], // nueva----------------------------------------------------------------
      optionsCiudad: [
        { value: "1000", text: "SANTIAGO DE CALI" },
        { value: "20000", text: "ALCALA" },
        { value: "36000", text: "ANDALUCIA" },
        { value: "41000", text: "ANSERMANUEVO" },
        { value: "54000", text: "ARGELIA" },
        { value: "100000", text: "BOLIVAR" },
        { value: "109000", text: "BUENAVENTURA" },
        { value: "111000", text: "GUADALAJARA DE BUGA" },
        { value: "113000", text: "BUGALAGRANDE" },
        { value: "122000", text: "CAICEDONIA" },
        { value: "126000", text: "DARIEN" },
        { value: "130000", text: "CANDELARIA" },
        { value: "147000", text: "CARTAGO" },
        { value: "233000", text: "DAGUA" },
        { value: "243000", text: "EL AGUILA" },
        { value: "246000", text: "EL CAIRO" },
        { value: "248000", text: "EL CERRITO" },
        { value: "250000", text: "EL DOVIO" },
        { value: "275000", text: "FLORIDA" },
        { value: "306000", text: "GINEBRA" },
        { value: "318000", text: "GUACARI" },
        { value: "364000", text: "JAMUNDI" },
        { value: "377000", text: "LA CUMBRE" },
        { value: "400000", text: "LA UNION" },
        { value: "403000", text: "LA VICTORIA" },
        { value: "497000", text: "OBANDO" },
        { value: "520000", text: "PALMIRA" },
        { value: "563000", text: "PRADERA" },
        { value: "606000", text: "RESTREPO" },
        { value: "616000", text: "RIOFRIO" },
        { value: "622000", text: "ROLDANILLO" },
        { value: "670000", text: "SAN PEDRO" },
        { value: "736000", text: "SEVILLA" },
        { value: "823000", text: "TORO" },
        { value: "828000", text: "TRUJILLO" },
        { value: "834000", text: "TULUA" },
        { value: "845000", text: "ULLOA" },
        { value: "863000", text: "VERSALLES" },
        { value: "869000", text: "VIJES" },
        { value: "890000", text: "YOTOCO" },
        { value: "892000", text: "YUMBO" },
        { value: "895000", text: "ZARZAL" },
      ],
      optionsdepart: [{ value: "76", text: "VALLE DEL CAUCA" }],
      optionsdepartamento: [
        { value: "91", text: "AMAZONAS" },
        { value: "5", text: "ANTIOQUIA" },
        { value: "81", text: "ARAUCA" },
        {
          value: "88",
          text: "ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA",
        },
        { value: "8", text: "ATLANTICO" },
        { value: "11", text: "BOGOTA D.C" },
        { value: "13", text: "BOLIVAR" },
        { value: "15", text: "BOYACA" },
        { value: "17", text: "CALDAS" },
        { value: "18", text: "CAQUETA" },
        { value: "85", text: "CASANARE" },
        { value: "19", text: "CAUCA" },
        { value: "20", text: "CESAR" },
        { value: "27", text: "CHOCO" },
        { value: "23", text: "CORDOBA" },
        { value: "25", text: "CUNDINAMARCA" },
        { value: "94", text: "GUAINIA" },
        { value: "95", text: "GUAVIARE" },
        { value: "41", text: "HUILA" },
        { value: "44", text: "LA GUAJIRA" },
        { value: "47", text: "MAGDALENA" },
        { value: "50", text: "META" },
        { value: "52", text: "NARIÑO" },
        { value: "54", text: "NORTE DE SANTANDER" },
        { value: "86", text: "PUTUMAYO" },
        { value: "63", text: "QUINDIO" },
        { value: "66", text: "RISARALDA" },
        { value: "68", text: "SANTANDER" },
        { value: "70", text: "SUCRE" },
        { value: "73", text: "TOLIMA" },
        { value: "76", text: "VALLE DEL CAUCA" },
        { value: "97", text: "VAUPES" },
        { value: "99", text: "VICHADA" },
      ],
      optionsciudades: [],
      optionsciudadesapo: [], //CAMBIO
      optionstiponoti: [
        { value: "2", text: "CORREO CERTIFICADO" },
        { value: "1", text: "CORREO ELECTRONICO" },
        { value: "7", text: "NOTIFICACIÓN PERSONAL" },
      ],
    };
  },
  watch: {
    "form.observacionradicado": function (newVal) {
      // Reemplaza el carácter "&" por una cadena vacía
      this.form.observacionradicado = newVal
        .replace(/&/g, "")
        .replace(/'/g, "");
    },
  },
  methods: {
    enviar() {
      this.arryaJson = [];
      this.nombrefiles = []; // nueva-----------------------------------------------------------

      this.form.desciudad = this.ciudadcompleta.text; //CAMBIO
      this.form.ciudad = this.ciudadcompleta.value; //CAMBIO
      this.form.desciudadapoderado = this.ciudadapoderado.text; //CAMBIO
      this.form.ciudadapoderado = this.ciudadapoderado.value; //CAMBIO

      if (this.form.selectedtipoident === "25") {
        this.form.selectedtipoident = "26";
        console.log("this.form.selectedtipoident", this.form.selectedtipoident);
      }

      if (this.form.selectedtipoidentapo === "25") {
        this.form.selectedtipoidentapo = "26";
        console.log(
          "this.form.selectedtipoidentapo",
          this.form.selectedtipoidentapo
        );
      }

      if (this.form.selectedtipotram === Porriesgoapteaa) {
        this.form.tipotramite = "8";
      } else if (this.form.selectedtipotram == 7) {
        this.form.tipotramite = "24";
      }

      this.pesofile1 = 0; // nueva----------------------------------------------------------------
      if (this.file1.length > 0) {
        this.file1.forEach((file) => {
          this.nombrefiles.push(file.name);
        });
      }
      this.form.filesname = this.nombrefiles.toString();

      const axiosInstance = axios.create({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      if (
        this.validacioncampos(this.form) &&
        this.validacioncamposapo(this.form)
      ) {
        this.form2 = Object.assign({}, this.form, this.form1);

        this.arryaJson.push(this.form, this.form1, this.form2);

        if (Array.isArray(this.arryaJson)) {
          // Recorre el arryaJson y aplica la función a todas las propiedades de todos los objetos en la matriz
          this.arryaJson.forEach((obj) => {
            for (const prop in obj) {
              if (typeof obj[prop] === "string") {
                obj[prop] = this.replaceSpecialCharacters(obj[prop]);
              }
            }
          });
        } else {
          console.error("arryaJson no es una matriz o está indefinida");
        }

        this.loading = true;
        this.show = false;
        for (var i = 0; i < this.file1.length; i++) {
          this.pesofile1 += this.file1[i].size;
        }

        // VALIDO SI CARGARON ARCHIVOS ADJUNTOS ----------------->
        if (this.file1.length > 0) {
          if (this.validarpesofile(this.file1)) {
            //console.log("valida archivos", this.validarpesofile(this.file1));
            // VALIDO SI TODOS LOS ARCHIVOS ADJUNTOS SUPERAN LOS 50 MB ----------------->
            console.log("this.pesofile1 3676", this.pesofile1);
            if (this.pesofile1 > 50000000) {
              swal(
                "El peso total de los archivos adjuntos supera los 50 Mb permitidos"
              );
              this.loading = false;
              this.show = true;
              //console.log('tamaño de los archivos -------- ',this.pesofile1)
              this.pesofile1 = 0;
            }  else if (this.pesofile1 < 1024) {
              swal(
                "El peso de uno o más archivos adjuntos es menor a 1 KB, lo cual no es permitido."
              );
              this.loading = false;
              this.show = true;
              this.pesofile1 = 0;
              console.log('tamaño de los archivos -------- ',this.pesofile1)
            } else {
              // SE ENVIA LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
              console.log("linea _____________ 3476");
              axiosInstance
                .post(rutaenviosolicitud, this.arryaJson)
                .then((response) => {
                  let blob = this.b64toBlob(response.data.pdf);

                  this.file1.push(blob);

                  console.log("adjuntos frontend ", this.file1.length);

                  if (this.file1.length > 0) {
                    this.contadorarchivos = this.file1.length;
                    this.filesValide = []; /////////////7nuevo

                    //console.log("cantidad de archivos ", this.contadorarchivos);
                    this.file1.forEach((file) => {
                      /////////////7nuevo
                      this.filesValide.push({
                        name: file.name,
                        value: 0,
                        color: true,
                      });

                      const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                      const formData = new FormData();
                      var regex = /([^-\w.]*)/gm;
                      //console.log("nombre archivo", file.name);
                      var arcPDF2 = file.name.replace(regex, "");
                      arcPDF2 = arcPDF2.replaceAll(" ", "");
                      //console.log("nombre nuevo ", arcPDF2);

                      var blob = file.slice(0, file.size, file.type);
                      var newFile = new File([blob], arcPDF2, {
                        type: file.type,
                      });

                      formData.append("file", newFile);
                      formData.append("fileName", arcPDF2);
                      formData.append("radicado", response.data.radicado);
                      formData.append("usuario", response.data.usuario);
                      formData.append("ordenTrabajo", response.data.orden);
                      formData.append("idCorreo", response.data.correo);

                      // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                      axios
                        .post(rutafile, formData, {
                          /// envio archivos
                          headers: { "content-type": "multipart/form-data" },
                          maxRedirects: 0,
                        })
                        .then((respon) => {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          if (respon.status === 200) {
                            this.contadorresponse++;
                          } else {
                            ///////////77nuevo
                            this.filesValide[keyInd].color = false;
                          } ///////////77nuevo

                          this.resetForm();
                          //console.log("Respuesta envio adjuntos", respon);

                          if (this.contadorarchivos === this.contadorresponse) {
                            this.loading = false;
                            swal({
                              closeOnClickOutside: false,
                              text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                          }

                          this.show = true;
                        })
                        .catch(function (error) {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          this.filesValide[keyInd].color = false; ///////////77nuevo
                          if (
                            error.response.status === 500 ||
                            error.response.status === 404
                          ) {
                            swal({
                              closeOnClickOutside: false,
                              text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                            this.resetForm();
                            console.log("error 2022 ", error.response.status);
                          }
                          this.show = true;
                        });
                    });
                  } else {
                    this.resetForm();
                    swal({
                      closeOnClickOutside: false,
                      text: `...Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                    this.loading = false;
                    this.show = true;
                  }
                })
                .catch(function (error) {
                  if (error.response.status === 500) {
                    swal({
                      closeOnClickOutside: false,
                      text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                  }
                  this.loading = false;
                  this.show = true;
                });
            }
          } else {
            swal("El peso de uno o mas archivos supera los 10 Mb permitidos");
            this.loading = false;
            this.show = true;
          }
        } else {
          // CUANDO NO CARGAR ARCHIVOS SE ENVIA SOLO LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
          axiosInstance
            .post(rutaenviosolicitud, this.arryaJson)
            .then((response) => {
              let blob = this.b64toBlob(response.data.pdf);

              this.file1.push(blob);

              console.log("adjuntos pdf backend", this.file1.length);

              if (this.file1.length > 0) {
                this.contadorarchivos = this.file1.length;
                this.filesValide = []; /////////////7nuevo

                //console.log("cantidad de archivos ", this.contadorarchivos);
                this.file1.forEach((file) => {
                  /////////////7nuevo
                  this.filesValide.push({
                    name: file.name,
                    value: 0,
                    color: true,
                  });

                  const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                  const formData = new FormData();
                  var regex = /([^-\w.]*)/gm;
                  //console.log("nombre archivo", file.name);
                  var arcPDF2 = file.name.replace(regex, "");
                  arcPDF2 = arcPDF2.replaceAll(" ", "");
                  //console.log("nombre nuevo ", arcPDF2);

                  var blob = file.slice(0, file.size, file.type);
                  var newFile = new File([blob], arcPDF2, {
                    type: file.type,
                  });

                  formData.append("file", newFile);
                  formData.append("fileName", arcPDF2);
                  formData.append("radicado", response.data.radicado);
                  formData.append("usuario", response.data.usuario);
                  formData.append("ordenTrabajo", response.data.orden);
                  formData.append("idCorreo", response.data.correo);

                  // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                  axios
                    .post(rutafile, formData, {
                      /// envio archivos
                      headers: { "content-type": "multipart/form-data" },
                      maxRedirects: 0,
                    })
                    .then((respon) => {
                      this.filesValide[keyInd].value = 100; ///////////77nuevo
                      if (respon.status === 200) {
                        this.contadorresponse++;
                      } else {
                        ///////////77nuevo
                        this.filesValide[keyInd].color = false;
                      } ///////////77nuevo

                      this.resetForm();
                      //console.log("Respuesta envio adjuntos", respon);

                      if (this.contadorarchivos === this.contadorresponse) {
                        this.loading = false;
                        swal({
                          closeOnClickOutside: false,
                          text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                        }).then((value) => {
                          if (value) {
                            window.location.href = "https://cvc.gov.co/";
                          }
                        });
                      }

                      this.show = true;
                    })
                    .catch(function (error) {
                      this.filesValide[keyInd].value = 100; ///////////77nuevo
                      this.filesValide[keyInd].color = false; ///////////77nuevo
                      if (
                        error.response.status === 500 ||
                        error.response.status === 404
                      ) {
                        swal({
                          closeOnClickOutside: false,
                          text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                        }).then((value) => {
                          if (value) {
                            window.location.href = "https://cvc.gov.co/";
                          }
                        });
                        this.resetForm();
                        console.log("error 2022 ", error.response.status);
                      }
                      this.show = true;
                    });
                });
              }

              this.resetForm();
              swal({
                closeOnClickOutside: false,
                text: `.,.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
              }).then((value) => {
                if (value) {
                  window.location.href = "https://cvc.gov.co/";
                }
              });
              this.loading = false;
              this.show = true;
            })
            .catch(function (error) {
              if (error.response.status === 500) {
                swal({
                  closeOnClickOutside: false,
                  text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                }).then((value) => {
                  if (value) {
                    window.location.href = "https://cvc.gov.co/";
                  }
                });
              }
              this.loading = false;
              this.show = true;
            });
        }
      } else {
        console.log("fallo");
      }
    },
    validarpesofile(files) {
      var count = 0;
      //console.log(files.length);
      files.forEach((file) => {
        if (file.size > 10000000) {
          count++;
        }
      });
      //console.log("archivos con peso de mas de 10 mb", count);
      if (count == 0) {
        return true;
      } else {
        return false;
      }
    },
    b64toBlob(b64Data) {
      const names = "formulario.pdf";
      const newbase64 = "data:application/pdf;base64," + b64Data;

      let arr = newbase64.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = window.atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      let file_created = new File([u8arr], names, { type: mime });

      /*var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";

      var blob = new Blob([file_created], { type: "application/pdf" }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "fileName.pdf";
      //a.click();*/

      return file_created;
    },
    resetForm() {
      (this.form = {
        tipotramite: "",
        mediorecepcion: "4",
        observacionradicado: "",
        asunto: "",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        expe: "",
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedrepre: "",
        selectedtipoperapo: "",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        nombrecentropoblado: "",
      }),
        (this.form1 = {
          Otroespecifique: "",
          selectedtipopredio: "",
          costopro: "",
          valorletras: "",
          expediente: "",
          actoadmin: "",
          terreno: "",
          propiedadprivada: "",
          prodforestal: "",
          selectedaprovechamiento: "",
          selectedclasedemanejo: "",
          Ingresosmensuales: "",
          Valorenletras: "",
          categoriaasociada: "",
          selectedtipodeaprovechamiento: "",
          Superficiepredio: "",
          selectedurbanorural: "",
          inmobiliaria: "",
          Catastral: "",
          selectedCoordenadas: "",
          Punto1: "",
          Punto2: "",
          Punto3: "",
          Punto4: "",
          Punto5: "",
          Punto6: "",
          Punto7: "",
          Punto8: "",
          Punto9: "",
          x1: "",
          x2: "",
          x3: "",
          x4: "",
          x5: "",
          x6: "",
          x7: "",
          x8: "",
          x9: "",
          y1: "",
          y2: "",
          y3: "",
          y4: "",
          y5: "",
          y6: "",
          y7: "",
          y8: "",
          y9: "",
          puntocg1: "",
          puntocg2: "",
          puntocg3: "",
          puntocg4: "",
          puntocg5: "",
          puntocg6: "",
          puntocg7: "",
          puntocg8: "",
          puntocg9: "",
          latitudg1: "",
          latitudg2: "",
          latitudg3: "",
          latitudg4: "",
          latitudg5: "",
          latitudg6: "",
          latitudg7: "",
          latitudg8: "",
          latitudg9: "",
          latitudm1: "",
          latitudm2: "",
          latitudm3: "",
          latitudm4: "",
          latitudm5: "",
          latitudm6: "",
          latitudm7: "",
          latitudm8: "",
          latitudm9: "",
          latituds1: "",
          latituds2: "",
          latituds3: "",
          latituds4: "",
          latituds5: "",
          latituds6: "",
          latituds7: "",
          latituds8: "",
          latituds9: "",
          longitudg1: "",
          longitudg2: "",
          longitudg3: "",
          longitudg4: "",
          longitudg5: "",
          longitudg6: "",
          longitudg7: "",
          longitudg8: "",
          longitudg9: "",
          longitudm1: "",
          longitudm2: "",
          longitudm3: "",
          longitudm4: "",
          longitudm5: "",
          longitudm6: "",
          longitudm7: "",
          longitudm8: "",
          longitudm9: "",
          longituds1: "",
          longituds2: "",
          longituds3: "",
          longituds4: "",
          longituds5: "",
          longituds6: "",
          longituds7: "",
          longituds8: "",
          longituds9: "",
          altitud1: "",
          altitud2: "",
          altitud3: "",
          altitud4: "",
          altitud5: "",
          altitud6: "",
          altitud7: "",
          altitud8: "",
          altitud9: "",
          selectedmanejosostenible: "",
          col1_1: "",
          col1_2: "",
          col1_3: "",
          col1_4: "",
          col1_5: "",
          col1_6: "",
          col1_7: "",
          col1_8: "",
          col2_11: "",
          col2_2: "",
          col2_3: "",
          col2_4: "",
          col2_5: "",
          col2_6: "",
          col2_7: "",
          col2_8: "",
          col3_11: "",
          col3_2: "",
          col3_3: "",
          col3_4: "",
          col3_5: "",
          col3_6: "",
          col3_7: "",
          col3_8: "",
          col4_11: "",
          col4_2: "",
          col4_3: "",
          col4_4: "",
          col4_5: "",
          col4_6: "",
          col4_7: "",
          col4_8: "",
          col5_11: "",
          col5_2: "",
          col5_3: "",
          col5_4: "",
          col5_5: "",
          col5_6: "",
          col5_7: "",
          col5_8: "",
          col6_11: "",
          col6_2: "",
          col6_3: "",
          col6_4: "",
          col6_5: "",
          col6_6: "",
          col6_7: "",
          col6_8: "",
          col7_11: "",
          col7_2: "",
          col7_3: "",
          col7_4: "",
          col7_5: "",
          col7_6: "",
          col7_7: "",
          col7_8: "",
          col8_11: "",
          col8_2: "",
          col8_3: "",
          col8_4: "",
          col8_5: "",
          col8_6: "",
          col8_7: "",
          col8_8: "",
          col9_11: "",
          col9_2: "",
          col9_3: "",
          col9_4: "",
          col9_5: "",
          col9_6: "",
          col9_7: "",
          col9_8: "",
          col10_11: "",
          col10_2: "",
          col10_3: "",
          col10_4: "",
          col10_5: "",
          col10_6: "",
          col10_7: "",
          col10_8: "",
          totaltabla: "",
          usoproducto: "",
          objetodeaprovechamiento: "",
          selectedestadoindividuo: "",
          especifiquecual: "",
          selectedtalapoda: "",
          selectedestadoind: "",
          selectedcausaperjucio: "",
          cuasaperjuiciootro: "",
          selectedtalatransplante: "",
          selectedobras: "",
          Similares: "",
          firma: "",
          aceptacion: "",
          origen: "",
        });
    },
    validarNumero() {
      this.form.identificacion = this.form.identificacion.replace(
        /[^0-9]/g,
        ""
      ); // Elimina cualquier carácter que no sea un número
      this.form.identificacionapode = this.form.identificacionapode.replace(
        /[^0-9]/g,
        ""
      );
    },
    validacioncampos() {
      if (this.form.selectedtipoper === "") {
        swal("Debe seleccionar el tipo de persona");
      } else if (
        this.form.selectedtipoper === "2" &&
        this.form.selectedtipoperjuridica === ""
      ) {
        swal("Debe seleccionar el tipo de persona juridica");
      } else if (this.form.selectedtipotram === "") {
        swal("Debe seleccionar el tipo de tramite");
      } else if (this.form.selectedtipoident === "") {
        swal("Debe seleccionar el tipo de identificación");
      } else if (
        (this.form.selectedtipoident === "26" ||
          this.form.selectedtipoident === "1") &&
        this.form.nombre === ""
      ) {
        swal("Debe ingresar un nombre o razón social");
      } else if (
        (this.form.selectedtipoident === "10" ||
          this.form.selectedtipoident === "36" ||
          this.form.selectedtipoident === "11") &&
        (this.form.nombre1 === "" || this.form.nombre3 === "")
      ) {
        swal("Debe ingresar un nombre y un apellido");
      } else if (this.form.identificacion === "") {
        swal("Debe ingresar la identificación");
      } else if (
        (this.form.selectedtipoident === "26" ||
          this.form.selectedtipoident === "1") &&
        this.form.digito === ""
      ) {
        swal("Debe ingresar el digito de verificación");
      } else if (this.form.dircorrespondencia === "") {
        swal("Debe ingresar la dirección de correspondencia");
      } else if (this.form.depart === "") {
        swal("Debe seleccionar el departamento de correspondencia");
      } else if (this.ciudadcompleta.value == undefined) {
        swal("Debe seleccionar la ciudad de correspondencia");
      } else if (this.form.selectedcorreo === "") {
        swal("Debe seleccionar si o no autoriza la notificación por correo");
      } else if (this.form.selectedcorreo === "1" && this.form.correo === "") {
        swal("Por favor ingresar un correo");
      } else if (
        this.form.selectedcorreo === "1" &&
        this.validEmail(this.form.correo) === false
      ) {
        swal("Por favor ingresar un correo valido");
      } else if (
        this.form.selectedcorreo === "2" &&
        this.form.correodirnoti === ""
      ) {
        swal("Por favor ingresar una dirección de notificación");
      } else if (this.form.direccionpredio === "") {
        swal(
          "Debe ingresar la dirección del predio para el cual esta haciendo la solicitud"
        );
      } else if (this.form.departpredio === "") {
        swal(
          "Debe seleccionar el departamento del predio para el cual esta haciendo la solicitud"
        );
      } else if (this.form.ciudadpredio === "") {
        swal(
          "Debe seleccionar el municipio del predio para el cual esta haciendo la solicitud"
        );
      } else if (this.form1.aceptacion === "") {
        swal(
          "Debe aceptar el uso de mis datos personales, tratamiento, protección"
        );
      } else {
        return true;
      }
    },
    validacioncamposapo() {
      if (
        this.form.selectedtipoperapo !== "" &&
        this.form.selectedtipoidentapo === ""
      ) {
        swal("Debe seleccionar el tipo de identificación del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.selectedtipoperapo === "2" &&
        this.form.selectedtipoperjuridica === ""
      ) {
        swal("Debe seleccionar el tipo de persona juridica del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "26") ||
          this.form.selectedtipoidentapo === "1") &&
        this.form.nombreapoderado === ""
      ) {
        swal("Debe ingresar un nombre o razón social del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "10") ||
          this.form.selectedtipoidentapo === "36" ||
          this.form.selectedtipoidentapo === "11") &&
        (this.form.nombre1apo === "" || this.form.nombre3apo === "")
      ) {
        swal("Debe ingresar un nombre y un apellido del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.identificacionapode === ""
      ) {
        swal("Debe ingresar la identificación del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "26") ||
          this.form.selectedtipoidentapo === "1") &&
        this.form.digitoapo === ""
      ) {
        swal("Debe ingresar el digito de verificación del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.dirapoderado === ""
      ) {
        swal("Debe ingresar la dirección de correspondencia del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.departapoderado === ""
      ) {
        swal(
          "Debe seleccionar el departamento de correspondencia del representante"
        );
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.ciudadapoderado.value == undefined
      ) {
        swal("Debe seleccionar la ciudad de correspondencia del representante");
      } else if (
        this.form.correoapoderado !== "" &&
        this.validEmail(this.form.correoapoderado) === false
      ) {
        swal("Por favor ingresar un correo valido del representante");
      } else {
        return true;
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(email);
    },
    onChangeCiudad() {
      if (this.form.depart !== "") {
        this.optionsciudades = [];

        this.$http
          .post(rutaciudad + `${this.form.depart}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudades.push(objeto);
            }
            this.showciudad = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeCiudadapoderado() {
      //CAMBIO
      if (this.form.departapoderado !== "") {
        this.optionsciudadesapo = [];

        this.$http
          .post(rutaciudad + `${this.form.departapoderado}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudadesapo.push(objeto);
            }
            this.showciudadapo = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeRadioButton() {
      // Hacer algo basado en el valor seleccionado
      if (this.form.selectedtipoper === "1") {
        // Se seleccionó "Natural"
        console.log("Se seleccionó Natural");
        this.form.selectedtipoident = "";
        this.form.selectedtipoperjuridica = "";
        // Ejecuta tu código aquí
      } else if (this.form.selectedtipoper === "2") {
        // Se seleccionó "Jurídica"
        this.form.selectedtipoident = "";
        console.log("Se seleccionó Jurídica");
        // Ejecuta tu código aquí
      }
    },
    onChangeRadioButtonApo() {
      // Hacer algo basado en el valor seleccionado
      if (this.form.selectedtipoperapo === "1") {
        // Se seleccionó "Natural"
        console.log("Se seleccionó Natural");
        this.form.selectedtipoidentapo = "";
        this.form.selectedtipoperjuridicaapo = "";
        // Ejecuta tu código aquí
      } else if (this.form.selectedtipoperapo === "2") {
        // Se seleccionó "Jurídica"
        this.form.selectedtipoidentapo = "";
        console.log("Se seleccionó Jurídica");
        // Ejecuta tu código aquí
      }
    },
    replaceSpecialCharacters(value) {
      if (typeof value === "string") {
        // Reemplaza el carácter "&" y comillas simples por una cadena vacía
        return value.replace(/&/g, "").replace(/'/g, "");
      } else {
        return value; // Si no es una cadena, devuelve el valor sin cambios
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

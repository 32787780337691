import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import Home from './components/HelloWorld.vue'
import Superficiales from './components/FunAguasSuperficiales.vue'
import Subterraneas from './components/FunAguasSubterraneas.vue'
import vertimiento_agua from './components/FunVertimientoAgua.vue'
import vertimiento_suelo from './components/FunVertimientoSuelo.vue'
import AdecuacionTerreno from './components/FunAdecuacionTerreno.vue'
import Aprovechamientofp from './components/FunAprovechamientofp.vue'
import Aprovechamientofu from './components/FunAprovechamientofu.vue'
import Autorizacionocoh from './components/FunAutorizacionocoh.vue'
import Autorizacionvce from './components/FunAutorizacionvce.vue'
import DiagnosticoAmbAlt from './components/FunDiagnosticoAmbAlt.vue'
import Expedicionceefc from './components/FunExpedicionceefc.vue'
import Modificacionlapma from './components/FunModificacionlapma.vue'
import Otorgamientola from './components/FunOtorgamientola.vue'
import Otorgamientolzoo from './components/FunOtorgamientolzoo.vue'
import Otorgamientopeaff from './components/FunOtorgamientopeaff.vue'
import Permisoaffs from './components/FunPermisoaffs.vue'
import Permisoafd from './components/FunPermisoafd.vue'
import Permisofjb from './components/FunPermisofjb.vue'
import Permisoresdbee from './components/FunPermisoresdbee.vue'
import Porriesgoapteaa from './components/FunPorriesgoapteaa.vue'
import Regisrtroederff from './components/FunRegisrtroederff.vue'
import Regisrtropp from './components/FunRegisrtropp.vue'
import Regisrtroloief from './components/FunRegisrtroloief.vue'
import Sinriesgoapteaa from './components/FunSinriesgoapteaa.vue'
import Generico from './components/FunGenerico.vue'
import FormularioRUA from './components/FormularioRUA.vue'
import FormularioACU from './components/FormularioACU.vue'
import FormularioITE from './components/FormularioIET.vue'
import FormularioRespel from './components/FormularioRespel.vue'
import FormularioRAACU from './components/FormularioRAACU.vue'
import FormularioGestorACU from './components/FormularioGestorACU.vue'
import FormularioDGA from './components/FormularioDGA.vue'
import FormularioNverde from './components/FormularioNverde.vue'
import FormularioTalarpp from './components/FunTalaOReubicacionpp.vue'
import FormularioILLANTAS from './components/FormularioILLANTAS.vue'
import FormularioRCD from './components/FormularioRCDAnexoIV.vue'
import FormularioPlanchsn from './components/FormularioPlanchsn.vue'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// Importar el icono de marcador y establecer la configuración por defecto
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Home
  },
  {
    path: '/Superficiales',
    name: 'Superficiales',
    component: Superficiales
  },
  {
    path: '/Subterraneas',
    name: 'Subterraneas',
    component: Subterraneas
  },
  {
    path: '/vertimiento_agua',
    name: 'vertimiento_agua',
    component: vertimiento_agua
  },
  {
    path: '/vertimiento_suelo',
    name: 'vertimiento_suelo',
    component: vertimiento_suelo
  },
  {
    path: '/AdecuacionTerreno',
    name: 'AdecuacionTerreno',
    component: AdecuacionTerreno
  },
  {
    path: '/Aprovechamientofp',
    name: 'Aprovechamientofp',
    component: Aprovechamientofp
  },
  {
    path: '/Aprovechamientofu',
    name: 'Aprovechamientofu',
    component: Aprovechamientofu
  },
  {
    path: '/Autorizacionocoh',
    name: 'Autorizacionocoh',
    component: Autorizacionocoh
  },
  {
    path: '/Autorizacionvce',
    name: 'Autorizacionvce',
    component: Autorizacionvce
  },
  {
    path: '/DiagnosticoAmbAlt',
    name: 'DiagnosticoAmbAlt',
    component: DiagnosticoAmbAlt
  },
  {
    path: '/Expedicionceefc',
    name: 'Expedicionceefc',
    component: Expedicionceefc
  },
  {
    path: '/Modificacionlapma',
    name: 'Modificacionlapma',
    component: Modificacionlapma
  },
  {
    path: '/Otorgamientola',
    name: 'Otorgamientola',
    component: Otorgamientola
  },
  {
    path: '/Otorgamientolzoo',
    name: 'Otorgamientolzoo',
    component: Otorgamientolzoo
  },
  {
    path: '/Otorgamientopeaff',
    name: 'Otorgamientopeaff',
    component: Otorgamientopeaff
  },
  {
    path: '/Permisoaffs',
    name: 'Permisoaffs',
    component: Permisoaffs
  },
  {
    path: '/Permisoafd',
    name: 'Permisoafd',
    component: Permisoafd
  },
  {
    path: '/Permisofjb',
    name: 'Permisofjb',
    component: Permisofjb
  },
  {
    path: '/Permisoresdbee',
    name: 'Permisoresdbee',
    component: Permisoresdbee
  },
  {
    path: '/Porriesgoapteaa',
    name: 'Porriesgoapteaa',
    component: Porriesgoapteaa
  },
  {
    path: '/Regisrtroederff',
    name: 'Regisrtroederff',
    component: Regisrtroederff
  },
  {
    path: '/Regisrtropp',
    name: 'Regisrtropp',
    component: Regisrtropp
  },
  {
    path: '/Regisrtroloief',
    name: 'Regisrtroloief',
    component: Regisrtroloief
  },
  {
    path: '/Sinriesgoapteaa',
    name: 'Sinriesgoapteaa',
    component: Sinriesgoapteaa
  },
  {
    path: '/Generico',
    name: 'Generico',
    component: Generico,
  },
  {
    path: '/FormularioRUA',
    name: 'FormularioRUA',
    component: FormularioRUA,
  },
  {
    path: '/FormularioACU',
    name: 'FormularioACU',
    component: FormularioACU,
  },
  {
    path: '/FormularioITE',
    name: 'FormularioITE',
    component: FormularioITE,
  },
  {
    path: '/FormularioRespel',
    name: 'FormularioRespel',
    component: FormularioRespel,
  },
  {
    path: '/FormularioRAACU',
    name: 'FormularioRAACU',
    component: FormularioRAACU,
  },
  {
    path: '/FormularioGestorACU',
    name: 'FormularioGestorACU',
    component: FormularioGestorACU,
  },
  {
    path: '/FormularioDGA',
    name: 'FormularioDGA',
    component: FormularioDGA,
  },
  {
    path: '/FormularioNverde',
    name: 'FormularioNverde',
    component: FormularioNverde,
  },
  {
    path: '/FormularioTalarpp',
    name: 'FormularioTalarpp',
    component: FormularioTalarpp,
  },
  {
    path: '/FormularioILLANTAS',
    name: 'FormularioILLANTAS',
    component: FormularioILLANTAS,
  },
  {
    path: '/FormularioRCD',
    name: 'FormularioRCD',
    component: FormularioRCD,
  },
  {
    path: '/FormularioPlanchsn',
    name: 'FormularioPlanchsn',
    component: FormularioPlanchsn,
  },
  
  
  
]

const router = new VueRouter({
  mode: "history",
  routes
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

<template>
  <div>
    <div id="viewDiv" style="width: 100%; height: 500px"></div><br><br><br>
    <div class="row mt-3">
      <div class="col-md-6">
        <b-form-group label="Latitud" style="text-align: left">
          <b-form-input v-model="latitude" disabled></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label="Longitud" style="text-align: left">
          <b-form-input v-model="longitude" disabled></b-form-input>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';

export default {
  name: "ArcGISMapComponent",
  data() {
    return {
      zoom: 11,
      center: [-76.5225, 3.4372],
      marker: null,
      latitude: '',
      longitude: '',
      view: null,
    };
  },
  mounted() {
    const map = new Map({
      basemap: 'topo-vector'
    });

    this.view = new MapView({
      container: 'viewDiv',
      map: map,
      center: this.center,
      zoom: this.zoom
    });

    this.view.on('click', this.onMapClick);
    this.view.watch('zoom', this.onZoomEnd);
  },
  methods: {
    onMapClick(event) {
      const { latitude, longitude } = event.mapPoint;
      this.marker = new Point({
        latitude,
        longitude
      });

      this.latitude = latitude.toFixed(6);
      this.longitude = longitude.toFixed(6);

      const graphic = new Graphic({
        geometry: this.marker,
        symbol: {
          type: 'simple-marker',
          color: 'red',
          outline: {
            color: 'white',
            width: 1
          }
        }
      });

      this.view.graphics.removeAll();
      this.view.graphics.add(graphic);

      console.log(`Lat: ${latitude}, Lng: ${longitude}`);
      this.$emit('coordinates-selected', { latitude, longitude });
    },
    onZoomEnd() {
      const zoomLevel = this.view.zoom;
      console.log(`Zoom Level: ${zoomLevel}`);
    }
  }
};
</script>

<style>
#viewDiv {
  height: 500px;
  width: 100%;
}
</style>

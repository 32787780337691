<template>
  <div>
    <div class="container">
      <p style="font-weight: bold; text-align: center">
        REGISTRO ÚNICO AMBIENTAL RUA 
      </p>
      <p style="font-weight: bold; text-align: center">
        Resolución 0839 de 2023
      </p>
    </div>
    <div class="container">
      <p style="text-align: justify">
        Señor(a) usuario (a), los campos que tienen el * son obligatorios para
        radicar su solicitud. Le recomendamos diligenciar la mayoría o totalidad
        de la información solicitada, así como adjuntar los documentos descritos
        en las instrucciones de diligenciamiento de este formulario.
      </p>
    </div>
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="text-right my-3">
        <b-button
          v-b-tooltip.hover
          title="Limpiar campos"
          variant="success"
          @click="resetForm()"
        >
          <b-icon icon="trash-fill"></b-icon>
        </b-button>
      </div>
      <div style="text-align: left; margin-top: 20px">
        <label style="font-weight: bold">I. DATOS DEL SOLICITANTE </label>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group label="*Tipo de persona:" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoper"
              name="selectedtipoper"
              @change="onChangeRadioButton()"
            >
              <b-form-radio value="1">Natural </b-form-radio>
              <b-form-radio value="2">Jurídica</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="*Tipo de identificación:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoident"
              name="selectedtipoident"
            >
              <b-form-radio value="10" v-if="form.selectedtipoper == 1"
                >CC
              </b-form-radio>
              <b-form-radio value="26" v-if="form.selectedtipoper == 2"
                >NIT
              </b-form-radio>
              <b-form-radio value="36" v-if="form.selectedtipoper == 1"
                >Pasaporte
              </b-form-radio>
              <b-form-radio value="25" v-if="form.selectedtipoper == 2"
                >Personería jurídica
              </b-form-radio>
              <b-form-radio value="11" v-if="form.selectedtipoper == 1"
                >Cédula de extranjería
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="*Nombre o razón social:"
            style="text-align: left"
          >
            <b-form-group style="text-align: left">
              <label
                style="text-align: left"
                v-if="
                  form.selectedtipoident == 26 || form.selectedtipoident == 25
                "
              >
                *Razón Social</label
              >
              <b-form-input
                v-if="
                  form.selectedtipoident == 26 || form.selectedtipoident == 25
                "
                v-model="form.nombre"
                placeholder="razón social"
                maxlength="180"
              ></b-form-input>
            </b-form-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group>
            <div class="row">
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Nombre</label
                >
                <b-form-input
                  placeholder="Primer Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre1"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Nombre</label
                >
                <b-form-input
                  placeholder="Segundo Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre2"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Apellido</label
                >
                <b-form-input
                  placeholder="Primer Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre3"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Apellido</label
                >
                <b-form-input
                  placeholder="Segundo Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre4"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 26 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 25 ||
                form.selectedtipoident == 11
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacion"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 11
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacion"
                  placeholder="No:"
                  v-if="
                    form.selectedtipoident == 26 || form.selectedtipoident == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digito"
                  placeholder="Dígito verificación:"
                  v-if="
                    form.selectedtipoident == 26 || form.selectedtipoident == 25
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
            <label>*Dirección de correspondencia: </label>
            <b-form-input
              v-model="form.dircorrespondencia"
              placeholder="Dirección de correspondencia"
              maxlength="95"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.depart"
              :options="optionsdepartamento"
              @change="onChangeCiudad()"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Ciudad:" style="text-align: left">
            <b-form-select v-show="showciudad" v-model="ciudadcompleta">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsciudades"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Teléfono (s):" style="text-align: left"
            ><b-form-input
              v-model="form.tel"
              placeholder="Teléfono (s)"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Fax:" style="text-align: left"
            ><b-form-input
              v-model="form.fax"
              placeholder="Fax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correo"
              placeholder="Correo electrónico"
              type="email"
              class="form-control"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="*¿Autoriza la notificación mediante correo electrónico?"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedcorreo"
              name="selectedcorreo"
            >
              <b-form-radio value="1">Si </b-form-radio>
              <b-form-radio value="2">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group
            label="En caso contrario indique la dirección para notificación física:"
            style="text-align: left"
            ><b-form-input
              v-model="form.correodirnoti"
              placeholder="Dirección de notificación"
              maxlength="200"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <!--------------------------cierre row finaliza el primer bloque de informacion ------------->
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div style="text-align: left; margin-top: 20px">
        <label style="font-weight: bold">II. DATOS DE LA EMPRESA </label>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group
            label="*Nombre completo o Razón social"
            style="text-align: left"
            ><b-form-input
              v-model="form.nombreempresa"
              placeholder="Nombre completo o Razón social"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="*Nombre comercial" style="text-align: left"
            ><b-form-input
              v-model="form.nombrepredio"
              placeholder="Nombre comercial"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="*Tipo Identificación de la empresa o persona natural"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoidentemp"
              name="selectedtipoidentemp"
            >
              <b-form-radio value="1">NIT </b-form-radio>
              <b-form-radio value="2">CC </b-form-radio>
              <b-form-radio value="3">CE </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoidentemp == 2 || form.selectedtipoidentemp == 3
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacionemp"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoidentemp == 2 || form.selectedtipoidentemp == 3
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacionemp"
                  placeholder="No:"
                  v-if="form.selectedtipoidentemp == 1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digitoemp"
                  placeholder="Dígito verificación:"
                  v-if="form.selectedtipoidentemp == 1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12"><label>Registro cámara de comercio </label></div>
        <div class="col-md-6">
          <b-form-group
            label="*Nombre Cámara de comercio"
            style="text-align: left"
            ><b-form-input
              v-model="form.camara"
              placeholder="Cámara"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Número de matrícula" style="text-align: left"
            ><b-form-input
              v-model="form.matricula"
              placeholder="Número de matrícula"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="*Dirección" style="text-align: left"
            ><b-form-input
              v-model="form.direccionpredio"
              placeholder="Dirección"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="3. *Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departpredio"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Municipio:" style="text-align: left">
            <b-form-select v-model="ciudadcompletaemp">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsCiudad"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Teléfono:" style="text-align: left"
            ><b-form-input
              v-model="form.telemp"
              placeholder="Teléfono"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Fax:" style="text-align: left"
            ><b-form-input
              v-model="form.faxemp"
              placeholder="Fax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="*Nombre completo de la persona natural o del representante legal de la persona jurídica"
            style="text-align: left"
            ><b-form-input
              v-model="form.nombrereprelegal"
              placeholder="Nombre completo de la persona natural o del representante legal de la persona jurídica"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="*Tipo Identificación de la persona natural o del representante legal de la persona jurídica"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoidentrepre"
              name="selectedtipoidentrepre"
            >
              <b-form-radio value="1">NIT </b-form-radio>
              <b-form-radio value="2">CC </b-form-radio>
              <b-form-radio value="3">CE </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoidentrepre == 2 ||
                form.selectedtipoidentrepre == 3
              "
              >*Identificación de la persona natural o del representante legal
              de la persona jurídica
            </label>
            <b-form-input
              v-model="form.identificacionrepre"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoidentrepre == 2 ||
                form.selectedtipoidentrepre == 3
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacionrepre"
                  placeholder="No:"
                  v-if="form.selectedtipoidentrepre == 1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digitorepre"
                  placeholder="Dígito verificación:"
                  v-if="form.selectedtipoidentrepre == 1"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="*Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correorepre"
              placeholder="Correo electrónico"
              type="email"
              class="form-control"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div>
        Por lo anterior, solicito a ustedes me sea asignado el (los) número(s)
        de inscripción correspondiente(s) para proceder a diligenciar la
        información del Registro Único Ambiental – RUA para el Sector
        Manufacturero dentro de los plazos establecidos en el artículo 8 de la
        Resolución 1023 del 28 de mayo de 2010.para el (los) establecimiento(s)
        que se relaciona (n) a continuación:
      </div>
      <div style="text-align: left; margin-top: 20px">
        <label style="font-weight: bold"
          >DATOS DEL ESTABLECIMIENTO DEL SECTOR MANUFACTURERO LOCALIZADO EN
          JURISDICCIÓN DE ESTA AUTORIDAD AMBIENTAL
        </label>
      </div>
      <div>
        (En caso de haber más de un establecimiento del sector manufacturero
        localizado en jurisdicción de esta Autoridad Ambiental que este obligado
        a diligenciar el Registro Único Ambiental para el sector manufacturero,
        por favor diligenciar este cuadro para cada uno de ellos, de acuerdo con
        lo establecido en el artículo 4 parágrafo 3 de la Resolución 1023 del 28
        de mayo de 2010.)
      </div>
      <br />
      <div class="row">
        <div class="col-md-12">
          <b-form-group
            label="*Nombre del establecimiento"
            style="text-align: left"
            ><b-form-input
              v-model="form.nombreestableci"
              placeholder="Nombre del establecimiento"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="3. *Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departpredioestab"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Municipio:" style="text-align: left">
            <b-form-select v-model="ciudadcompletaempest">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsCiudad"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="*Dirección" style="text-align: left"
            ><b-form-input
              v-model="form.direccionpredioestab"
              placeholder="Dirección"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Teléfono:" style="text-align: left"
            ><b-form-input
              v-model="form.telestab"
              placeholder="Teléfono"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Fax:" style="text-align: left"
            ><b-form-input
              v-model="form.faxestab"
              placeholder="Fax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="*Nombre de la persona a contactar"
            style="text-align: left"
            ><b-form-input
              v-model="form.nombrereprelegal0"
              placeholder="Nombre de la persona a contactar"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="*Descripción" style="text-align: left">
            <b-form-textarea
              id="textarea"
              v-model="form.observacionestab"
              placeholder="Descripción detallada de la actividad económica principal del establecimiento"
              rows="3"
              max-rows="6"
              maxlength="3900"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label for=""
            >*Código CIIU de la actividad principal (sí no lo conoce consultelo
            <a href="https://linea.ccb.org.co/descripcionciiu/" target="blank"
              >Aqui</a
            >)</label
          >
          <b-form-select
            v-model="form.codigoestab"
            :options="optioonsCIIU"
          ></b-form-select>
        </div>
      </div>
      <br />
    </div>
    <br />
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row" style="padding: 5px">
        <div class="col-md-12">
          <b-form-group
            label="ESCRIBA LA FIRMA DEL SOLICITANTE O APODERADO DEBIDAMENTE CONSTITUIDO"
            style="text-align: left; font-weight: bold"
            ><b-form-input
              v-model="form.firmasolicitante"
              maxlength="50"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <label style="text-align: left; font-weight: bold"
            >POR FAVOR FIRME LA SOLICITUD SOBRE EL SIGUIENTE ESPACIO</label
          >
          <canvas
            ref="signatureCanvas"
            @mouseup="captureSignature"
            @touchend="captureSignature"
          ></canvas>
        </div>
        <div class="col-md-12 mt-3">
          <!-- Agrega espacio arriba de los botones -->
          <b-button
            type="button"
            @click="clearSignature"
            class="btn btn-primary btn-sm mr-2"
            >Borrar</b-button
          >
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-11">
          <b-form-file
            v-model="file1"
            :state="Boolean(file1)"
            placeholder="Adjunta los documentos, peso maximo total archivos 50 Mb, peso maximo por cada archivo 10 Mb"
            drop-placeholder="Drop file here..."
            multiple
            browse-text="Buscar"
          ></b-form-file>
        </div>
        <div class="col-md-1">
          <b-button id="popover-button-variant" href="#" tabindex="0"
            >Ayuda</b-button
          >
          <b-popover
            target="popover-button-variant"
            variant="info"
            triggers="focus"
          >
            <template #title>Tutorial</template>
            De click
            <b-link
              href="../../../document/Guía Rápida Adjuntar documentos Tramites Web.pdf"
              target="blank"
              >aqui</b-link
            >
          </b-popover>
        </div>
      </div>
      <br /><br />
      <b-form-checkbox
        id="aceptacion"
        v-model="form.aceptacion"
        name="aceptacion"
        value="1"
        unchecked-value="0"
        >Acepto el uso de mis datos personales, tratamiento, protección y
        conservación de estos a CVC de acuerdo a lo establecido en la Ley 1581
        de 2012.
        <a
          href="https://www.cvc.gov.co/politicas_de_seguridad_y_privacidad_de_la_informacion"
          >Ver Política de protección de Datos Personales de la CVC.</a
        > </b-form-checkbox
      ><br />
      <div v-show="show">
        <b-button block variant="success" @click="enviar()"
          >Enviar solicitud</b-button
        >
      </div>
      <br />
      <div class="text-center" v-show="loading">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <div class="text-center">
          <strong>Enviando Solicitud</strong><br />
          <strong
            >IMPORTANTE: Si adjuntó archivos, por favor espere que termine la
            subida y salga un mensaje con el número de radicado. Si abandona el
            proceso, la solicitud quedará incompleta...</strong
          >
        </div>
        <br />

        <div
          :key="ind"
          v-for="(tr, ind) in filesValide"
          class="row ml-8 mb-2 d-flex justify-content-center"
        >
          <b-progress
            :max="100"
            striped
            animated
            :variant="'primary'"
            class="w-75"
          >
            <b-progress-bar :value="tr.value === 0 ? 33 : tr.value">
              <span>
                {{
                  tr.value !== 0
                    ? tr.color
                      ? `Se proceso el archivo '${tr.name} de manera correcta.`
                      : `No se pudo procesar el archivo '${tr.name}'.`
                    : `Se esta procesando el archivo '${tr.name}'`
                }}
                <strong></strong></span
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
      <br />
    </div>
    <br />
  </div>
  <!--cierre div principal-->
</template>

<script>
import {
  rutafile,
  rutaciudad,
  rutaenviosolicitud,
  tiponotificacion,
  logo,
  INSCRIPCION_AL_REGISTRO_UNICO_AMBIENTAL_RUA_MANUFACTURERO,
} from "../properties";
import SignaturePad from "signature_pad";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      canvasWidth: 700,
      canvasHeight: 120,
      signaturePad: null, // Variable para almacenar la instancia de SignaturePad
      form: {
        tipotramite: "74",
        mediorecepcion: "4",
        observacionradicado:
          "INSCRIPCIÓN AL REGISTRO ÚNICO AMBIENTAL RUA MANUFACTURERO",
        asunto: "INSCRIPCIÓN AL REGISTRO ÚNICO AMBIENTAL RUA MANUFACTURERO",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram:
          INSCRIPCION_AL_REGISTRO_UNICO_AMBIENTAL_RUA_MANUFACTURERO, //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        expe: "",
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        desciudad: "", //CAMBIO
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: tiponotificacion,
        selectedrepre: "",
        selectedtipoperapo: "",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        desciudadapoderado: "", //CAMBIO
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        desciudadpredio: "",
        nombrecentropoblado: "",
        logo: logo,
        filesname: "",
        firmasolicitante: "",
        aceptacion: "",
        nombreempresa: "",
        selectedtipoidentemp: "",
        identificacionemp: "",
        digitoemp: "",
        camara: "",
        matricula: "",
        telemp: "",
        faxemp: "",
        nombrereprelegal: "",
        selectedtipoidentrepre: "",
        identificacionrepre: "",
        digitorepre: "",
        correorepre: "",
        nombreestableci: "",
        departpredioestab: "",
        ciudadpredioestab: "",
        direccionpredioestab: "",
        telestab: "",
        faxestab: "",
        nombrereprelegal0: "",
        observacionestab: "",
        codigoestab: "",
        nombreestableci1: "",
        departpredioestab1: "",
        ciudadpredioestab1: "",
        direccionpredioestab1: "",
        telestab1: "",
        faxestab1: "",
        nombrereprelegal1: "",
        observacionestab1: "",
        codigoestab1: "",

        nombreestableci2: "",
        departpredioestab2: "",
        ciudadpredioestab2: "",
        direccionpredioestab2: "",
        telestab2: "",
        faxestab2: "",
        nombrereprelegal2: "",
        observacionestab2: "",
        codigoestab2: "",

        nombreestableci3: "",
        departpredioestab3: "",
        ciudadpredioestab3: "",
        direccionpredioestab3: "",
        telestab3: "",
        faxestab3: "",
        nombrereprelegal3: "",
        observacionestab3: "",
        codigoestab3: "",

        nombreestableci4: "",
        departpredioestab4: "",
        ciudadpredioestab4: "",
        direccionpredioestab4: "",
        telestab4: "",
        faxestab4: "",
        nombrereprelegal4: "",
        observacionestab4: "",
        codigoestab4: "",
        establecimiento1: "1",
        establecimiento2: "1",
        establecimiento3: "1",
        establecimiento4: "1",
        dia: "",
        mes: "",
        anio: "",
        firmaBase64: "",
      },
      form1: {
        actividadeco: "",
        CodigoCIIU: "",
        costototal: "",
        deparfuente: "",
        munifuente: "",
        direccionprediofuente: "",
        fuenteabastecimiento: "",
        grado1: "",
        grado2: "",
        grado3: "",
        munitos1: "",
        munitos2: "",
        munitos3: "",
        segundos1: "",
        segundos2: "",
        segundos3: "",
        grados1l: "",
        grados2l: "",
        grados3l: "",
        minutos1l: "",
        minutos2l: "",
        minutos3l: "",
        segundos1l: "",
        segundos2l: "",
        segundos3l: "",
        altitud1l: "",
        altitud2l: "",
        altitud3l: "",
        usosueloactual: "",
        usosuelopotencial: "",
        selectedtipoverti: "",
        selectedcaptacion: "",
        tiempodescargahora: "",
        tiempodescargadias: "",
        tiempodescargacuadal: "",
        selectedtipoflujo: "",
        areavertimiento: "",
        observa: "",
        firma: "",
        aceptacion: "",
        tipovertmiento: "2",
      },
      ciudadapoderado: "", //CAMBIO
      ciudadcompleta: "", //CAMBIO
      ciudadcompletaemp: "", //CAMBIO
      ciudadcompletaempest: "", //CAMBIO
      ciudadcompletaempest1: "", //CAMBIO
      ciudadcompletaempest2: "", //CAMBIO
      ciudadcompletaempest3: "", //CAMBIO
      ciudadcompletaempest4: "", //CAMBIO
      form2: {},
      datos: [],
      tipopeticion: "",
      nombretramite: "",
      peticion: "",
      loading: false,
      show: true,
      showciudad: false,
      showciudadapo: false,
      showestab2: false,
      showestab3: false,
      showestab4: false,
      showestab5: false,
      file1: [],
      pesofile1: 0,
      nombrefiles: [],
      arryaJson: [],
      contadorarchivos: 0, // nueva------------------------------------------------------------
      contadorresponse: 0, // nueva------------------------------------------------------------
      filesValide: [], // nueva----------------------------------------------------------------
      optionsCiudad: [
        { value: "1000", text: "SANTIAGO DE CALI" },
        { value: "20000", text: "ALCALA" },
        { value: "36000", text: "ANDALUCIA" },
        { value: "41000", text: "ANSERMANUEVO" },
        { value: "54000", text: "ARGELIA" },
        { value: "100000", text: "BOLIVAR" },
        { value: "109000", text: "BUENAVENTURA" },
        { value: "111000", text: "GUADALAJARA DE BUGA" },
        { value: "113000", text: "BUGALAGRANDE" },
        { value: "122000", text: "CAICEDONIA" },
        { value: "126000", text: "DARIEN" },
        { value: "130000", text: "CANDELARIA" },
        { value: "147000", text: "CARTAGO" },
        { value: "233000", text: "DAGUA" },
        { value: "243000", text: "EL AGUILA" },
        { value: "246000", text: "EL CAIRO" },
        { value: "248000", text: "EL CERRITO" },
        { value: "250000", text: "EL DOVIO" },
        { value: "275000", text: "FLORIDA" },
        { value: "306000", text: "GINEBRA" },
        { value: "318000", text: "GUACARI" },
        { value: "364000", text: "JAMUNDI" },
        { value: "377000", text: "LA CUMBRE" },
        { value: "400000", text: "LA UNION" },
        { value: "403000", text: "LA VICTORIA" },
        { value: "497000", text: "OBANDO" },
        { value: "520000", text: "PALMIRA" },
        { value: "563000", text: "PRADERA" },
        { value: "606000", text: "RESTREPO" },
        { value: "616000", text: "RIOFRIO" },
        { value: "622000", text: "ROLDANILLO" },
        { value: "670000", text: "SAN PEDRO" },
        { value: "736000", text: "SEVILLA" },
        { value: "823000", text: "TORO" },
        { value: "828000", text: "TRUJILLO" },
        { value: "834000", text: "TULUA" },
        { value: "845000", text: "ULLOA" },
        { value: "863000", text: "VERSALLES" },
        { value: "869000", text: "VIJES" },
        { value: "890000", text: "YOTOCO" },
        { value: "892000", text: "YUMBO" },
        { value: "895000", text: "ZARZAL" },
      ],
      optionsdepart: [{ value: "76", text: "VALLE DEL CAUCA" }],
      optionsdepartamento: [
        { value: "91", text: "AMAZONAS" },
        { value: "5", text: "ANTIOQUIA" },
        { value: "81", text: "ARAUCA" },
        {
          value: "88",
          text: "ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA",
        },
        { value: "8", text: "ATLANTICO" },
        { value: "11", text: "BOGOTA D.C" },
        { value: "13", text: "BOLIVAR" },
        { value: "15", text: "BOYACA" },
        { value: "17", text: "CALDAS" },
        { value: "18", text: "CAQUETA" },
        { value: "85", text: "CASANARE" },
        { value: "19", text: "CAUCA" },
        { value: "20", text: "CESAR" },
        { value: "27", text: "CHOCO" },
        { value: "23", text: "CORDOBA" },
        { value: "25", text: "CUNDINAMARCA" },
        { value: "94", text: "GUAINIA" },
        { value: "95", text: "GUAVIARE" },
        { value: "41", text: "HUILA" },
        { value: "44", text: "LA GUAJIRA" },
        { value: "47", text: "MAGDALENA" },
        { value: "50", text: "META" },
        { value: "52", text: "NARIÑO" },
        { value: "54", text: "NORTE DE SANTANDER" },
        { value: "86", text: "PUTUMAYO" },
        { value: "63", text: "QUINDIO" },
        { value: "66", text: "RISARALDA" },
        { value: "68", text: "SANTANDER" },
        { value: "70", text: "SUCRE" },
        { value: "73", text: "TOLIMA" },
        { value: "76", text: "VALLE DEL CAUCA" },
        { value: "97", text: "VAUPES" },
        { value: "99", text: "VICHADA" },
      ],
      optioonsCIIU: [
        {
          value: "1011",
          text: "1011	Procesamiento y conservación de carne y productos cárnicos",
        },
        {
          value: "1012",
          text: "1012	Procesamiento y conservación de pescados, crustáceos y moluscos",
        },
        {
          value: "1020",
          text: "1020	Procesamiento y conservación de frutas, legumbres, hortalizas y tubérculos",
        },
        {
          value: "1031",
          text: "1031	Extracción de aceites de origen vegetal crudos",
        },
        {
          value: "1032",
          text: "1032	Elaboración de aceites y grasas de origen vegetal refinados",
        },
        {
          value: "1033",
          text: "1033	Elaboración de aceites y grasas de origen animal",
        },
        { value: "1040", text: "1040 Elaboración de productos lácteos" },
        { value: "1051", text: "1051 Elaboración de productos de molinería" },
        {
          value: "1052",
          text: "1052	Elaboración de almidones y productos derivados del almidón",
        },
        { value: "1061", text: "1061 Trilla de café" },
        {
          value: "1062",
          text: "1062 Descafeinado, tostión y molienda del café",
        },
        { value: "1063", text: "1063 Otros derivados del café" },
        { value: "1071", text: "1071 Elaboración y refinación de azúcar" },
        { value: "1072", text: "1072 Elaboración de panela	" },
        { value: "1081", text: "1081 Elaboración de productos de panadería" },
        {
          value: "1082",
          text: "1082	Elaboración de cacao, chocolate y productos de confitería",
        },
        {
          value: "1083",
          text: "1083	Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares",
        },
        {
          value: "1084",
          text: "1084 Elaboración de comidas y platos preparados",
        },
        {
          value: "1089",
          text: "1089	Elaboración de otros productos alimenticios n.c.p.",
        },
        {
          value: "1090",
          text: "1090	Elaboración de alimentos preparados para animales",
        },
        {
          value: "1101",
          text: "1101	Destilación, rectificación y mezcla de bebidas alcohólicas",
        },
        {
          value: "1102",
          text: "1102	Elaboración de bebidas fermentadas no destiladas",
        },
        {
          value: "1103",
          text: "1103	Producción de malta, elaboración de cervezas y otras bebidas malteadas",
        },
        {
          value: "1104",
          text: "1104	Elaboración de bebidas no alcohólicas, producción de aguas minerales y otras aguas embotelladas",
        },
        { value: "1200", text: "1200 Elaboración de productos de tabaco" },
        {
          value: "1311",
          text: "1311 Preparación e hilatura de fibras textiles",
        },
        { value: "1312", text: "1312 Tejeduría de productos textiles" },
        { value: "1313", text: "1313 Acabado de productos textiles" },
        {
          value: "1391",
          text: "1391 Fabricación de tejidos de punto y ganchillo",
        },
        {
          value: "1392",
          text: "1392	Confección de artículos con materiales textiles, excepto prendas de vestir",
        },
        {
          value: "1393",
          text: "1393	Fabricación de tapetes y alfombras para pisos",
        },
        {
          value: "1394",
          text: "1394	Fabricación de cuerdas, cordeles, cables, bramantes y redes",
        },
        {
          value: "1399",
          text: "1399	Fabricación de otros artículos textiles n.c.p.",
        },
        {
          value: "1410",
          text: "1410	Confección de prendas de vestir, excepto prendas de piel",
        },
        { value: "1420", text: "1420 Fabricación de artículos de piel" },
        {
          value: "1430",
          text: "1430	Fabricación de artículos de punto y ganchillo",
        },
        {
          value: "1511",
          text: "1511	Curtido y recurtido de cueros; recurtido y teñido de pieles",
        },
        {
          value: "1512",
          text: "1512	Fabricación de artículos de viaje, bolsos de mano y artículos similares elaborados en cuero, y fabricación de artículos de talabartería y guarnicionería",
        },
        {
          value: "1513",
          text: "1513	Fabricación de artículos de viaje, bolsos de mano y artículos similares; artículos de talabartería y guarnicionería elaborados en otros materiales",
        },
        {
          value: "1521",
          text: "1521	Fabricación de calzado de cuero y piel, con cualquier tipo de suela",
        },
        {
          value: "1522",
          text: "1522	Fabricación de otros tipos de calzado, excepto calzado de cuero y piel",
        },
        { value: "1523", text: "1523 Fabricación de partes del calzado" },
        {
          value: "1610",
          text: "1610	Aserrado, acepillado e impregnación de la madera",
        },
        {
          value: "1620",
          text: "1620	Fabricación de hojas de madera para enchapado; fabricación de tableros contrachapados, tableros laminados, tableros de partículas y otros tableros y paneles",
        },
        {
          value: "1630",
          text: "1630	Fabricación de partes y piezas de madera, de carpintería y ebanistería para la construcción",
        },
        { value: "1640", text: "1640 Fabricación de recipientes de madera" },
        {
          value: "1690",
          text: "1690 Fabricación de otros productos de madera; fabricación de artículos de corcho, cestería y espartería",
        },
        {
          value: "1701",
          text: "1701	Fabricación de pulpas (pastas) celulósicas; papel y cartón",
        },
        {
          value: "1702",
          text: "1702	Fabricación de papel y cartón ondulado (corrugado); fabricación de envases, empaques y de embalajes de papel y cartón",
        },
        {
          value: "1709",
          text: "1709	Fabricación de otros artículos de papel y cartón",
        },
        { value: "1811", text: "1811 Actividades de impresión" },
        {
          value: "1812",
          text: "1812	Actividades de servicios relacionados con la impresión",
        },
        {
          value: "1820",
          text: "1820	Producción de copias a partir de grabaciones originales",
        },
        {
          value: "1910",
          text: "1910 Fabricación de productos de hornos de coque",
        },
        {
          value: "1921",
          text: "1921	Fabricación de productos de la refinación del petróleo",
        },
        { value: "1922", text: "1922 Actividad de mezcla de combustibles	" },
        {
          value: "2011",
          text: "2011	Fabricación de sustancias y productos químicos básicos",
        },
        {
          value: "2012",
          text: "2012	Fabricación de abonos y compuestos inorgánicos nitrogenados",
        },
        {
          value: "2013",
          text: "2013 Fabricación de plásticos en formas primarias",
        },
        {
          value: "2014",
          text: "2014	Fabricación de caucho sintético en formas primarias",
        },
        {
          value: "2021",
          text: "2021	Fabricación de plaguicidas y otros productos químicos de uso agropecuario",
        },
        {
          value: "2022",
          text: "2022	Fabricación de pinturas, barnices y revestimientos similares, tintas para impresión y masillas",
        },
        {
          value: "2023",
          text: "2023	Fabricación de jabones y detergentes, preparados para limpiar y pulir; perfumes y preparados de tocador",
        },
        {
          value: "2029",
          text: "2029	Fabricación de otros productos químicos n.c.p.",
        },
        {
          value: "2030",
          text: "2030	Fabricación de fibras sintéticas y artificiales",
        },
        {
          value: "2100",
          text: "2100	Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos de uso farmacéutico",
        },
        {
          value: "2211",
          text: "2211	Fabricación de llantas y neumáticos de caucho",
        },
        { value: "2212", text: "2212 Reencauche de llantas usadas" },
        {
          value: "2219",
          text: "2219	Fabricación de formas básicas de caucho y otros productos de caucho n.c.p.",
        },
        {
          value: "2221",
          text: "2221 Fabricación de formas básicas de plástico",
        },
        {
          value: "2229",
          text: "2229 Fabricación de artículos de plástico n.c.p.",
        },
        {
          value: "2310",
          text: "2310 Fabricación de vidrio y productos de vidrio",
        },
        { value: "2391", text: "2391 Fabricación de productos refractarios" },
        {
          value: "2392",
          text: "2392	Fabricación de materiales de arcilla para la construcción",
        },
        {
          value: "2393",
          text: "2393	Fabricación de otros productos de cerámica y porcelana",
        },
        { value: "2394", text: "2394 Fabricación de cemento, cal y yeso" },
        {
          value: "2395",
          text: "2395	Fabricación de artículos de hormigón, cemento y yeso",
        },
        { value: "2396", text: "2396 Corte, tallado y acabado de la piedra" },
        {
          value: "2399",
          text: "2399	Fabricación de otros productos minerales no metálicos n.c.p.",
        },
        { value: "2410", text: "2410 Industrias básicas de hierro y de acero	" },
        { value: "2421", text: "2421 Industrias básicas de metales preciosos" },
        {
          value: "2429",
          text: "2429	Industrias básicas de otros metales no ferrosos",
        },
        { value: "2431", text: "2431 Fundición de hierro y de acero" },
        { value: "2432", text: "2432 Fundición de metales no ferrosos" },
        {
          value: "2511",
          text: "2511	Fabricación de productos metálicos para uso estructural",
        },
        {
          value: "2512",
          text: "2512	Fabricación de tanques, depósitos y recipientes de metal, excepto los utilizados para el envase o el transporte de mercancías",
        },
        {
          value: "2513",
          text: "2513	Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central",
        },
        { value: "2520", text: "2520 Fabricación de armas y municiones" },
        {
          value: "2591",
          text: "2591	Forja, prensado, estampado y laminado de metal; pulvimetalurgia",
        },
        {
          value: "2592",
          text: "2592	Tratamiento y revestimiento de metales; mecanizado",
        },
        {
          value: "2593",
          text: "2593	Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería",
        },
        {
          value: "2599",
          text: "2599	Fabricación de otros productos elaborados de metal n.c.p.",
        },
        {
          value: "2610",
          text: "2610	Fabricación de componentes y tableros electrónicos",
        },
        {
          value: "2620",
          text: "2620	Fabricación de computadoras y de equipo periférico",
        },
        { value: "2630", text: "2630 Fabricación de equipos de comunicación" },
        {
          value: "2640",
          text: "2640	Fabricación de aparatos electrónicos de consumo",
        },
        {
          value: "2651",
          text: "2651	Fabricación de equipo de medición, prueba, navegación y control",
        },
        { value: "2652", text: "2652 Fabricación de relojes" },
        {
          value: "2660",
          text: "2660	Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico",
        },
        {
          value: "2670",
          text: "2670	Fabricación de instrumentos ópticos y equipo fotográfico",
        },
        {
          value: "2680",
          text: "2680	Fabricación de medios magnéticos y ópticos para almacenamiento de datos",
        },
        {
          value: "2711",
          text: "2711	Fabricación de motores, generadores y transformadores eléctricos",
        },
        {
          value: "2712",
          text: "2712	Fabricación de aparatos de distribución y control de la energía eléctrica",
        },
        {
          value: "2720",
          text: "2720	Fabricación de pilas, baterías y acumuladores eléctricos",
        },
        {
          value: "2731",
          text: "2731	Fabricación de hilos y cables eléctricos y de fibra óptica",
        },
        { value: "2732", text: "2732 Fabricación de dispositivos de cableado" },
        {
          value: "2740",
          text: "2740	Fabricación de equipos eléctricos de iluminación",
        },
        {
          value: "2750",
          text: "2750 Fabricación de aparatos de uso doméstico",
        },
        {
          value: "2790",
          text: "2790	Fabricación de otros tipos de equipo eléctrico n.c.p.",
        },
        {
          value: "2811",
          text: "2811	Fabricación de motores, turbinas, y partes para motores de combustión interna",
        },
        {
          value: "2812",
          text: "2812	Fabricación de equipos de potencia hidráulica y neumática",
        },
        {
          value: "2813",
          text: "2813	Fabricación de otras bombas, compresores, grifos y válvulas",
        },
        {
          value: "2814",
          text: "2814	Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión",
        },
        {
          value: "2815",
          text: "2815	Fabricación de hornos, hogares y quemadores industriales",
        },
        {
          value: "2816",
          text: "2816	Fabricación de equipo de elevación y manipulación",
        },
        {
          value: "2817",
          text: "2817	Fabricación de maquinaria y equipo de oficina (excepto computadoras y equipo periférico)",
        },
        {
          value: "2818",
          text: "2818	Fabricación de herramientas manuales con motor",
        },
        {
          value: "2819",
          text: "2819	Fabricación de otros tipos de maquinaria y equipo de uso general n.c.p.",
        },
        {
          value: "2821",
          text: "2821	Fabricación de maquinaria agropecuaria y forestal",
        },
        {
          value: "2822",
          text: "2822	Fabricación de máquinas formadoras de metal y de máquinas herramienta",
        },
        {
          value: "2823",
          text: "2823 Fabricación de maquinaria para la metalurgia",
        },
        {
          value: "2824",
          text: "2824	Fabricación de maquinaria para explotación de minas y canteras y para obras de construcción",
        },
        {
          value: "2825",
          text: "2825	Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco",
        },
        {
          value: "2826",
          text: "2826	Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros",
        },
        {
          value: "2829",
          text: "2829	Fabricación de otros tipos de maquinaria y equipo de uso especial n.c.p.",
        },
        {
          value: "2910",
          text: "2910	Fabricación de vehículos automotores y sus motores",
        },
        {
          value: "2920",
          text: "2920	Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques",
        },
        {
          value: "2930",
          text: "2930	Fabricación de partes, piezas (autopartes) y accesorios (lujos) para vehículos automotores",
        },
        {
          value: "3011",
          text: "3011	Construcción de barcos y de estructuras flotantes",
        },
        {
          value: "3012",
          text: "3012	Construcción de embarcaciones de recreo y deporte",
        },
        {
          value: "3020",
          text: "3020	Fabricación de locomotoras y de material rodante para ferrocarriles",
        },
        {
          value: "3030",
          text: "3030	Fabricación de aeronaves, naves espaciales y de maquinaria conexa",
        },
        {
          value: "3040",
          text: "3040	Fabricación de vehículos militares de combate",
        },
        { value: "3091", text: "3091 Fabricación de motocicletas" },
        {
          value: "3092",
          text: "3092	Fabricación de bicicletas y de sillas de ruedas para personas con discapacidad",
        },
        {
          value: "3099",
          text: "3099	Fabricación de otros tipos de equipo de transporte n.c.p.",
        },
        { value: "3110", text: "3110 Fabricación de muebles" },
        { value: "3120", text: "3120 Fabricación de colchones y somieres" },
        {
          value: "3210",
          text: "3210	Fabricación de joyas, bisutería y artículos conexos",
        },
        { value: "3220", text: "3220 Fabricación de instrumentos musicales" },
        {
          value: "3230",
          text: "3230	Fabricación de artículos y equipo para la práctica del deporte",
        },
        {
          value: "3240",
          text: "3240	Fabricación de juegos, juguetes y rompecabezas",
        },
        {
          value: "3250",
          text: "3250	Fabricación de instrumentos, aparatos y materiales médicos y odontológicos (incluido mobiliario)",
        },
        { value: "3290", text: "3290 Otras industrias manufactureras n.c.p." },
        {
          value: "3311",
          text: "3311	Mantenimiento y reparación especializado de productos elaborados en metal",
        },
        {
          value: "3312",
          text: "3312	Mantenimiento y reparación especializado de maquinaria y equipo",
        },
        {
          value: "3313",
          text: "3313	Mantenimiento y reparación especializado de equipo electrónico y óptico",
        },
        {
          value: "3314",
          text: "3314	Mantenimiento y reparación especializado de equipo eléctrico",
        },
        {
          value: "3315",
          text: "3315	Mantenimiento y reparación especializado de equipo de transporte, excepto los vehículos automotores, motocicletas y bicicletas",
        },
        {
          value: "3319",
          text: "3319	Mantenimiento y reparación de otros tipos de equipos y sus componentes n.c.p.",
        },
        {
          value: "3320",
          text: "3320	Instalación especializada de maquinaria y equipo industrial",
        },
      ],
      optionsciudades: [],
      optionsciudadesapo: [], //CAMBIO
      optionstiponoti: [
        { value: "2", text: "CORREO CERTIFICADO" },
        { value: "1", text: "CORREO ELECTRONICO" },
        { value: "7", text: "NOTIFICACIÓN PERSONAL" },
      ],
    };
  },
  watch: {
    "form.observacionradicado": function (newVal) {
      // Reemplaza el carácter "&" por una cadena vacía
      this.form.observacionradicado = newVal
        .replace(/&/g, "")
        .replace(/'/g, "");
    },
  },
  methods: {
    enviar() {
      this.arryaJson = [];
      this.nombrefiles = []; // nueva-----------------------------------------------------------

      // Obtener la fecha actual
      let fechaActual = new Date();

      // Obtener el día, mes y año por separado
      this.form.dia = fechaActual.getDate();
      this.form.mes = fechaActual.getMonth() + 1; // Sumamos 1 porque los meses se indexan desde 0
      this.form.anio = fechaActual.getFullYear();

      console.log("Día: " + this.form.dia);
      console.log("Mes: " + this.form.mes);
      console.log("Año: " + this.form.anio);

      this.captureSignature().then(() => {
        console.log(
          "Firma actualizada en this.form.firmaBase64:",
          this.form.firmaBase64
        );

        // Continuar con el resto de la lógica de envío aquí
      });

      this.form.desciudad = this.ciudadcompleta.text; //CAMBIOciudadpredio
      this.form.ciudad = this.ciudadcompleta.value; //CAMBIO
      this.form.desciudadapoderado = this.ciudadapoderado.text; //CAMBIO
      this.form.ciudadapoderado = this.ciudadapoderado.value; //CAMBIO
      this.form.desciudadpredio = this.ciudadcompletaemp.text;
      this.form.ciudadpredio = this.ciudadcompletaemp.value; //CAMBIO
      this.form.ciudadpredioestab = this.ciudadcompletaempest.text;
      this.form.ciudadpredioestab1 = this.ciudadcompletaempest1.text;
      this.form.ciudadpredioestab2 = this.ciudadcompletaempest2.text;
      this.form.ciudadpredioestab3 = this.ciudadcompletaempest3.text;
      this.form.ciudadpredioestab4 = this.ciudadcompletaempest4.text;
      console.log("ciudadpredio 1937", this.form.ciudadpredio);

      if (this.form.selectedtipoident === "25") {
        this.form.selectedtipoident = "26";
        console.log("this.form.selectedtipoident", this.form.selectedtipoident);
      }

      if (this.file1.length > 0) {
        this.file1.forEach((file) => {
          this.nombrefiles.push(file.name);
        });
      }
      this.form.filesname = this.nombrefiles.toString();

      const axiosInstance = axios.create({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      if (
        this.validacioncampos(this.form) === true &&
        this.validacioncamposapo(this.form) === true
      ) {
        this.form2 = Object.assign({}, this.form, this.form1);

        this.arryaJson.push(this.form, this.form1, this.form2);

        if (Array.isArray(this.arryaJson)) {
          // Recorre el arryaJson y aplica la función a todas las propiedades de todos los objetos en la matriz
          this.arryaJson.forEach((obj) => {
            for (const prop in obj) {
              if (typeof obj[prop] === "string") {
                obj[prop] = this.replaceSpecialCharacters(obj[prop]);
              }
            }
          });
        } else {
          console.error("arryaJson no es una matriz o está indefinida");
        }

        this.loading = true;
        this.show = false;
        this.pesofile1 = 0; // nueva----------------------------------------------------------------
        for (let i = 0; i < this.file1.length; i++) {
          this.pesofile1 += this.file1[i].size;
        }

        // VALIDO SI CARGARON ARCHIVOS ADJUNTOS ----------------->

        if (this.file1.length > 0) {
          if (this.validarpesofile(this.file1) === true) {
            // VALIDO SI TODOS LOS ARCHIVOS ADJUNTOS SUPERAN LOS 50 MB ----------------->
            if (this.pesofile1 > 50000000) {
              swal(
                "El peso total de los archivos adjuntos supera los 50 Mb permitidos"
              );
              this.loading = false;
              this.show = true;
              this.pesofile1 = 0;
            } else {
              // SE ENVIA LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
              axiosInstance
                .post(rutaenviosolicitud, this.arryaJson)
                .then((response) => {
                  let blob = this.b64toBlob(response.data.pdf);

                  this.file1.push(blob);

                  console.log("adjuntos frontend ", this.file1.length);
                  if (this.file1.length > 0) {
                    this.contadorarchivos = this.file1.length;
                    this.filesValide = []; /////////////7nuevo

                    console.log("cantidad de archivos ", this.contadorarchivos);
                    this.file1.forEach((file) => {
                      /////////////7nuevo
                      this.filesValide.push({
                        name: file.name,
                        value: 0,
                        color: true,
                      });

                      const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                      const formData = new FormData();
                      let regex = /([^-\w.]*)/gm;

                      let arcPDF2 = file.name.replace(regex, "");
                      arcPDF2 = arcPDF2.replaceAll(" ", "");

                      let blob = file.slice(0, file.size, file.type);
                      let newFile = new File([blob], arcPDF2, {
                        type: file.type,
                      });

                      formData.append("file", newFile);
                      formData.append("fileName", arcPDF2);
                      formData.append("radicado", response.data.radicado);
                      formData.append("usuario", response.data.usuario);
                      formData.append("ordenTrabajo", response.data.orden);
                      formData.append("idCorreo", response.data.correo);

                      // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                      axios
                        .post(rutafile, formData, {
                          /// envio archivos
                          headers: { "content-type": "multipart/form-data" },
                          maxRedirects: 0,
                        })
                        .then((respon) => {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          if (respon.status === 200) {
                            this.contadorresponse++;
                          } else {
                            ///////////77nuevo
                            this.filesValide[keyInd].color = false;
                          } ///////////77nuevo

                          this.resetForm();

                          if (this.contadorarchivos === this.contadorresponse) {
                            this.loading = false;
                            swal({
                              closeOnClickOutside: false,
                              text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                          }

                          this.show = true;
                        })
                        .catch(function (error) {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          this.filesValide[keyInd].color = false; ///////////77nuevo
                          if (
                            error.response.status === 500 ||
                            error.response.status === 404
                          ) {
                            swal({
                              closeOnClickOutside: false,
                              text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                            this.resetForm();
                            console.log("error 2022 ", error.response.status);
                          }
                          this.show = true;
                        });
                    });
                  } else {
                    this.resetForm();
                    swal({
                      closeOnClickOutside: false,
                      text: `...Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                    this.loading = false;
                    this.show = true;
                  }
                })
                .catch(function (error) {
                  if (error.response.status === 500) {
                    swal({
                      closeOnClickOutside: false,
                      text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                  }
                  this.loading = false;
                  this.show = true;
                });
            }
          } else {
            swal("El peso de uno o mas archivos supera los 10 Mb permitidos");
            this.loading = false;
            this.show = true;
          }
        } else {
          // CUANDO NO CARGAR ARCHIVOS SE ENVIA SOLO LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
          axiosInstance
            .post(rutaenviosolicitud, this.arryaJson)
            .then((response) => {
              let blob = this.b64toBlob(response.data.pdf);

              this.file1.push(blob);

              console.log("adjuntos pdf backend", this.file1.length);

              if (this.file1.length > 0) {
                this.contadorarchivos = this.file1.length;
                this.filesValide = []; /////////////7nuevo

                this.file1.forEach((file) => {
                  /////////////7nuevo
                  this.filesValide.push({
                    name: file.name,
                    value: 0,
                    color: true,
                  });

                  const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                  const formData = new FormData();
                  let regex = /([^-\w.]*)/gm;

                  let arcPDF2 = file.name.replace(regex, "");
                  arcPDF2 = arcPDF2.replaceAll(" ", "");

                  let blob = file.slice(0, file.size, file.type);
                  let newFile = new File([blob], arcPDF2, {
                    type: file.type,
                  });

                  formData.append("file", newFile);
                  formData.append("fileName", arcPDF2);
                  formData.append("radicado", response.data.radicado);
                  formData.append("usuario", response.data.usuario);
                  formData.append("ordenTrabajo", response.data.orden);
                  formData.append("idCorreo", response.data.correo);

                  // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                  axios
                    .post(rutafile, formData, {
                      /// envio archivos
                      headers: { "content-type": "multipart/form-data" },
                      maxRedirects: 0,
                    })
                    .then((respon) => {
                      this.filesValide[keyInd].value = 100; ///////////77nuevo
                      if (respon.status === 200) {
                        this.contadorresponse++;
                      } else {
                        ///////////77nuevo
                        this.filesValide[keyInd].color = false;
                      } ///////////77nuevo

                      this.resetForm();

                      if (this.contadorarchivos === this.contadorresponse) {
                        this.loading = false;
                        swal({
                          closeOnClickOutside: false,
                          text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                        }).then((value) => {
                          if (value) {
                            window.location.href = "https://cvc.gov.co/";
                          }
                        });
                      }

                      this.show = true;
                    })
                    .catch(function (error) {
                      this.filesValide[keyInd].value = 100; ///////////77nuevo
                      this.filesValide[keyInd].color = false; ///////////77nuevo
                      if (
                        error.response.status === 500 ||
                        error.response.status === 404
                      ) {
                        swal({
                          closeOnClickOutside: false,
                          text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                        }).then((value) => {
                          if (value) {
                            window.location.href = "https://cvc.gov.co/";
                          }
                        });
                        this.resetForm();
                        console.log("error 2022 ", error.response.status);
                      }
                      this.show = true;
                    });
                });
              }

              this.resetForm();
              swal({
                closeOnClickOutside: false,
                text: `.,.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
              }).then((value) => {
                if (value) {
                  window.location.href = "https://cvc.gov.co/";
                }
              });
              this.loading = false;
              this.show = true;
            })
            .catch(function (error) {
              if (error.response.status === 500) {
                swal({
                  closeOnClickOutside: false,
                  text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                }).then((value) => {
                  if (value) {
                    window.location.href = "https://cvc.gov.co/";
                  }
                });
              }
              this.loading = false;
              this.show = true;
            });
        }
      } else {
        console.log("fallo");
      }
    },
    validarpesofile(files) {
      let count = 0;
      files.forEach((file) => {
        if (file.size > 10000000) {
          count++;
        }
      });
      if (count == 0) {
        return true;
      } else {
        return false;
      }
    },
    b64toBlob(b64Data) {
      const names = "formulario.pdf";
      const newbase64 = "data:application/pdf;base64," + b64Data;

      let arr = newbase64.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = window.atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      let file_created = new File([u8arr], names, { type: mime });

      return file_created;
    },
    resetForm() {
      this.form1.firma = "";
      (this.form = {
        tipotramite: "31",
        mediorecepcion: "4",
        observacionradicado:
          "INSCRIPCIÓN AL REGISTRO ÚNICO AMBIENTAL RUA MANUFACTURERO",
        asunto: "",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram: "184", //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        expe: "",
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: "",
        selectedrepre: "",
        selectedtipoperapo: "",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        nombrecentropoblado: "",
      }),
        (this.form1 = {
          actividadeco: "",
          CodigoCIIU: "",
          costototal: "",
          deparfuente: "",
          munifuente: "",
          direccionprediofuente: "",
          fuenteabastecimiento: "",
          grado1: "",
          grado2: "",
          grado3: "",
          munitos1: "",
          munitos2: "",
          munitos3: "",
          segundos1: "",
          segundos2: "",
          segundos3: "",
          grados1l: "",
          grados2l: "",
          grados3l: "",
          minutos1l: "",
          minutos2l: "",
          minutos3l: "",
          segundos1l: "",
          segundos2l: "",
          segundos3l: "",
          altitud1l: "",
          altitud2l: "",
          altitud3l: "",
          usosueloactual: "",
          usosuelopotencial: "",
          selectedtipoverti: "",
          selectedcaptacion: "",
          tiempodescargahora: "",
          tiempodescargadias: "",
          tiempodescargacuadal: "",
          selectedtipoflujo: "",
          areavertimiento: "",
          observa: "",
          firma: "",
          aceptacion: "",
        });
    },
    validarNumero() {
      this.form.identificacion = this.form.identificacion.replace(
        /[^0-9]/g,
        ""
      ); // Elimina cualquier carácter que no sea un número
      this.form.identificacionapode = this.form.identificacionapode.replace(
        /[^0-9]/g,
        ""
      );
    },
    validacioncampos() {
      if (this.form.selectedtipoper === "") {
        swal("Debe seleccionar el tipo de persona");
      } else if (this.form.selectedtipotram === "") {
        swal("Debe seleccionar el tipo de tramite");
      } else if (this.form.selectedtipoident === "") {
        swal("Debe seleccionar el tipo de identificación");
      } else if (
        (this.form.selectedtipoident === "26" ||
          this.form.selectedtipoident === "1") &&
        this.form.nombre === ""
      ) {
        swal("Debe ingresar un nombre o razón social");
      } else if (
        (this.form.selectedtipoident === "10" ||
          this.form.selectedtipoident === "36" ||
          this.form.selectedtipoident === "11") &&
        (this.form.nombre1 === "" || this.form.nombre3 === "")
      ) {
        swal("Debe ingresar un nombre y un apellido");
      } else if (this.form.identificacion === "") {
        swal("Debe ingresar la identificación");
      } else if (
        (this.form.selectedtipoident === "26" ||
          this.form.selectedtipoident === "1") &&
        this.form.digito === ""
      ) {
        swal("Debe ingresar el digito de verificación");
      } else if (this.form.dircorrespondencia === "") {
        swal("Debe ingresar la dirección de correspondencia");
      } else if (this.form.depart === "") {
        swal("Debe seleccionar el departamento de correspondencia");
      } else if (this.ciudadcompleta.value == undefined) {
        swal("Debe seleccionar la ciudad de correspondencia");
      } else if (this.form.selectedcorreo === "") {
        swal("Debe seleccionar si o no autoriza la notificación por correo");
      } else if (this.form.selectedcorreo === "1" && this.form.correo === "") {
        swal("Por favor ingresar un correo");
      } else if (
        this.form.selectedcorreo === "1" &&
        this.validEmail(this.form.correo) === false
      ) {
        swal("Por favor ingresar un correo valido");
      } else if (
        this.form.selectedcorreo === "2" &&
        this.form.correodirnoti === ""
      ) {
        swal("Por favor ingresar una dirección de notificación");
      } else if (this.form.observacionradicado === "") {
        swal("Debe ingresar una breve descripción de la solicitud");
      } else if (this.form.nombreempresa === "") {
        swal("Debe digitar el Nombre completo o Razón social");
      } else if (this.form.nombrepredio === "") {
        swal("Debe digitar el Nombre comercial");
      } else if (this.form.selectedtipoidentemp === "") {
        swal(
          "Debe seleccionar Tipo Identificación de la empresa o persona natural"
        );
      } else if (this.form.identificacionemp === "") {
        swal("Debe ingresar el número de Identificación");
      } else if (this.form.camara === "") {
        swal("Debe ingresar el Nombre Cámara de comercio");
      } else if (this.form.matricula === "") {
        swal("Debe ingresar el Número de matrícula");
      } else if (this.form.direccionpredio === "") {
        swal("Debe ingresar la dirección");
      } else if (this.form.departpredio === "") {
        swal("Debe seleccionar el departamento");
      } else if (this.form.ciudadcompletaemp === "") {
        swal("Debe seleccionar el municipio");
      } else if (this.form.telemp === "") {
        swal("Debe ingresar el telefono de la empresa");
      } else if (this.form.nombrereprelegal === "") {
        swal(
          "Debe ingresar el Nombre completo de la persona natural o del representante legal de la persona jurídica"
        );
      } else if (this.form.selectedtipoidentrepre === "") {
        swal(
          "Seleccione el Tipo Identificación de la persona natural o del representante legal de la persona jurídica"
        );
      } else if (this.form.identificacionrepre === "") {
        swal(
          "Ingrese la Identificación de la persona natural o del representante legal de la persona jurídica"
        );
      } else if (this.form.correorepre === "") {
        swal("Ingrese un correo electronico");
      } else if (this.validEmail(this.form.correorepre) === false) {
        swal("Por favor ingresar un correo valido");
      } else if (this.form.nombreestableci === "") {
        swal("Debe digitar el nombre del establecimiento");
      } else if (this.form.departpredioestab === "") {
        swal("Debe digitar el departamento del establecimiento");
      } else if (this.form.ciudadpredioestab === "") {
        swal("Debe digitar la cuidad del establecimiento");
      } else if (this.form.direccionpredioestab === "") {
        swal("Debe digitar la dirección del establecimiento");
      } else if (this.form.telestab === "") {
        swal("Debe digitar Telefono del establecimiento");
      } else if (this.form.nombrereprelegal0 === "") {
        swal("Debe digitar Nombre de la persona a contactar");
      } else if (this.form.observacionestab === "") {
        swal(
          "Debe escribir Descripción detallada de la actividad económica principal del establecimiento"
        );
      } else if (this.form.codigoestab === "") {
        swal("Debe escribir el *Código CIIU de la actividad principal");
      } else if (
        (this.showestab2 === true && this.form.nombreestableci1 === "") ||
        (this.showestab2 === true && this.form.departpredioestab1 === "") ||
        (this.showestab2 === true && this.form.ciudadpredioestab1 === "") ||
        (this.showestab2 === true && this.form.direccionpredioestab1 === "") ||
        (this.showestab2 === true && this.form.telestab1 === "") ||
        (this.showestab2 === true && this.form.nombrereprelegal1 === "") ||
        (this.showestab2 === true && this.form.observacionestab1 === "") ||
        (this.showestab2 === true && this.form.codigoestab1 === "")
      ) {
        swal(
          "Debes ingresar todos los datos del segundo establecimiento a excepción del fax que no es obligatorio"
        );
      } else if (
        (this.showestab3 === true && this.form.nombreestableci2 === "") ||
        (this.showestab3 === true && this.form.departpredioestab2 === "") ||
        (this.showestab3 === true && this.form.ciudadpredioestab2 === "") ||
        (this.showestab3 === true && this.form.direccionpredioestab2 === "") ||
        (this.showestab3 === true && this.form.telestab2 === "") ||
        (this.showestab3 === true && this.form.nombrereprelegal2 === "") ||
        (this.showestab3 === true && this.form.observacionestab2 === "") ||
        (this.showestab3 === true && this.form.codigoestab2 === "")
      ) {
        swal(
          "Debes ingresar todos los datos del tercer establecimiento a excepción del fax que no es obligatorio"
        );
      } else if (
        (this.showestab4 === true && this.form.nombreestableci3 === "") ||
        (this.showestab4 === true && this.form.departpredioestab3 === "") ||
        (this.showestab4 === true && this.form.ciudadpredioestab3 === "") ||
        (this.showestab4 === true && this.form.direccionpredioestab3 === "") ||
        (this.showestab4 === true && this.form.telestab3 === "") ||
        (this.showestab4 === true && this.form.nombrereprelegal3 === "") ||
        (this.showestab4 === true && this.form.observacionestab3 === "") ||
        (this.showestab4 === true && this.form.codigoestab3 === "")
      ) {
        swal(
          "Debes ingresar todos los datos del cuarto establecimiento a excepción del fax que no es obligatorio"
        );
      } else if (
        (this.showestab5 === true && this.form.nombreestableci4 === "") ||
        (this.showestab5 === true && this.form.departpredioestab4 === "") ||
        (this.showestab5 === true && this.form.ciudadpredioestab4 === "") ||
        (this.showestab5 === true && this.form.direccionpredioestab4 === "") ||
        (this.showestab5 === true && this.form.telestab4 === "") ||
        (this.showestab5 === true && this.form.nombrereprelegal4 === "") ||
        (this.showestab5 === true && this.form.observacionestab4 === "") ||
        (this.showestab5 === true && this.form.codigoestab4 === "")
      ) {
        swal(
          "Debes ingresar todos los datos del quinto establecimiento a excepción del fax que no es obligatorio"
        );
      } else if (this.form.firmasolicitante === "") {
        swal("Debes ingresar el Nombre del solicitante o apoderado");
      } else if (this.signaturePad.isEmpty()) {
        swal("Debes firmar el campo de la firma no debe quedar vacio");
      } else if (this.form.aceptacion === "") {
        swal(
          "Debe aceptar el uso de mis datos personales, tratamiento, protección"
        );
      } else {
        return true;
      }
    },
    validacioncamposapo() {
      //console.log("this.form.selectedtipoperapo",this.form.selectedtipoperapo)
      if (
        this.form.selectedtipoperapo !== "" &&
        this.form.selectedtipoidentapo === ""
      ) {
        swal("Debe seleccionar el tipo de identificación del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.selectedtipoperapo === "2" &&
        this.form.selectedtipoperjuridica === ""
      ) {
        swal("Debe seleccionar el tipo de persona juridica del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "26") ||
          this.form.selectedtipoidentapo === "1") &&
        this.form.nombreapoderado === ""
      ) {
        swal("Debe ingresar un nombre o razón social del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "10") ||
          this.form.selectedtipoidentapo === "36" ||
          this.form.selectedtipoidentapo === "11") &&
        (this.form.nombre1apo === "" || this.form.nombre3apo === "")
      ) {
        swal("Debe ingresar un nombre y un apellido del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.identificacionapode === ""
      ) {
        swal("Debe ingresar la identificación del representante");
      } else if (
        ((this.form.selectedtipoperapo !== "" &&
          this.form.selectedtipoidentapo === "26") ||
          this.form.selectedtipoidentapo === "1") &&
        this.form.digitoapo === ""
      ) {
        swal("Debe ingresar el digito de verificación del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.dirapoderado === ""
      ) {
        swal("Debe ingresar la dirección de correspondencia del representante");
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.form.departapoderado === ""
      ) {
        swal(
          "Debe seleccionar el departamento de correspondencia del representante"
        );
      } else if (
        this.form.selectedtipoperapo !== "" &&
        this.ciudadapoderado.value == undefined
      ) {
        swal("Debe seleccionar la ciudad de correspondencia del representante");
      } else if (
        this.form.correoapoderado !== "" &&
        this.validEmail(this.form.correoapoderado) === false
      ) {
        swal("Por favor ingresar un correo valido del representante");
      } else {
        return true;
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(email);
    },
    onChangeCiudad() {
      if (this.form.depart !== "") {
        this.optionsciudades = [];

        this.$http
          .post(rutaciudad + `${this.form.depart}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudades.push(objeto);
            }
            this.showciudad = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeCiudadapoderado() {
      //CAMBIO
      if (this.form.departapoderado !== "") {
        this.optionsciudadesapo = [];

        this.$http
          .post(rutaciudad + `${this.form.departapoderado}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudadesapo.push(objeto);
            }
            this.showciudadapo = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeRadioButton() {
      // Hacer algo basado en el valor seleccionado
      if (this.form.selectedtipoper === "1") {
        // Se seleccionó "Natural"
        console.log("Se seleccionó Natural");
        this.form.selectedtipoident = "";
        this.form.selectedtipoperjuridica = "";
        // Ejecuta tu código aquí
      } else if (this.form.selectedtipoper === "2") {
        // Se seleccionó "Jurídica"
        this.form.selectedtipoident = "";
        console.log("Se seleccionó Jurídica");
        // Ejecuta tu código aquí
      }
    },

    initializeCanvas() {
      const canvas = this.$refs.signatureCanvas;
      const windowWidth = window.innerWidth;

      if (windowWidth <= 700) {
        this.canvasWidth = windowWidth - 20;
        this.canvasHeight = (this.canvasWidth * 120) / 700;
      } else {
        this.canvasWidth = 700;
        this.canvasHeight = 120;
      }

      canvas.width = this.canvasWidth;
      canvas.height = this.canvasHeight;
      this.signaturePad = new SignaturePad(canvas);
    },
    clearSignature() {
      this.signaturePad.clear();
      this.form.firmaBase64 = ""; // Limpiar la variable de base64 cuando se borre la firma
    },
    captureSignature() {
      return new Promise((resolve) => {
        if (this.signaturePad.isEmpty()) {
          resolve(); // Resolvemos la promesa sin capturar la firma
          return;
        }

        // Obtener la firma en formato base64
        const dataURL = this.signaturePad.toDataURL();
        this.form.firmaBase64 = dataURL.replace(
          /^data:image\/(png|jpg);base64,/,
          ""
        );
        console.log("Firma capturada:", this.form.firmaBase64);
        resolve(); // Resolvemos la promesa después de capturar la firma
      });
    },
    replaceSpecialCharacters(value) {
      if (typeof value === "string") {
        // Reemplaza el carácter "&" y comillas simples por una cadena vacía
        return value.replace(/&/g, "").replace(/'/g, "");
      } else {
        return value; // Si no es una cadena, devuelve el valor sin cambios
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initializeCanvas);
  },
  mounted() {
    window.scroll({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    this.initializeCanvas();
    window.addEventListener("resize", this.initializeCanvas);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
canvas {
  border: 1px solid #000;
  max-width: 100%;
  height: auto;
}
</style>
